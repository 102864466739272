import { FormSelect } from "@nef/core";
import React, { useMemo, memo, useCallback, useState } from "react";
import { execOrReturn } from "utils/js.utils";
import { SelectOptions, final } from ".";
import { useUserContext } from "../user";
import { SELECT_ALL } from "wksConstants";
import { FieldBox } from "components/styled";
import { useSelectOptionContext } from "./optionContext";

const WorkXSelect = props => {
  const [userData] = useUserContext();
  const {
    value,
    style,
    isRequired,
    form,
    name,
    handleInputChange: onInputChange,
    portalRef,
    id,
    hasSelectAll,
    optionsNew,
    marginRight = 3,
  } = props;

  const handleChange = useCallback(
    (e, other) => {
      const fieldDataKey = id ? id : name;
      const fieldData = execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });
      if (typeof e === "object") {
        let { value } = e;
        if (
          (other && other.action === "clear") ||
          !e ||
          e.clearSelection ||
          e.value?.clearSelection
        ) {
          value = null;
        }

        let allOptions = null;
        if (hasSelectAll === true && value.some(v => v.value === SELECT_ALL)) {
          allOptions = SelectOptions[id];
        }
        if (onInputChange) {
          onInputChange({
            id,
            name,
            value: hasSelectAll && allOptions !== null ? allOptions : value,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [id, name, form, userData.entitlements, hasSelectAll, onInputChange]
  );
  const [optionsData] = useSelectOptionContext();

  let optionsToUse = optionsNew ? optionsData.options[form.id][id] : props.options;

  const valueLength = execOrReturn(value)?.length || 0;
  const sortedOptions = useMemo(() => {
    if (Array.isArray(optionsToUse)) {
      const sorted = optionsToUse.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        } else {
          return 0;
        }
      });

      if (hasSelectAll && valueLength !== optionsToUse.length) {
        sorted.unshift({ value: SELECT_ALL, label: "<Select All>" });
      }

      return sorted;
    } else {
      return [];
    }
  }, [hasSelectAll, optionsToUse, valueLength]);

  return (
    <FieldBox paddingBottom={3} marginRight={marginRight} style={style} $isRequired={isRequired}>
      <FormSelect
        {...props}
        menuPortalTarget={portalRef?.current}
        value={value || ""}
        onChange={handleChange}
        options={sortedOptions}
        formatOptionLabel={(option, { context }) => {
          return context === "value" && option.valueLabel ? option.valueLabel : option.label;
        }}
      />
    </FieldBox>
  );
};

export default memo(WorkXSelect);
