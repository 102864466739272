import { NotificationHub } from "@nef/core";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { formatUrl } from "utils/js.utils";
import { Banner, BANNER_FORM, BANNER_THEME } from "./constant";
import {
  AlertRead,
  PROMISE_STATUS,
  readErrorCallback,
  readSuccessCallback,
} from "components/topBar/alerts/pvr/constants";
import { EST_TIMEZONE } from "utils/dateUtils";

const bannerDispatch = createContext<React.Dispatch<BannerAction> | undefined>(undefined);
bannerDispatch.displayName = "BannerDispatch";
export const useBannerDispatch = () => {
  const context = useContext(bannerDispatch);
  if (context === undefined) {
    throw new Error("useBannerDispatch must be used within a BannerProvider");
  }
  return context;
};

const bannerContext = createContext<[BannerState, React.Dispatch<BannerAction>] | undefined>(
  undefined
);
bannerContext.displayName = "BannerContext";
export const useBannerContext = () => {
  const context = useContext(bannerContext);
  if (context === undefined) {
    throw new Error("useBannerContext must be used within a BannerProvider");
  }
  return context;
};

type BannerState = {
  banners: Banner[];
  read: Set<string>;
  activeBanners: Banner[];
  isLoading: boolean;
  refreshCount: number;
  previewId: number;
};

type BannerAction =
  | { type: "START_REFRESH" }
  | {
      type: "FINISH_REFRESH";
      payload: { banners: Banner[]; activeBanners: Banner[]; read: Set<string> };
    }
  | { type: "REFRESH_ERROR" }
  | { type: "REMOVE_BANNER"; payload: string }
  | { type: "SET_READ"; payload: string }
  | { type: "PREVIEW_BANNERS"; payload: Banner[] };

const defaultState: BannerState = {
  banners: [],
  activeBanners: [],
  read: new Set<string>(),
  isLoading: true,
  refreshCount: 0,
  previewId: 0,
};

const themePriority = {
  [BANNER_THEME.SECONDARY]: 5,
  [BANNER_THEME.PRIMARY]: 4,
  [BANNER_THEME.SUCCESS]: 3,
  [BANNER_THEME.WARNING]: 2,
  [BANNER_THEME.DANGER]: 1,
};

const bannerPrioritySort = (a: Banner, b: Banner) => {
  return themePriority[a[BANNER_FORM.THEME]] - themePriority[b[BANNER_FORM.THEME]];
};

const DispatchFn = (state: BannerState, actions: BannerAction | BannerAction[]): BannerState => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state: BannerState, action: BannerAction): BannerState => {
  switch (action.type) {
    case "START_REFRESH": {
      return { ...state, isLoading: true };
    }
    case "FINISH_REFRESH": {
      return {
        ...state,
        banners: action.payload.banners,
        activeBanners: action.payload.activeBanners.sort(bannerPrioritySort),
        read: action.payload.read,
        isLoading: false,
        refreshCount: state.refreshCount + 1,
      };
    }
    case "REFRESH_ERROR": {
      NotificationHub.send("danger", "An error occurred getting banners");
      return { ...state, isLoading: false };
    }
    case "REMOVE_BANNER": {
      return {
        ...state,
        activeBanners: state.activeBanners.filter(b => b[BANNER_FORM.ID] !== action.payload),
      };
    }
    case "SET_READ": {
      const read = new Set(state.read);
      read.add(action.payload);
      return { ...state, read };
    }
    case "PREVIEW_BANNERS": {
      let id = state.previewId;
      const previewBanners = action.payload.map(b => {
        id = id + 1;
        return { ...b, [BANNER_FORM.ID]: `${id}` };
      });
      return { ...state, activeBanners: state.activeBanners.concat(previewBanners), previewId: id };
    }
    default:
      return { ...state };
  }
};

interface BannerContextProps {
  children: ReactNode;
  defaultData?: Partial<BannerState>;
  userId: string;
}

const BannerProvider: React.FC<BannerContextProps> = ({ children, defaultData, userId }) => {
  const [state, dispatchF] = useReducer(DispatchFn, { ...defaultState, ...defaultData });

  useEffect(() => {
    Promise.allSettled([
      new Promise<Banner[]>((resolve, reject) => {
        doFetchWrapper(
          formatUrl(process.env.REACT_APP_URL_USER_WS, "/banner"),
          {
            method: "GET",
            mode: "cors",
            headers: getHeaders(),
          },
          readSuccessCallback(resolve),
          readErrorCallback(reject)
        );
      }),
      new Promise<AlertRead[]>((resolve, reject) => {
        doFetchWrapper(
          formatUrl(process.env.REACT_APP_URL_USER_WS, "/getbannerread"),
          {
            method: "POST",
            mode: "cors",
            headers: getHeaders(),
          },
          readSuccessCallback(resolve),
          readErrorCallback(reject)
        );
      }),
    ]).then(([bannerResult, readResult]) => {
      if (bannerResult.status === PROMISE_STATUS.FULFILLED) {
        if (readResult.status === PROMISE_STATUS.FULFILLED) {
          const read = new Set(readResult.value.map(r => r.alertId));
          const activeBanners = bannerResult.value.filter(b => {
            const isRead = read.has(b[BANNER_FORM.ID] as string);
            const isEnabled = b[BANNER_FORM.IS_ENABLED];
            const now = new Date();
            const start = new Date(`${b[BANNER_FORM.START_DATE]} 00:00:00${EST_TIMEZONE}`);
            const end = new Date(`${b[BANNER_FORM.END_DATE]} 23:59:59${EST_TIMEZONE}`);
            const isActive = now >= start && now <= end;
            return !isRead && isEnabled && isActive;
          });
          dispatchF({
            type: "FINISH_REFRESH",
            payload: { banners: bannerResult.value, activeBanners, read },
          });
        } else {
          console.error("Error getting read banners");
        }
      } else {
        console.error("Error getting banner announcements");
      }
    });
  }, [state.isLoading]);

  return (
    <bannerDispatch.Provider value={dispatchF}>
      <bannerContext.Provider value={[state, dispatchF]}>{children}</bannerContext.Provider>
    </bannerDispatch.Provider>
  );
};

export default BannerProvider;
