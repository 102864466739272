import { Button, FontAwesomeIcon, Header } from "@nef/core";
import useSize from "@react-hook/size";
import { useLayoutContext } from "components/context";
import { getKeycloak } from "keycloak";
import React, { useMemo, lazy, Suspense, useCallback, useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Right } from "components/right";
import { useStatsDispatch } from "components/stats";
import { UploadInput } from "components/upload";
import { FourOhFour, FourOhThree, FourOhNine, NoAccess, useUserContext } from "components/user";
import { Views } from "viewConstants";
import { ContentWrapper } from ".";
import { useViewModelContext, useViewModelDispatch } from "../context";
import { PageLoader } from "./pageLoader";
import { OrderEntry } from "components/orderEntry/orderEntry";
import { SuspenseLoading } from "components/styled";
import { PvRejectLite, PvRejectMonitor } from "components/pvRejects";
import { MonitorWrapper } from "components/pvRejects/finra/monitor";
import styled from "styled-components";
import { BANNER_HEIGHT, Banners, MAX_BANNERS_VISIBLE } from "components/settings/banner/banner";
import { useBannerContext } from "components/settings/banner";

const ReportScan = lazy(() => import("components/reportScan"));
const Upload = lazy(() => import("components/upload"));
const Trader = lazy(() => import("components/trader"));
const QueryTool = lazy(() => import("components/queryTool"));
const LimitMonitor = lazy(() => import("components/limitMonitor"));
const Settings2 = lazy(() => import("components/settings"));
const Stats = lazy(() => import("components/stats"));
const ReferenceData = lazy(() => import("components/referenceData"));
const EQRC = lazy(() => import("components/eqrc"));

const SuspenseWrapper = styled.div`
  ${({ $numBanners }) => `
    position: relative;
    display: grid;
    grid-template-rows: ${`${
      BANNER_HEIGHT * Math.min(MAX_BANNERS_VISIBLE, $numBanners)
    }px calc(100vh - ${BANNER_HEIGHT * Math.min(MAX_BANNERS_VISIBLE, $numBanners)}px - 48px)`};
    padding-top: 46px;
    transition: grid-template-rows 0.2s;
  `}
`;

const Fallback = ({ error, resetErrorBoundary }) => {
  const handleReload = useCallback(() => {
    resetErrorBoundary();
  }, [resetErrorBoundary]);

  return (
    <ContentWrapper isLeftExpanded={false} isRightOpen={false} hideLeft={true}>
      <SuspenseLoading>
        <FontAwesomeIcon iconClassName={`fa-exclamation-triangle`} />
        <Header className="suspense-text">Error processing your request</Header>
        <Button onClick={handleReload}>Refresh current view</Button>
      </SuspenseLoading>
    </ContentWrapper>
  );
};

// This is the container for everything that isn't nav
const Content = () => {
  const [layoutData] = useLayoutContext();
  const [user] = useUserContext();
  const [viewData] = useViewModelContext();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const target = useRef(null);
  const [width, height] = useSize(target);
  const statsDispatch = useStatsDispatch();
  const [bannerData] = useBannerContext();

  useEffect(() => {
    if (width < 1500 || height < 950) {
      statsDispatch({ type: "LEGENDS", payload: false });
    } else {
      statsDispatch({ type: "LEGENDS", payload: true });
    }
  }, [width, height, statsDispatch, user.isUserDataLoading]);

  useEffect(() => {
    if (!user.allowed.views[Views.REPORT] && viewData.view === Views.REPORT) {
      viewDispatch({
        type: "CHANGE_VIEW",
        payload: { history, view: Views.SCAN },
      });
    }
  }, [history, viewDispatch, viewData.view, user.entitlements, user.allowed.views]);

  useEffect(() => {
    viewDispatch({
      type: "CHANGE_VIEW",
      payload: { history, view: user.defaultHomepage },
    });
  }, [history, user.defaultHomepage, viewDispatch]);

  const contentWrappedPaths = useMemo(
    () =>
      [
        Views.REPORT,
        Views.SCAN,
        Views.REJECTS,
        Views.UPLOAD,
        Views.QUERY_TOOL,
        Views.POST_TRADE_RISK,
        Views.SETTINGS,
        Views.REFERENCE_DATA_CLEARING,
        Views.REFERENCE_DATA_AGU,
        Views.REFERENCE_DATA_CUSIP,
        Views.EQRC_MONITOR,
        Views.ORDER_ENTRY,
        Views.PVR_CLIENT_MONITOR,
        Views.PVR_LITE_MONITOR,
        Views.PVR_FINRA_MONITOR,
      ].map(route => `/${route}/:form?`),
    []
  );

  return (
    <SuspenseWrapper $numBanners={bannerData.activeBanners.length}>
      <Banners />
      <Suspense fallback={<PageLoader />}>
        <ErrorBoundary
          FallbackComponent={({ error, resetErrorBoundary }) => {
            return <Fallback resetErrorBoundary={resetErrorBoundary} />;
          }}
        >
          <div ref={target}>
            {user.profileLoaded && (
              <>
                {getKeycloak().authenticated && (
                  <>
                    {user.hasRolesMisconfigured || user.hasMissingRoleDeps ? (
                      <FourOhNine />
                    ) : (
                      <>
                        <UploadInput />
                        <Switch>
                          <Route path={contentWrappedPaths} strict={true} exact={true}>
                            <ContentWrapper
                              isLeftExpanded={layoutData.isLeftExpanded}
                              isRightOpen={layoutData.isRightOpen}
                              hideLeft={layoutData.hideLeft}
                            >
                              <Route
                                path={`/${Views.UPLOAD}`}
                                render={() => {
                                  return user.allowed.views[Views.UPLOAD] ? (
                                    <Upload />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  );
                                }}
                              />
                              <Route
                                path={`/${Views.TRADER}`}
                                render={() => {
                                  return user.allowed.views[Views.TRADING] ? (
                                    <Trader />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  );
                                }}
                              />
                              <Route
                                path={`/(|${Views.REPORT}|${Views.SCAN}|${Views.REJECTS})/:form?`}
                                exact={true}
                                render={() =>
                                  user.allowed.views[Views.REPORT] ||
                                  user.allowed.views[Views.SCAN] ||
                                  user.allowed.views[Views.REJECTS] ? (
                                    <ReportScan />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />
                              <Route
                                path={`/${Views.QUERY_TOOL}`}
                                render={() =>
                                  user.allowed.views[Views.QUERY_TOOL] ? (
                                    <QueryTool />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />
                              <Route
                                path={`/${Views.POST_TRADE_RISK}`}
                                render={() =>
                                  user.allowed.views[Views.POST_TRADE_RISK] ? (
                                    <LimitMonitor />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />

                              <Route
                                path={`/${Views.EQRC_MONITOR}`}
                                render={() =>
                                  user.allowed.views[Views.EQRC_MONITOR] ? (
                                    <EQRC />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />
                              <Route path={`/${Views.SETTINGS}`}>
                                <Settings2 />
                              </Route>

                              <Route
                                path={[
                                  `/${Views.REFERENCE_DATA_CLEARING}`,
                                  `/${Views.REFERENCE_DATA_AGU}`,
                                  `/${Views.REFERENCE_DATA_CUSIP}`,
                                ]}
                              >
                                <ReferenceData />
                              </Route>

                              <Route
                                path={`/${Views.PVR_CLIENT_MONITOR}`}
                                render={() =>
                                  user.allowed.views[Views.PVR_CLIENT_MONITOR] ? (
                                    <PvRejectMonitor />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />

                              <Route
                                path={`/${Views.PVR_LITE_MONITOR}`}
                                render={() =>
                                  user.allowed.views[Views.PVR_LITE_MONITOR] ? (
                                    <PvRejectLite />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />

                              <Route
                                path={[`/${Views.PVR_FINRA_MONITOR}`]}
                                render={() =>
                                  user.allowed.views[Views.PVR_FINRA_MONITOR] ? (
                                    <MonitorWrapper />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />

                              <Route
                                path={`/${Views.ORDER_ENTRY}`}
                                render={() =>
                                  user.allowed.views[Views.ORDER_ENTRY] ? (
                                    <OrderEntry />
                                  ) : (
                                    <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                                  )
                                }
                              />

                              {user.allowed.views[Views.RIGHT] && layoutData.isRightOpen && (
                                <Right isOpen={layoutData.isRightOpen} />
                              )}
                            </ContentWrapper>
                          </Route>

                          <Route path={`/${Views.REAL_TIME_STATS}`}>
                            <ContentWrapper
                              isLeftOpen={layoutData.isLeftOpen}
                              isLeftExpanded={layoutData.isLeftExpanded}
                              isRightOpen={layoutData.isRightOpen}
                            >
                              {user.allowed.views[Views.REAL_TIME_STATS] ? (
                                <>
                                  <Stats />
                                  {user.allowed.views[Views.RIGHT] && layoutData.isRightOpen && (
                                    <Right isOpen={layoutData.isRightOpen} />
                                  )}
                                </>
                              ) : (
                                <Redirect to={`/${Views.FOUR_OH_THREE}`} />
                              )}
                            </ContentWrapper>
                          </Route>

                          <Route path={`/${Views.NO_ACCESS}`}>
                            <NoAccess />
                          </Route>
                          <Route path={`/${Views.FOUR_OH_THREE}`}>
                            <FourOhThree />
                          </Route>
                          <Route path={`/${Views.FOUR_OH_NINE}`}>
                            <FourOhNine />
                          </Route>

                          <Route path="/" exact={true}>
                            <Redirect to={user.defaultHomepage} />
                          </Route>
                          <Route path="/*">
                            <FourOhFour />
                          </Route>
                        </Switch>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </ErrorBoundary>
      </Suspense>
    </SuspenseWrapper>
  );
};

export default Content;
