import { SettingsWrapper } from "components/settings";
import { BannerManagerTable } from ".";

export const BannerManager = () => {
  return (
    <>
      <SettingsWrapper
        header="Banner Manager"
        subheader="Manage banners to be displayed to WorkX users"
        errorMessage={undefined}
        hasSave={false}
        disableFormWrapperClass={true}
        headerMenu={undefined}
        RightSide={undefined}
        tabs={undefined}
      >
        <BannerManagerTable />
      </SettingsWrapper>
    </>
  );
};
