import { NotificationHub } from "@nef/core";
import { ContextProviderProps, DispatchFn, DispatchFnSwitch } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { useStandardTableDispatch } from "components/standardTable";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useReducer } from "react";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { POR, PORModel, PORStatus } from "components/pvRejects/constant";
import { Status } from "wksConstantsTS";

export enum POR_CACHE_FILTER_BY {
  NULL,
  ALL,
  MPID,
  SYMBOL,
  MPID_SYMBOL,
}

type Exclude = { attribute?: PORModel; keys?: string[] };

type PorMap = { [key: string]: POR };
type OffsetMap = { [key: string]: number };
type CriteriaQuery = { query: { key: string; offset: number }[] };

export type PorCacheState = {
  data: PorMap;
  pending: PorMap;
  offsets: OffsetMap;
  keys: string[];
  exclude: Exclude;
  windowId: string | null;
  filterBy: POR_CACHE_FILTER_BY;
  status: Status;
  makeInitialRequest: boolean;
  requestFailedCount: number;
  hasMadeInitialRequest: boolean;
  isPolling: boolean;
  isSummaryPolling: boolean;
  isRightPolling: boolean;
  isRequesting: boolean;
  timeStamp: number;
  instId: number;
  isLoading: boolean;
  requestAbort: boolean;
  abort: AbortController;
  requestTimer: NodeJS.Timeout | undefined;
};

export type PorCacheAction =
  | {
      type: "START_POLLING";
      payload: { filterBy: POR_CACHE_FILTER_BY; keys?: string[]; exclude?: Exclude };
    }
  | { type: "RESET_CACHE" }
  | { type: "STOP_POLLING" }
  | { type: "HANDLE_INITIAL_REQUEST" }
  | { type: "HANDLE_REQUEST_FAILED"; payload: number }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_REQUESTING"; payload: boolean }
  | { type: "SET_REQUEST_STATUS"; payload: Status }
  | { type: "SET_ABORT"; payload: AbortController }
  | { type: "SET_REQUEST_TIMER"; payload: NodeJS.Timeout | undefined }
  | { type: "SET_DATA"; payload: { data: PorMap; pending: PorMap } }
  | { type: "SET_OFFSETS"; payload: { [key: string]: number } }
  | { type: "SET_WINDOW_ID"; payload: string | null };

const FACTORY = new ContextFactory<PorCacheState, PorCacheAction>();

const DEFAULT_STATE = {
  data: {},
  pending: {},
  offsets: {},
  keys: [],
  exclude: { attribute: undefined, keys: [] },
  windowId: null,
  filterBy: POR_CACHE_FILTER_BY.NULL,
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isSummaryPolling: false,
  isRightPolling: false,
  isRequesting: false,
  timeStamp: 0,
  instId: 0,
  isLoading: false,
  requestAbort: false,
  abort: new AbortController(),
  requestTimer: undefined,
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts({ ...DEFAULT_STATE });

const DISPATCH_FN_SWITCH: DispatchFnSwitch<PorCacheState, PorCacheAction> = (
  prevState: PorCacheState,
  action: PorCacheAction
) => {
  switch (action.type) {
    case "START_POLLING": {
      const { filterBy, keys = [], exclude = { keys: [] } } = action.payload;
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        filterBy,
        keys,
        exclude,
        windowId: null,
        isPolling: true,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        isRequesting: false,
        isLoading: true,
        data: {},
        pending: {},
        offsets: {},
      };
    }
    case "RESET_CACHE": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...DEFAULT_STATE,
      };
    }
    case "STOP_POLLING": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        isPolling: false,
      };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...prevState,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...prevState,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_LOADING": {
      return { ...prevState, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...prevState, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...prevState, status: action.payload };
    }
    case "SET_ABORT": {
      return { ...prevState, abort: action.payload };
    }
    case "SET_REQUEST_TIMER": {
      return { ...prevState, requestTimer: action.payload };
    }
    case "SET_DATA": {
      return {
        ...prevState,
        data: action.payload.data,
        pending: action.payload.pending,
        isLoading: false,
      };
    }
    case "SET_OFFSETS": {
      return { ...prevState, offsets: action.payload };
    }
    case "SET_WINDOW_ID": {
      return { ...prevState, windowId: action.payload };
    }
    default:
      return { ...prevState };
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<PorCacheState, PorCacheAction>(DISPATCH_FN_SWITCH);

interface PorCacheProviderProps extends ContextProviderProps {}

const getKeyForPor = (por: POR) => {
  return `${por[PORModel.ID]}`;
};

const pollingIntervalMs = 4000;
export const PorCacheProvider: React.FC<PorCacheProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<PorCacheState, PorCacheAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );
  const tableDispatch = useStandardTableDispatch();

  const getPorData = useCallback(
    (isInitialData: boolean) => {
      const getSuccessActions = ({
        isInitialData,
        hasUpdate,
        timer,
        windowId,
        newOffsets,
        newData,
        newPending,
      }: {
        isInitialData: boolean;
        hasUpdate: boolean;
        timer: NodeJS.Timeout;
        windowId: string;
        newOffsets?: OffsetMap;
        newData?: PorMap;
        newPending?: PorMap;
      }) => {
        const actions: PorCacheAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          {
            type: "SET_REQUEST_STATUS",
            payload: Status.SUCCESS,
          },
          {
            type: "SET_REQUEST_TIMER",
            payload: timer,
          },
        ];

        if (isInitialData) {
          actions.push({ type: "HANDLE_INITIAL_REQUEST" });
        }
        if (hasUpdate) {
          if (newOffsets !== undefined) {
            actions.push({
              type: "SET_OFFSETS",
              payload: newOffsets,
            });
          } else {
            console.warn("PorCache: Missing new offsets");
          }
          if (newData !== undefined && newPending !== undefined) {
            actions.push({
              type: "SET_DATA",
              payload: { data: newData, pending: newPending },
            });
          } else {
            console.warn("PorCache: Missing new data / new pending");
          }
        }
        if (state.windowId !== windowId) {
          actions.push({
            type: "SET_WINDOW_ID",
            payload: windowId,
          });
        }
        return actions;
      };

      const getAllPorSuccess = ({ data, windowId }: { data: POR[]; windowId: string }) => {
        if (state.windowId !== null && state.windowId !== windowId) {
          dispatch({
            type: "START_POLLING",
            payload: { filterBy: state.filterBy, keys: state.keys },
          });
          tableDispatch({
            type: "DESELECT_ALL_ROWS",
            payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
          });
        } else {
          const timer = setTimeout(() => {
            dispatch({ type: "SET_REQUESTING", payload: true });
          }, pollingIntervalMs);

          const hasUpdate = data.length > 0;
          if (hasUpdate) {
            const newOffsets = { ...state.offsets };
            const newData = { ...state.data };
            const newPending = { ...state.pending };
            if (newOffsets[POR_CACHE_FILTER_BY.ALL] === undefined) {
              newOffsets[POR_CACHE_FILTER_BY.ALL] = data.length;
            } else {
              newOffsets[POR_CACHE_FILTER_BY.ALL] += data.length;
            }
            data?.forEach(por => {
              if (
                state.exclude.attribute === undefined ||
                state.exclude.keys === undefined ||
                !state.exclude.keys.includes(por[state.exclude.attribute] as string)
              ) {
                const key = getKeyForPor(por);
                newData[key] = por;
                if (por[PORModel.REQUEST_STATUS] === PORStatus.PENDING) {
                  newPending[key] = por;
                } else {
                  delete newPending[key];
                }
              }
            });
            dispatch(
              getSuccessActions({
                isInitialData,
                hasUpdate,
                timer,
                windowId,
                newOffsets,
                newData,
                newPending,
              })
            );
          } else {
            dispatch(getSuccessActions({ isInitialData, hasUpdate, timer, windowId }));
          }
        }
      };

      const getFilteredPorSuccess = ({
        data,
        windowId,
      }: {
        data: { [key: string]: POR[] };
        windowId: string;
      }) => {
        if (state.windowId !== null && state.windowId !== windowId) {
          dispatch({
            type: "START_POLLING",
            payload: { filterBy: state.filterBy, keys: state.keys },
          });
        } else {
          const timer = setTimeout(() => {
            dispatch({ type: "SET_REQUESTING", payload: true });
          }, pollingIntervalMs);

          const mapEntries = Object.entries(data);
          const hasUpdate = mapEntries.length > 0;
          if (hasUpdate) {
            const newOffsets = { ...state.offsets };
            const newData = { ...state.data };
            const newPending = { ...state.pending };
            mapEntries.forEach(([key, pors]) => {
              if (newOffsets[key] === undefined) {
                newOffsets[key] = pors.length;
              } else {
                newOffsets[key] += pors.length;
              }
              pors.forEach(por => {
                newData[getKeyForPor(por)] = por;
                if (por[PORModel.REQUEST_STATUS] === PORStatus.PENDING) {
                  newPending[getKeyForPor(por)] = por;
                } else {
                  delete newPending[getKeyForPor(por)];
                }
              });
            });
            dispatch(
              getSuccessActions({
                isInitialData,
                hasUpdate,
                timer,
                windowId,
                newOffsets,
                newData,
                newPending,
              })
            );
          } else {
            dispatch(getSuccessActions({ isInitialData, hasUpdate, timer, windowId }));
          }
        }
      };

      const getPorError = () => {
        const actions: PorCacheAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
        ];
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        if (state.status !== Status.ERROR) {
          NotificationHub.send("danger", "Error getting PORs");
        }
        dispatch(actions);
      };

      const getPorAbortCb = () => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      };

      const abortController = new AbortController();
      dispatch({ type: "SET_ABORT", payload: abortController });
      if (state.filterBy === POR_CACHE_FILTER_BY.ALL) {
        doFetchWrapper(
          formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/cache/getAll"),
          {
            method: "post",
            mode: "cors",
            signal: abortController.signal,
            headers: getHeaders(),
            body: state.offsets[POR_CACHE_FILTER_BY.ALL] || 0,
          },
          getAllPorSuccess,
          getPorError,
          getPorAbortCb
        );
      } else if (state.filterBy === POR_CACHE_FILTER_BY.SYMBOL) {
        if (state.keys) {
          const criteria = state.keys.reduce(
            (acc, curr: string) => {
              acc.query.push({
                key: curr,
                offset: state.offsets[curr] || 0,
              });
              return acc;
            },
            { query: [] } as CriteriaQuery
          );
          doFetchWrapper(
            formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/cache/get-by/symbol"),
            {
              method: "post",
              mode: "cors",
              signal: abortController.signal,
              headers: getHeaders(),
              body: JSON.stringify(criteria),
            },
            getFilteredPorSuccess,
            getPorError,
            getPorAbortCb
          );
        } else {
          console.warn("No keys found for PVR symbol filter");
        }
      } else {
        if (state.keys) {
          const criteria = state.keys.reduce(
            (acc, curr: string) => {
              acc.query.push({
                key: curr,
                offset: state.offsets[curr] || 0,
              });
              return acc;
            },
            { query: [] } as CriteriaQuery
          );
          doFetchWrapper(
            formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/cache/get-by/mpid"),
            {
              method: "post",
              mode: "cors",
              signal: abortController.signal,
              headers: getHeaders(),
              body: JSON.stringify(criteria),
            },
            getFilteredPorSuccess,
            getPorError,
            getPorAbortCb
          );
        } else {
          console.warn("No keys found for PVR mpid filter");
        }
      }
    },
    [
      state.offsets,
      state.data,
      state.pending,
      state.filterBy,
      state.windowId,
      state.keys,
      state.exclude,
      state.requestFailedCount,
      state.status,
      tableDispatch,
    ]
  );

  // -- initial request --
  // should only have to run once as long as timeStamp
  // is persisted throughout the entire session
  useEffect(() => {
    if (state.makeInitialRequest && state.isPolling) {
      if (state.requestFailedCount > 0) {
        const timer = setTimeout(() => {
          getPorData(true);
        }, pollingIntervalMs);
        dispatch({ type: "SET_REQUEST_TIMER", payload: timer });
      } else {
        dispatch({ type: "SET_LOADING", payload: true });
        getPorData(true);
      }
    }
  }, [state.makeInitialRequest, getPorData, state.requestFailedCount, state.isPolling]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting && !state.requestAbort) {
      dispatch({ type: "SET_REQUESTING", payload: false });
      getPorData(false);
    } else if (!state.isPolling) {
      dispatch({ type: "SET_REQUEST_STATUS", payload: Status.NO_STATUS });
    }
  }, [state.isPolling, state.isRequesting, state.requestAbort, getPorData]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const usePorCacheDispatch =
  FACTORY.createContextHook<React.Dispatch<PorCacheAction | PorCacheAction[]>>(DISPATCH_CONTEXT);
export const usePorCacheState = FACTORY.createContextHook<PorCacheState>(STATE_CONTEXT);
