import React from "react";
import { FieldLoop, Forms, bannerFormFields } from "components/fields";

export const BannerManagerForm = () => {
  return (
    <FieldLoop
      form={Forms.SETTINGS_BANNER}
      fields={bannerFormFields}
      classNames={undefined}
      augmentOnCreate={undefined}
      portalRef={undefined}
      isReactFragment={true}
      isDisabled={undefined}
      containerRef={undefined}
      showLabel={undefined}
      augmentOnChange={undefined}
    />
  );
};
