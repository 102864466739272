import { CSSGrid, DataArray, Flex, FontAwesomeIcon, Header, Tooltip } from "@nef/core";
import React, { memo, useCallback, useState, useMemo } from "react";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";
import { useEqrcCacheDispatch } from "./dataCacheContext";
import {
  BREACHES,
  BREACHES_LABELS,
  ORDER_TYPE_BREACHES,
  ORDER_TYPE_BREACHES_WITH_ORDER_TYPE,
} from "./eqrc.types";
import { useRecapDispatch } from "components/tradeDetailRecap";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";

const StyledFlex = styled(Flex)<{ value: number }>`
  border: 1px solid rgb(230, 230, 230);
  border-radius: 8px;
  padding: 1rem;
  &:hover {
    ${props => `
      background-color: ${getColor("gray", 25)(props)};
    `}
  }
  ${props => `
    ${props.value === 0 ? `cursor: not-allowed;` : `cursor: pointer;`};
  `}
`;

const StyledText = styled.div`
  ${props => `
    font-size: 16px;
    fill: ${getColor("gray", 500)(props)};
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: top;

    & > i {
      height: 16px;
    }
  `}
`;

const StyledNumber = styled.div`
  ${props => `
    font-size: 22px;
    color: ${getColor("gray", 900)(props)};
    width:100%; 
    text-align:right;
  `}
`;

const StyledSpan = styled.span`
  font-size: 12px;
`;

const DetailToolTip = ({ data }: { data: TileData[] | undefined }) => {
  const formattedData = useMemo(() => {
    const parsedData = (data || []).reduce(
      (acc, curr) => {
        if (!Object.keys(ORDER_TYPE_BREACHES_WITH_ORDER_TYPE).includes(curr.name)) {
          return acc;
        }

        acc.state.set(curr.name, {
          label: BREACHES_LABELS[curr.name],
          value: curr.value,
        });

        if (curr.name === BREACHES.ORDER_TYPE) {
          acc.adjustedCount += curr.value;
        } else {
          acc.adjustedCount -= curr.value;
        }

        return acc;
      },
      { state: new Map(), adjustedCount: 0 }
    );

    const alertValue = parsedData.state.get(BREACHES.ORDER_TYPE);
    parsedData.state.set(BREACHES.ORDER_TYPE, { ...alertValue, value: parsedData.adjustedCount });

    const parsedDataToArray: TileData[] = [];
    parsedData.state.forEach(orderType => {
      parsedDataToArray.push(orderType);
    });
    return parsedDataToArray;
  }, [data]);

  return (
    <>
      <Header size={3}>Order Type Breach Details</Header>
      <DataArray data={formattedData} />
    </>
  );
};

export type TileData = { name: BREACHES; value: number; data?: TileData[] };
type TilesType = {
  data: TileData[];
};

const StyledToolTip = styled(Tooltip)`
  ${props => `width: 350px;

  & span, & h3 {
    color: ${getColor("gray", 0)(props)} !important ;
    font-weight: normal !important;
  }
`}
`;

const Tile = memo(({ name, value = 0, data }: TileData) => {
  const dispatch = useEqrcCacheDispatch();
  const recapDispatch = useRecapDispatch();
  const [userData] = useUserContext();

  const [isOpen, setIsOpen] = useState(false);

  const id = useMemo(
    () => `SingleOrderTileToolTip_${name?.replace(/\W/g, "-")}_${value}`,
    [name, value]
  );

  const handleClick = useCallback(
    (name: BREACHES | undefined) => () => {
      if (name !== undefined && value > 0) {
        dispatch({
          type: "SHOW_DETAILS",
          payload: { name: name },
        });

        recapDispatch({ type: "SET_OPEN", payload: true });
      }
    },
    [dispatch, recapDispatch, value]
  );

  const toggle = useCallback(
    (e?: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement> | Event) => {
      if (e?.type === "mouseenter" || e?.type === "focus") {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    },
    []
  );

  const toolTipString = useMemo(
    () => (value === 0 ? "No Breaches" : "Click to View Breaches"),
    [value]
  );
  return (
    <>
      <StyledFlex
        justifyContent="between"
        alignItems="center"
        flexDirection="column"
        key={`${name}_${value}`}
        onClick={handleClick(name)}
        id={id}
        value={value}
      >
        <StyledText>
          <TextWrapper name={name} />
          <Tooltip isOpen={isOpen} toggle={toggle} target={id}>
            {toolTipString}
          </Tooltip>
          {userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcShowSubOrderTypes] &&
            name === BREACHES.ORDER_TYPE && (
              <>
                <FontAwesomeIcon iconClassName="fas fa-info-circle" />

                <StyledToolTip
                  isOpen={isOpen}
                  toggle={toggle}
                  target={id}
                  placement="right"
                  maxContent={true}
                  offsetRight={10}
                >
                  <DetailToolTip data={data} />
                </StyledToolTip>
              </>
            )}
        </StyledText>
        <StyledNumber>{value.toLocaleString()}</StyledNumber>
      </StyledFlex>
    </>
  );
});

const TextWrapper = ({ name }: { name: BREACHES }) => {
  if (name === undefined) {
    return <></>;
  } else {
    return <StyledSpan>{BREACHES_LABELS[name]}</StyledSpan>;
  }
};

export const Tiles = ({ data }: TilesType) => {
  return (
    <CSSGrid cols={"repeat(6, minmax(0,1fr))"} rows={"repeat(2,1fr)"} gap={"1rem"}>
      {data
        .filter(({ name, value }) => {
          return !Object.keys(ORDER_TYPE_BREACHES).includes(name);
        })
        .map(({ name, value }: TileData) => (
          <Tile value={value} name={name} key={`${name}`} data={data} />
        ))}
    </CSSGrid>
  );
};
