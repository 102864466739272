import { NotificationHub } from "@nef/core";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
} from "react";
import { Status } from "wksConstantsTS";
import { formatUrl } from "utils/js.utils";
import { useUserContext } from "components/user";
import { Views } from "viewConstants";
import { PVR_CONFIGURATION_ENTITY, PVR_ALERT_TYPE, PvrConfigurationEntity } from "./fields";
import { getHashKey } from "components/topBar/alerts/pvr/constants";

export type UiAlertHashMap = {
  [key: string]: {
    [PVR_CONFIGURATION_ENTITY.popupActive]: boolean;
    [PVR_CONFIGURATION_ENTITY.logActive]: boolean;
  };
};

type PvrAlertConfigState = {
  data: PvrConfigurationEntity[];
  hashMap: UiAlertHashMap;
  requestStatus: Status;
  isRequesting: boolean;
  isLoading: boolean;
};

type PvrAlertConfigAction =
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_REQUESTING"; payload: boolean }
  | { type: "SET_REQUEST_STATUS"; payload: Status }
  | { type: "SET_DATA"; payload: PvrConfigurationEntity[] };

const pvrConfigDispatch = createContext<React.Dispatch<PvrAlertConfigAction> | undefined>(
  undefined
);
pvrConfigDispatch.displayName = "PvrConfigDispatch";
export const usePvrConfigDispatch = () => {
  const context = useContext(pvrConfigDispatch);
  if (context === undefined) {
    throw new Error("usePvrConfigDispatch must be used within a PvrConfigProvider");
  }
  return context;
};

const pvrConfigContext = createContext<
  [PvrAlertConfigState, React.Dispatch<PvrAlertConfigAction>] | undefined
>(undefined);
pvrConfigContext.displayName = "PvrConfigContext";
export const usePvrConfigContext = () => {
  const context = useContext(pvrConfigContext);
  if (context === undefined) {
    throw new Error("usePvrConfigContext must be used within a PvrConfigProvider");
  }
  return context;
};

const defaultState: PvrAlertConfigState = {
  data: [],
  hashMap: {},
  requestStatus: Status.NO_STATUS,
  isRequesting: false,
  isLoading: false,
};

const getActiveAlertTypes = (config: PvrConfigurationEntity): number[] => {
  const alertTypes: number[] = [];
  if (config[PVR_CONFIGURATION_ENTITY.porStatusAlertActive]) {
    alertTypes.push(PVR_ALERT_TYPE.POR_STATUS);
  }
  if (config[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive]) {
    alertTypes.push(PVR_ALERT_TYPE.POR_WINDOW);
  }
  if (config[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive]) {
    alertTypes.push(PVR_ALERT_TYPE.PV_RESUBMIT);
  }
  return alertTypes;
};

const DispatchFn = (
  state: PvrAlertConfigState,
  actions: PvrAlertConfigAction | PvrAlertConfigAction[]
): PvrAlertConfigState => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (
  state: PvrAlertConfigState,
  action: PvrAlertConfigAction
): PvrAlertConfigState => {
  switch (action.type) {
    case "SET_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      const isLoading = action.payload === true ? true : state.isLoading;
      return {
        ...state,
        isRequesting: action.payload,
        isLoading,
      };
    }
    case "SET_REQUEST_STATUS": {
      return { ...state, requestStatus: action.payload };
    }
    case "SET_DATA": {
      const data = action.payload;
      const hashMap = data.reduce((acc, curr) => {
        const isConfigEnabled = curr[PVR_CONFIGURATION_ENTITY.enabled];
        const isPopupActive = curr[PVR_CONFIGURATION_ENTITY.popupActive];
        const isLogActive = curr[PVR_CONFIGURATION_ENTITY.logActive];
        if (isConfigEnabled && (isPopupActive || isLogActive)) {
          const alertTypes = getActiveAlertTypes(curr);
          alertTypes.forEach(alertType => {
            const key = getHashKey({
              alertType,
              mpid: curr.mpid,
            });
            acc[key] = {
              [PVR_CONFIGURATION_ENTITY.popupActive]: !!(
                isPopupActive || acc[key]?.[PVR_CONFIGURATION_ENTITY.popupActive]
              ),
              [PVR_CONFIGURATION_ENTITY.logActive]: !!(
                isLogActive || acc[key]?.[PVR_CONFIGURATION_ENTITY.logActive]
              ),
            };
          });
        }
        return acc;
      }, {} as UiAlertHashMap);
      return { ...state, data, hashMap };
    }
    default:
      return { ...state };
  }
};

interface PvrConfigProviderProps {
  children: ReactNode;
  defaultData?: Partial<PvrAlertConfigState>;
}

export const PvrConfigProvider: React.FC<PvrConfigProviderProps> = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, { ...defaultState, ...defaultData });
  const [user] = useUserContext();

  const getPvrConfigData = useCallback(() => {
    const getPvrConfigSuccess = (json: PvrConfigurationEntity[]) => {
      dispatchF([
        { type: "SET_LOADING", payload: false },
        { type: "SET_DATA", payload: json },
      ]);
    };

    const getPvrConfigError = () => {
      const actions: PvrAlertConfigAction[] = [
        {
          type: "SET_LOADING",
          payload: false,
        },
      ];
      actions.push({
        type: "SET_REQUEST_STATUS",
        payload: Status.ERROR,
      });
      if (state.requestStatus !== Status.ERROR) {
        NotificationHub.send(
          "danger",
          "An error occurred while retrieving Price Reject Override (PRO) Alert configurations"
        );
      }

      dispatchF(actions);
    };

    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "alert/configuration/findByCreator"),
      {
        method: "get",
        headers: getHeaders(),
      },
      getPvrConfigSuccess,
      getPvrConfigError
    );
  }, [state.requestStatus]);

  useEffect(() => {
    if (user.allowed.views[Views.PVR_ALERTS]) {
      dispatchF({
        type: "SET_REQUESTING",
        payload: true,
      });
    }
  }, [user]);

  useEffect(() => {
    if (state.isRequesting) {
      dispatchF({ type: "SET_REQUESTING", payload: false });
      getPvrConfigData();
    }
  }, [getPvrConfigData, state.isRequesting]);

  return (
    <pvrConfigDispatch.Provider value={dispatchF}>
      <pvrConfigContext.Provider value={[state, dispatchF]}>{children}</pvrConfigContext.Provider>
    </pvrConfigDispatch.Provider>
  );
};
