import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useAlertDispatch } from "../context";
import { GenericAlerts } from "../genericAlerts";
import styled from "styled-components";
import { FontAwesomeIcon, Header } from "@nef/core";
import { getColor } from "@nef/style-utils";
import moment from "moment-timezone";
import { createConfigPopupTitle, usePtraAlertContext, usePtraAlertDispatch } from "./alertCache";
import { PTR_ALERT_TYPE_LABEL } from "components/settings/ptra/fields";
import {
  getIdentifierFromAlert,
  PTRA_BREACH_CACHE_MODEL,
  PTRA_CONFIG_CACHE_MODEL,
} from "./constants";
import { PTRA_CONFIGURATION_ENTITY } from "components/settings/ptra/fields";
import { LIMIT_SIDE_BREACH_VALUES } from "components/fields";
import { BreachAlertBody } from "./breachAlertBody";
import { AlertFooter, ALERT_FILTER } from "./alertFooter";
import { ConfigAlertBody } from "./configAlertBody";
import { GENERIC_ALERT_ITEM } from "../constant";

const Icon = styled(FontAwesomeIcon)`
  ${props => `
    font-size: 16px;
    color: ${getColor(props.isAction ? "danger" : "primary", 300)(props)};
  `}
`;

const Title = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const TitleTextAndIcon = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
`;

const StyledHeader = styled(Header)`
  margin: 0;
`;

const DateWrapper = styled.span`
  color: ${getColor("gray", 500)};
`;

const sortPtraAlerts = (a, b) => {
  return a.time - b.time;
};

const defaultFilter = ALERT_FILTER.ALL;
const ptraId = "ptra-alerts-id";
export const PtraAlertsContainer = () => {
  const [ptrAlertData] = usePtraAlertContext();
  const ptrAlertDispatch = usePtraAlertDispatch();
  const alertDispatch = useAlertDispatch();
  const [activeFilter, setActiveFilter] = useState(defaultFilter);
  const lastSetFilter = useRef(defaultFilter);
  const lastSetAlerts = useRef({ alerts: {}, itemSet: [] });

  useEffect(() => {
    alertDispatch({
      type: "SET_STATUS",
      payload: ptrAlertData.status,
    });
  }, [alertDispatch, ptrAlertData.status]);

  const getCurrentAlertItems = useCallback(
    filter => {
      let activeBreachAlerts;
      let activeConfigAlerts;
      switch (filter) {
        case ALERT_FILTER.READ:
          activeBreachAlerts = ptrAlertData.readBreachAlerts;
          activeConfigAlerts = ptrAlertData.readConfigAlerts;
          break;
        case ALERT_FILTER.UNREAD:
          activeBreachAlerts = ptrAlertData.unreadBreachAlerts;
          activeConfigAlerts = ptrAlertData.unreadConfigAlerts;
          break;
        case ALERT_FILTER.ALL:
        default:
          activeBreachAlerts = ptrAlertData.breachAlerts;
          activeConfigAlerts = ptrAlertData.configAlerts;
          break;
      }
      if (
        activeBreachAlerts !== lastSetAlerts.current.breachAlerts ||
        activeConfigAlerts !== lastSetAlerts.current.configAlerts
      ) {
        lastSetAlerts.current.breachAlerts = activeBreachAlerts;
        lastSetAlerts.current.configAlerts = activeConfigAlerts;
        const items = Object.values(activeBreachAlerts).reduce((acc, curr) => {
          // if configured for both sides, only show action alert
          const identifier = getIdentifierFromAlert(curr);
          if (!acc[identifier]?.isAction) {
            const isAction =
              curr[PTRA_CONFIGURATION_ENTITY.limitBreachSide] === LIMIT_SIDE_BREACH_VALUES.Own;
            const subtitle = <BreachAlertBody alert={curr} includeMarkAsRead={true} />;

            const time = moment(curr[PTRA_BREACH_CACHE_MODEL.streamTime], "H:mm:ss");
            acc[identifier] = {
              [GENERIC_ALERT_ITEM.ICON]: (
                <Icon
                  isAction={isAction}
                  iconClassName={isAction ? "fa-clock" : "fa-info-circle"}
                />
              ),
              [GENERIC_ALERT_ITEM.TITLE]: (
                <Title>
                  <TitleTextAndIcon>
                    <StyledHeader size={4}>
                      {`${PTR_ALERT_TYPE_LABEL[curr[PTRA_BREACH_CACHE_MODEL.alertTypes][0]]}`}
                    </StyledHeader>
                    <Icon
                      isAction={isAction}
                      iconClassName={isAction ? "fa-clock" : "fa-info-circle"}
                    />
                  </TitleTextAndIcon>
                  <DateWrapper>{time.format("hh:mm A")}</DateWrapper>
                </Title>
              ),
              [GENERIC_ALERT_ITEM.SUBTITLE]: subtitle,
              [GENERIC_ALERT_ITEM.ID]: identifier,
              isAction,
              time,
            };
          }
          return acc;
        }, {});
        Object.values(activeConfigAlerts).forEach(curr => {
          const identifier = getIdentifierFromAlert(curr);
          if (!items[identifier]?.isAction) {
            const isAction =
              curr[PTRA_CONFIGURATION_ENTITY.limitBreachSide] === LIMIT_SIDE_BREACH_VALUES.Own;
            const subtitle = (
              <ConfigAlertBody alert={curr} includeMarkAsRead={true} includeTooltip={true} />
            );

            const time = moment(curr[PTRA_CONFIG_CACHE_MODEL.lastUpdated]);
            items[identifier] = {
              [GENERIC_ALERT_ITEM.ICON]: (
                <Icon
                  isAction={isAction}
                  iconClassName={isAction ? "fa-clock" : "fa-info-circle"}
                />
              ),
              [GENERIC_ALERT_ITEM.TITLE]: (
                <Title>
                  <TitleTextAndIcon>
                    <StyledHeader size={4}>{createConfigPopupTitle(curr)}</StyledHeader>
                    <Icon
                      isAction={isAction}
                      iconClassName={isAction ? "fa-clock" : "fa-info-circle"}
                    />
                  </TitleTextAndIcon>
                  <DateWrapper>{time.format("hh:mm A")}</DateWrapper>
                </Title>
              ),
              [GENERIC_ALERT_ITEM.SUBTITLE]: subtitle,
              [GENERIC_ALERT_ITEM.ID]: identifier,
              isAction,
              time,
            };
          }
        });
        const itemSet = Object.values(items).sort(sortPtraAlerts);
        lastSetAlerts.current.itemSet = itemSet;
        return itemSet;
      }
      return lastSetAlerts.current.itemSet;
    },
    [
      ptrAlertData.breachAlerts,
      ptrAlertData.configAlerts,
      ptrAlertData.readBreachAlerts,
      ptrAlertData.readConfigAlerts,
      ptrAlertData.unreadBreachAlerts,
      ptrAlertData.unreadConfigAlerts,
    ]
  );

  const handleFilterChange = useCallback(
    value => {
      if (lastSetFilter.current === value) {
        alertDispatch({ type: "START_FORCE_REFRESH" });
      } else {
        setActiveFilter(value);
      }
    },
    [alertDispatch]
  );

  const handleNewActiveAlerts = useCallback(
    activeAlerts => {
      ptrAlertDispatch({
        type: "SET_ACTIVE_ALERTS",
        payload: activeAlerts,
      });
      alertDispatch({
        type: "SET_FOOTER",
        payload: (
          <AlertFooter
            activeFilter={activeFilter}
            onFilterChange={handleFilterChange}
            activeAlerts={activeAlerts}
          />
        ),
      });
    },
    [activeFilter, alertDispatch, handleFilterChange, ptrAlertDispatch]
  );

  useEffect(() => {
    const actions = [];
    actions.push({
      type: "SET_ALERTS",
      payload: {
        alerts: getCurrentAlertItems(activeFilter),
        totalNumAlerts:
          Object.keys(ptrAlertData.breachAlerts).length +
          Object.keys(ptrAlertData.configAlerts).length,
      },
    });
    if (lastSetFilter.current !== activeFilter) {
      actions.push({
        type: "START_FORCE_REFRESH",
      });
      lastSetFilter.current = activeFilter;
    }
    alertDispatch(actions);
  }, [
    activeFilter,
    alertDispatch,
    getCurrentAlertItems,
    handleFilterChange,
    ptrAlertData.breachAlerts,
    ptrAlertData.configAlerts,
  ]);

  useEffect(() => {
    alertDispatch({
      type: "SET_FOOTER",
      payload: <AlertFooter activeFilter={activeFilter} onFilterChange={handleFilterChange} />,
    });
  }, [activeFilter, alertDispatch, handleFilterChange]);

  return (
    <GenericAlerts
      id={ptraId}
      iconClassName="fa-bell"
      tooltip="Post - Trade Risk Alerts"
      emptyStateTitle="No Alerts Found"
      emptyStateSubtitle="Today's Post - Trade Risk alerts matching a configuration for this user will be logged
  here"
      emptyFilterTitle={`No ${activeFilter} Alerts`}
      emptyFilterSubtitle="Adjust the filter to view alerts"
      itemHeight={105}
      onChangeAlertSetShown={handleNewActiveAlerts}
      hasUnread={
        Object.keys(ptrAlertData.unreadBreachAlerts).length +
          Object.keys(ptrAlertData.unreadConfigAlerts).length >
        0
      }
    />
  );
};
