import { FontAwesomeIcon, Progress } from "@nef/core";
import { allowedColors } from "@nef/core/lib/components/Progress";
import { getColor } from "@nef/style-utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Status } from "wksConstants";
import { usePorWindowCacheDispatch, usePorWindowCacheState } from "./cache/porWindowCache";
import { getPorWindowDateFromDateTime, PorWindow } from "./constant";

const StyledProgress = styled(Progress)`
  width: 100%;
`;

const ProgressWrapper = styled.div<{ $marginTopPx: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${({ $marginTopPx }) => $marginTopPx}px;
`;

const ProgressTextBg = styled.div<{ $width: number }>`
  ${props => `
    background-color: ${getColor("gray", 900)(props)};
    opacity: 0.4;
    width: ${(props.$width % 100).toFixed(2)}%;
    height: 100%;
    position: absolute;
    left: 0;
    border-radius: 3px;
    transition: width 1s;
  `}
`;

const ProgressText = styled.span`
  ${props => `
    position: absolute;
    color: ${getColor("gray", 0)(props)};
    font-size: 12px;
    line-height: 2px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    letter-spacing: .5px;
  `}
`;

const RefreshWrapper = styled.div<{ $isRefreshing: boolean }>`
  ${props => `
    position: absolute;
    right: 3px;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: ${getColor("gray", 0)(props)};
    cursor: pointer;
  `}
`;

const calculateTimer = (now: number, end: number) => {
  const hours = Math.floor((end - now) / 3600000);
  const minutes = Math.floor((end - now) / 60000) - hours * 60;
  const seconds = Math.floor((end - now) / 1000) - hours * 3600 - minutes * 60;
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const defaultMessage = "Finding Next Approval Window...";

const sortWindows = (a: any, b: any) => {
  return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
};

enum WindowStatus {
  UNSET = "UNSET",
  DONE = "DONE",
  ERROR = "ERROR",
}

type PorWindowTimerProps = {
  marginTopPx?: number;
};

export const PorWindowTimer: React.FC<PorWindowTimerProps> = ({ marginTopPx = 0 }) => {
  const porWindows = usePorWindowCacheState();
  const [currWindow, setCurrWindow] = useState<PorWindow | WindowStatus>(WindowStatus.UNSET);
  const [message, setMessage] = useState<string>(defaultMessage);
  const [color, setColor] = useState<allowedColors>("warning");
  const [progress, setProgress] = useState(100);
  const [isRefreshing, setRefreshing] = useState(false);
  const porWindowDispatch = usePorWindowCacheDispatch();
  const updateInterval = useRef<NodeJS.Timeout>();

  const findNextWindow = useCallback(() => {
    setRefreshing(false);
    if (porWindows.status === Status.ERROR) {
      setCurrWindow(WindowStatus.ERROR);
    } else {
      const sortedWindows = porWindows.windows.sort(sortWindows);
      for (let i = 0; i < sortedWindows.length; i++) {
        const porWindow = sortedWindows[i];
        const now = new Date().getTime();
        const end = getPorWindowDateFromDateTime(porWindow.endTime).getTime();
        if (now < end) {
          setCurrWindow(porWindow);
          return;
        }
      }
      setCurrWindow(WindowStatus.DONE);
    }
  }, [porWindows.windows, porWindows.status]);

  useEffect(() => {
    findNextWindow();
  }, [findNextWindow]);

  const updateTimer = useCallback(() => {
    setColor("danger");
    setMessage("Error finding Windows");
    setProgress(100);
    if (currWindow === WindowStatus.ERROR) {
    } else if (currWindow === WindowStatus.UNSET) {
      setColor("warning");
      setMessage(defaultMessage);
      setProgress(100);
    } else if (currWindow === WindowStatus.DONE) {
      setColor("default");
      setMessage("No more window events today");
      setProgress(100);
      clearInterval(updateInterval.current);
    } else {
      const now = new Date().getTime();
      const start = getPorWindowDateFromDateTime(currWindow.startTime).getTime();
      const end = getPorWindowDateFromDateTime(currWindow.endTime).getTime();
      if (now < start) {
        setMessage(`Window Opening in ${calculateTimer(now, start)}`);
        setColor("primary");
        setProgress(100);
      } else if (now < end) {
        setColor("success");
        setMessage(`Window Closing in ${calculateTimer(now, end)}`);
        setProgress(100 - ((end - now) / (end - start)) * 100);
      } else {
        findNextWindow();
        setColor("warning");
        setProgress(100);
        setMessage(defaultMessage);
      }
    }
  }, [currWindow, findNextWindow]);

  useEffect(() => {
    updateTimer();
    updateInterval.current = setInterval(() => {
      updateTimer();
    }, 1000);
    return () => clearInterval(updateInterval.current);
  }, [updateTimer]);

  const refreshWindows = useCallback(() => {
    if (!isRefreshing) {
      setMessage("Refreshing Windows");
      setRefreshing(true);
      setTimeout(() => {
        porWindowDispatch({
          type: "REFRESH_WINDOWS",
        });
      }, 3000);
    }
  }, [porWindowDispatch, isRefreshing]);

  return (
    <ProgressWrapper $marginTopPx={marginTopPx}>
      <StyledProgress value={100} color={color}></StyledProgress>
      <ProgressTextBg $width={progress}></ProgressTextBg>
      <ProgressText>{message}</ProgressText>
      {currWindow === WindowStatus.DONE ? (
        <RefreshWrapper onClick={refreshWindows} $isRefreshing={isRefreshing}>
          <FontAwesomeIcon iconClassName={`fa-sync`} color="inherit" />
        </RefreshWrapper>
      ) : (
        <></>
      )}
    </ProgressWrapper>
  );
};
