import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";
import { ColorType } from "@nef/theme";
import { Button, FontAwesomeIcon } from "@nef/core";
import {
  Banner,
  BANNER_FORM,
  BANNER_THEME,
  useBannerContext,
  useBannerDispatch,
} from "components/settings/banner";
import { doFetchWrapper } from "network";
import { getHeaders } from "keycloak";
import { formatUrl } from "utils/js.utils";

export const BANNER_HEIGHT = 50;
const BannerWrapper = styled.div<{ $themeColor: ColorType }>`
  ${props => `
        height: ${BANNER_HEIGHT}px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: ${getColor(props.$themeColor, 600)(props)};
        border-bottom: 1px solid ${getColor(props.$themeColor, 700)(props)};
        padding: .25rem 1rem;
        box-sizing: border-box;
        color: ${getColor("gray", 0)(props)};
        font-weight: 400;
        justify-content: space-between;
        grid-gap: 1rem;
    `}
`;

const Message = styled.div`
  display: flex;
  grid-gap: 1rem;
  justify-content: start;
`;

const Icon = styled(FontAwesomeIcon)<{ $themeColor: ColorType; onClick?: () => void }>`
  ${props => `
    color: ${getColor("gray", 0)(props)};
    cursor: ${props.onClick ? "pointer" : "default"};
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  grid-gap: 0.5rem;
`;

type BannerAnnouncementProps = {
  themeColor: ColorType;
  banner: Banner;
};

const DEFAULT_LINK_LABEL = "Link";
export const BannerAnnouncement: React.FC<BannerAnnouncementProps> = ({ banner, themeColor }) => {
  const bannerDispatch = useBannerDispatch();
  const [isCloseButtonVisible, setCloseButtonVisible] = useState(false);

  const handleClose = useCallback(() => {
    bannerDispatch({
      type: "REMOVE_BANNER",
      payload: banner[BANNER_FORM.ID] as string,
    });
    const cb = () => {
      bannerDispatch({
        type: "SET_READ",
        payload: banner[BANNER_FORM.ID] as string,
      });
    };
    const errorCb = () => {
      console.error(`Error marking banner ${banner[BANNER_FORM.ID]} as read`);
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "savebannerread"),
      {
        method: "post",
        headers: getHeaders(),
        body: banner[BANNER_FORM.ID],
      },
      cb,
      errorCb
    );
  }, [banner, bannerDispatch]);

  const handleClickLink = useCallback(() => {
    window.open(banner[BANNER_FORM.LINK_URL], "_blank");
    setCloseButtonVisible(true);
  }, [banner]);

  return (
    <BannerWrapper $themeColor={themeColor}>
      <Message>
        <Icon iconClassName="fa-bullhorn" $themeColor={themeColor} />
        {banner[BANNER_FORM.MESSAGE]}
      </Message>
      {banner[BANNER_FORM.LINK_URL] ? (
        <ButtonWrapper>
          <Button
            size="xs"
            color={
              banner[BANNER_FORM.THEME] === BANNER_THEME.SECONDARY
                ? BANNER_THEME.PRIMARY
                : BANNER_THEME.SECONDARY
            }
            onClick={handleClickLink}
          >
            {banner[BANNER_FORM.LINK_LABEL] ? banner[BANNER_FORM.LINK_LABEL] : DEFAULT_LINK_LABEL}
          </Button>
          {isCloseButtonVisible ? (
            <Button size="xs" color="light" onClick={handleClose}>
              Close
            </Button>
          ) : (
            <></>
          )}
        </ButtonWrapper>
      ) : (
        <Icon iconClassName="fa-times" $themeColor={themeColor} onClick={handleClose} />
      )}
    </BannerWrapper>
  );
};

const BannersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MAX_BANNERS_VISIBLE = 3;
export const Banners = () => {
  const [bannerData] = useBannerContext();

  const Announcements = useMemo(() => {
    const announcements = [];
    for (let i = 0; i < bannerData.activeBanners.length && i < MAX_BANNERS_VISIBLE; i++) {
      const banner = bannerData.activeBanners[i];
      announcements.push(
        <BannerAnnouncement
          key={banner[BANNER_FORM.ID]}
          themeColor={banner[BANNER_FORM.THEME]}
          banner={banner}
        />
      );
    }
    return announcements;
  }, [bannerData.activeBanners]);

  return <BannersContainer>{Announcements}</BannersContainer>;
};
