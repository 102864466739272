export const BANNER_FIELD_PREFIX = "banner_";

export enum BANNER_THEME {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  DANGER = "danger",
  WARNING = "warning",
}

export enum BANNER_FORM {
  ID = "id",
  IS_ENABLED = "enabled",
  DATE_RANGE = "dateRange",
  START_DATE = "startDate",
  END_DATE = "endDate",
  MESSAGE = "message",
  THEME = "theme",
  LINK_URL = "linkUrl",
  LINK_LABEL = "linkLabel",
}

export type Banner = {
  [BANNER_FORM.ID]?: string;
  [BANNER_FORM.IS_ENABLED]: boolean;
  [BANNER_FORM.START_DATE]: string;
  [BANNER_FORM.END_DATE]: string;
  [BANNER_FORM.MESSAGE]: string;
  [BANNER_FORM.THEME]: BANNER_THEME;
  [BANNER_FORM.LINK_URL]?: string;
  [BANNER_FORM.LINK_LABEL]?: string;
};
