import { Tag } from "@nef/core";
import moment from "moment-timezone";
import { POR, PORModel } from "components/pvRejects/constant";
import { PVR_DATE_TIME_FORMAT } from "../client/columns";

export type Row = {
  symbol: string;
  requestId: string;
  requestStatus: string;
  lastUpdate: string;
};

export const columns = [
  { Header: "Symbol", id: "symbol", accessor: "symbol", width: 100 },
  { Header: "MPID", id: "mpid", accessor: "mpid", width: 100 },
  { Header: "Price", id: "price", accessor: "price", width: 100 },
  { Header: "Quantity", id: "quantity", accessor: "quantity", width: 100 },
  { Header: "Reject Reason", id: "rejectReason", accessor: "rejectReason", width: 200 },
];

export enum PORModel2 {
  SYMBOL = "symbol",
  MPID = "mpid",
  REQUEST_STATUS = "requestStatus",
  REQUESTOR = "requestor",
  REQUEST_ID = "requestId",
  LAST_UPDATE_TIME = "lastUpdateTime",
}

export const columns3 = [
  { Header: "Symbol", id: "symbol", accessor: "symbol", width: 60 },
  { Header: "MPID", id: "mpid", accessor: "mpid", width: 60 },
  {
    Header: "Request Status",
    id: "status",
    accessor: "status",
    width: 110,
    Cell: ({ row }: { row: any }) => {
      let color = "success";
      if (row.original.status === "DENIED") {
        color = "danger";
      } else if (row.original.status === "PENDING") {
        color = "primary";
      } else if (row.original.status === "EXPIRED") {
        color = "warning";
      }
      return <Tag color={color}>{row.original.status}</Tag>;
    },
  },
  { Header: "Requestor", id: "requestorEmail", accessor: "requestorEmail", width: 150 },
  { Header: "Request ID", id: "id", accessor: "id", width: 80 },
  {
    Header: "Last Update Time",
    id: "lastUpdateDatetime",
    accessor: (row: POR) => {
      const currentTimeZone = moment.tz("America/New_York").format("Z");
      return moment(`${row[PORModel.LAST_UDPATE]}${currentTimeZone}`).format(PVR_DATE_TIME_FORMAT);
    },
    width: 130,
  },
];
export const columns2 = columns3;
