import { FormDropdown } from "@nef/core";
import { FieldBox } from "components/styled";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { execOrReturn } from "utils/js.utils";
import { final, FORM_KEY } from ".";
import { useUserContext } from "../user";
import { useSelectOptionContext, useSelectOptionDispatch } from "./optionContext";
import { SelectOption } from "types";
import { useFormContext } from "components/form";

const empty: any[] = [];
const WorkXDropdown2 = (props: any) => {
  const [userData] = useUserContext();
  const {
    style,
    isRequired,
    form,
    name,
    handleInputChange: onInputChange,
    isCountingParentsEnabled,
    id,
  } = props;
  const [formData] = useFormContext();
  const stateCounter = useRef(0);
  const propCounter = useRef(0);

  const value = useMemo(() => {
    const curr = formData[form.key].fields[name];
    return Array.isArray(curr) ? curr : empty;
  }, [form, formData, name]);

  const [{ options, disabledMap, loadingMap }] = useSelectOptionContext();
  const [stateValue, setStateValue] = useState(value);
  const optionDispatch = useSelectOptionDispatch();

  useEffect(() => {
    propCounter.current += 1;
    if (value !== stateValue && propCounter.current >= stateCounter.current) {
      setStateValue(value === undefined ? empty : value);
      propCounter.current = 0;
      stateCounter.current = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(
    () => () => {
      optionDispatch({
        type: "RESET_OPTIONS_CACHE",
        payload: { form: form.id },
      });
    },
    [form.id, optionDispatch]
  );

  const handleChange = useCallback(
    (e: any, other: any) => {
      const fieldDataKey = id ? id : name;
      const fieldData = execOrReturn(final[fieldDataKey], {
        form,
        entitlements: userData.entitlements,
      });

      if (typeof e === "object") {
        let { value } = e;
        if (
          (other && other.action === "clear") ||
          !e ||
          e.clearSelection ||
          e.value?.clearSelection ||
          (Array.isArray(e.value) && e.value.length === 0)
        ) {
          value = [];
        }

        let formKey = form.id || form;
        value = value.map((v: any) => ({
          label: options[formKey as FORM_KEY][id]
            ? options[formKey as FORM_KEY][id].find((opt: SelectOption) => opt.value === v)?.label
            : v,
          value: v,
        }));
        stateCounter.current += 1;
        setStateValue(value);
        if (onInputChange) {
          onInputChange({
            id,
            name,
            value,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [id, name, form, userData.entitlements, onInputChange, options]
  );

  const { helpText, disabled } = useMemo(() => {
    let { helpText, disabled } = props;
    if (disabledMap[props.id]?.has(form.id)) {
      disabled = true;
    }

    if (loadingMap[props.id]?.has(form.id)) {
      helpText = "Loading...";
      disabled = true;
    }

    return { helpText, disabled };
  }, [props, loadingMap, disabledMap, form.id]);

  const optionsToUse = options[form.id as FORM_KEY][id];
  let valueToUse = stateValue;

  if (!stateValue || stateValue.length === 0) {
    valueToUse = empty;
  }

  return (
    <FieldBox paddingBottom={3} marginRight={3} style={style} $isRequired={isRequired}>
      <FormDropdown
        {...props}
        id={`${props.id || props.name}_${form.key}`}
        headers={true}
        pageSize={1000}
        value={(valueToUse || []).map((v: any) => v.value)}
        onChange={handleChange}
        placement="bottom-start"
        oppositeOnOverflow={true}
        isCountingParentsEnabled={isCountingParentsEnabled}
        helpText={helpText}
        disabled={disabled}
        options={optionsToUse}
        isSearchable={true}
      />
    </FieldBox>
  );
};

export default memo(WorkXDropdown2);
