import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { MAX_RETRY } from "wksConstants";
import { AlertRead } from "../pvr/constants";

export enum PROMISE_STATUS {
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

interface PostTradeRiskAlert {
  counterPartyRole: string;
  tranId: string;
  symbol: string;
  limitValue: number;
  side: string;
  alertType: number;
  breachPartyRole: string;
  quantity: number;
  tradeDigit: string;
  notionalAmt: number;
  oldState: number;
  breachPartyClearingNum: string;
  tranSource: string;
  counterPartyGU: string;
  breachPartyMpid: string;
  counterPartyMpid: string;
  price: number;
  counterPartyClearingNum: string;
  breachPartyGU: string;
  soupSequence: number;
  id: string;
  newState: number;
  timestamp: number;
}

const cacheCallback = (resolve: Function) => (json: PostTradeRiskAlert[]) => {
  resolve(json);
};

const readCallback = (resolve: Function) => (json: AlertRead[]) => {
  resolve(json);
};

const errorCallback = (reject: Function) => () => {
  reject();
};

export const getAllAlertData = (user: any, abortSignal: AbortSignal) => {
  const getCacheAlerts = new Promise<PostTradeRiskAlert[]>((resolve, reject) => {
    doFetchWrapper(
      formatUrl(
        user[INITIAL_DATA_MODEL.config][USER_CONFIG_MODEL.ptraCacheUrl],
        `/profile/range/categories/${user.userId}`
      ),
      {
        method: "post",
        mode: "cors",
        signal: abortSignal,
        headers: getHeaders(),
        body: JSON.stringify([
          {
            category: "ptra-risk-config-alerts",
            profile: "riskConfigAlert",
            from: 0,
            to: -1,
          },
          {
            category: "ptra-breach-alerts",
            profile: "breachAlert",
            from: 0,
            to: -1,
          },
        ]),
      },
      cacheCallback(resolve),
      errorCallback(reject),
      undefined,
      undefined,
      MAX_RETRY
    );
  });

  const getReadAlerts = new Promise<AlertRead[]>((resolve, reject) => {
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "getptralerts"),
      {
        method: "post",
        headers: getHeaders(),
      },
      readCallback(resolve),
      errorCallback(reject)
    );
  });
  return Promise.allSettled([getCacheAlerts, getReadAlerts]);
};
