import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NotificationHub } from "@nef/core";
import { useFormContext } from "components/form";
import { originatingFormKey } from "components/form/constants";
import { useStandardTableContext, useStandardTableDispatch } from "components/standardTable";
import { getHeaders } from "keycloak";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { doFetchWrapper } from "../../../network";
import { usePvrConfigDispatch } from "./configContext";
import { PVR_CONFIGURATION_ENTITY, PVR_VALIDATION_RESULT } from "./fields";

const StyledModalBody = styled(ModalBody)`
  height: 90vh;
  overflow: overlay;
`;

const Description = styled.span`
  font-size: 12px;
  padding: 1rem 1rem 0rem 1rem;
`;

const FooterDescription = styled.span`
  padding-right: 1rem;
`;

export const PvrEditModal = ({
  form,
  validationTable,
  riskConfigTable,
  RiskAlertForm,
  ValidationTable,
  isVisible,
  onClose,
}) => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const [stepNumber, setStepNumber] = useState(0);
  const [hasError, setHasError] = useState(true);
  const configDispatch = usePvrConfigDispatch();

  const { ids, update } = useMemo(() => {
    const edits = formData[form.key].fields;
    const update = Object.entries(edits).reduce((acc, [key, opt]) => {
      if (key !== originatingFormKey) {
        acc[key] = !!opt.value;
      }
      return acc;
    }, {});

    const ids = Object.keys(tableData[riskConfigTable].selected).reduce((acc, key) => {
      if (tableData[riskConfigTable].selected[key] === true) {
        const tableRowId = parseInt(key);
        const row = Object.assign({}, tableData[riskConfigTable].data[tableRowId], update);
        acc.push(row[PVR_CONFIGURATION_ENTITY.id]);
      }
      return acc;
    }, []);
    return { ids, update };
  }, [form, formData, riskConfigTable, tableData]);

  const handleCloseEdit = useCallback(() => {
    setStepNumber(0);
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose]);

  const handleSaveEdit = useCallback(() => {
    const success = data => {
      NotificationHub.send("success", `Edited ${data.length} alert configuration(s)`);
      configDispatch({
        type: "SET_REQUESTING",
        payload: true,
      });
      handleCloseEdit();
    };
    const error = () => {
      NotificationHub.send("danger", `Error editing alert configuration(s)`);
      handleCloseEdit();
    };

    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "alert/configuration/update"),
      {
        method: "put",
        headers: getHeaders(),
        body: JSON.stringify({ ids, update }),
      },
      success,
      error
    );
  }, [ids, update, configDispatch, handleCloseEdit]);

  const FooterText = useMemo(() => {
    const editCount = Object.keys(formData[form.key].fields).filter(
      field => field !== originatingFormKey
    ).length;
    const selectedCount = Object.keys(tableData[riskConfigTable].selected).length;
    let text = "No changes have been made";
    if (editCount > 0) {
      text = `${editCount} value(s) will be updated on ${selectedCount} configuration(s)`;
    }
    return <FooterDescription>{text}</FooterDescription>;
  }, [form, formData, riskConfigTable, tableData]);

  const handleBack = useCallback(() => {
    setHasError(true);
    setStepNumber(0);
  }, []);

  const handleValidateEdit = useCallback(() => {
    const success = data => {
      const {
        [PVR_VALIDATION_RESULT.entities]: entities,
        [PVR_VALIDATION_RESULT.hasError]: hasError,
      } = data;
      setHasError(hasError);
      entities.forEach(config => {
        if (data[PVR_VALIDATION_RESULT.errors]?.[config[PVR_CONFIGURATION_ENTITY.id]]) {
          config[PVR_VALIDATION_RESULT.errors] =
            data[PVR_VALIDATION_RESULT.errors][config[PVR_CONFIGURATION_ENTITY.id]];
        } else {
          delete config[PVR_VALIDATION_RESULT.errors];
        }
        if (data[PVR_VALIDATION_RESULT.warnings]?.[config[PVR_CONFIGURATION_ENTITY.id]]) {
          config[PVR_VALIDATION_RESULT.warnings] =
            data[PVR_VALIDATION_RESULT.warnings][config[PVR_CONFIGURATION_ENTITY.id]];
        } else {
          delete config[PVR_VALIDATION_RESULT.warnings];
        }
      });
      tableDispatch([
        {
          type: "SET_TABLE_DATA",
          payload: {
            table: validationTable,
            data: entities,
          },
        },
        { type: "SET_NOT_LOADING", payload: { table: validationTable } },
      ]);
    };
    const error = errors => {
      NotificationHub.send("danger", "An error occurred while validating.");
    };
    tableDispatch({
      type: "SET_LOADING",
      payload: { table: validationTable },
    });
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "alert/configuration/validateUpdate"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ ids, update }),
      },
      success,
      error
    );
    setStepNumber(1);
  }, [ids, validationTable, tableDispatch, update]);

  return (
    <Modal
      isOpen={isVisible}
      closeOnOutsideClick={true}
      toggle={handleCloseEdit}
      size={stepNumber === 0 ? "md" : "flex"}
    >
      <ModalHeader toggle={handleCloseEdit} title="Edit Alert(s) for Post - Trade Risk" />
      {stepNumber === 0 ? (
        <Description>
          Any changes made to the fields below will be applied to all of the selected alert
          configurations. Empty fields will not be applied and those values will remain unchanged on
          each of the selected alert configurations.
        </Description>
      ) : (
        <></>
      )}
      <StyledModalBody>
        {stepNumber === 0 ? <RiskAlertForm /> : <ValidationTable table={validationTable} />}
      </StyledModalBody>
      <ModalFooter>
        {FooterText}
        {stepNumber === 0 ? (
          <>
            <Button outline={true} onClick={handleCloseEdit}>
              Close
            </Button>
            <Button onClick={handleValidateEdit}>Next</Button>
          </>
        ) : (
          <>
            <Button outline={true} onClick={handleBack}>
              Back
            </Button>
            <Button
              onClick={handleSaveEdit}
              disabled={hasError || tableData[validationTable].isLoading}
            >
              Save
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};
