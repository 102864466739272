import { doFetchWrapper } from "network";
import { getHeaders } from "keycloak";
import { formatUrl } from "utils/js.utils";
import { NotificationHub } from "@nef/core";
import { Banner, BANNER_FORM } from "./constant";

export const handleSaveBanner = ({
  bannerDispatch,
  onDone,
  banner,
}: {
  bannerDispatch: any;
  onDone: () => void;
  banner: Banner;
}) => {
  const onSuccess = () => {
    NotificationHub.send("success", "Created banner announcement");
    bannerDispatch({
      type: "START_REFRESH",
    });
    if (typeof onDone === "function") {
      onDone();
    }
  };
  const onError = () => {
    NotificationHub.send("danger", "Error saving banner");
    if (typeof onDone === "function") {
      onDone();
    }
  };
  doFetchWrapper(
    formatUrl(process.env.REACT_APP_URL_USER_WS, "banner"),
    {
      method: banner[BANNER_FORM.ID] ? "put" : "post",
      headers: getHeaders(),
      body: JSON.stringify(banner),
    },
    onSuccess,
    onError
  );
};
