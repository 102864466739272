import {
  ExportLimitTypes,
  final,
  GrayableFields,
  PriceTradeDigit,
  symbolOrCUSIP,
  SelectOptions,
  validationEngine,
  alertFrequencyOptions,
  AlertFrequencyValues,
  FormFields,
  ScanDateType,
  REJECT_DATE_OPTIONS,
} from "components/fields";

import _ from "lodash";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { execOrReturn, safeParseJSON } from "utils/js.utils";
import { RequestResult, SettingNames } from "wksConstants";
import { SELECT_ALL, DEFAULT_CONFIRM_REPORT_QUANTITY_NUMBER } from "../../wksConstants";
import {
  FieldNames,
  Forms,
  clearReportRiskOptions,
  WORKX_THEME_VALUE,
  workXThemeOptions,
  WORKX_NOTIFICATION_PLACEMENT_VALUES,
  workXNotificationPlacementOptions,
} from "../fields/fieldConstants";
import { originatingFormKey } from "./constants";
import { OptionConstants } from "components/fields";
import { KeycloakRoles, useUserContext, INITIAL_DATA_MODEL } from "../user";
import { PTRA_CONFIGURATION_ENTITY } from "components/settings/ptra/fields";
import { EqrcFields, EQRC_RULE_STATUS } from "components/settings/eqrc/constants";
import { useMPIDOptionsContext } from "components/user/mpidContext";
import {
  RASH_CAPACITY_OPTIONS,
  RASH_CAPACITY_VALUE,
  RASH_ORDER,
  RASH_PEG_TYPE_OPTIONS,
  RASH_PEG_TYPE_VALUE,
  RASH_SIDE_OPTIONS,
  RASH_SIDE_VALUE,
} from "components/orderEntry/constants";
import { Decimal } from "decimal.js";
import { DATE_FILTER } from "components/pvRejects/constant";
import { PV_REJECT_TOP } from "components/pvRejects/client/constant";
import { BANNER_FORM } from "components/settings/banner";

export const formDispatch = createContext();
formDispatch.displayName = "FormDispatch";
export const useFormDispatch = () => {
  return useContext(formDispatch);
};

export const formContext = createContext();
formContext.displayName = "FormContext";
export const useFormContext = () => {
  return useContext(formContext);
};

const alertFrequencyDefault = alertFrequencyOptions.find(
  opt => opt.value === AlertFrequencyValues.REAL_TIME
);
export const DEFAULT_SCAN_DATE_TYPE = ScanDateType.TRADE_DATE;
const defaultValues = {
  [Forms.TR_REPORT.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.TR_MODIFY.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.TR_REPAIR.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.TR_COPY.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.UPLOAD_REPAIR.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.ST_MODIFY.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.ST_REPAIR.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.ST_COPY.key]: { [FieldNames.priceTradeDigit]: PriceTradeDigit.PER_SHARE },
  [Forms.TR_SCAN.key]: { [FieldNames.scanDateType]: DEFAULT_SCAN_DATE_TYPE },
  [Forms.PTR_EXPORT_LIMITS.key]: { [FieldNames.exportLimitType]: ExportLimitTypes.INTRADAY },
  [Forms.PTRA_CONFIG.key]: {
    [PTRA_CONFIGURATION_ENTITY.settingChangeAlertFrequency]: alertFrequencyDefault,
    [PTRA_CONFIGURATION_ENTITY.breachAlertFrequency]: alertFrequencyDefault,
    [PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive]: false,
    [PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive]: false,
  },
  [Forms.PTRA_AGU_CONFIG.key]: {
    [PTRA_CONFIGURATION_ENTITY.settingChangeAlertFrequency]: alertFrequencyDefault,
    [PTRA_CONFIGURATION_ENTITY.breachAlertFrequency]: alertFrequencyDefault,
    [PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive]: false,
    [PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive]: false,
  },
  [Forms.RD_CUSIP.key]: { [FieldNames.symbolOrCUSIP]: symbolOrCUSIP.SYMBOL },
  [Forms.EQRC_MARKET_IMPACT_CHECK.key]: { [EqrcFields.marketImpact]: false },
  [Forms.EQRC_ORDER_TYPE.key]: {
    [EqrcFields.isoOrders]: false,
    [EqrcFields.shortSaleOrders]: false,
    [EqrcFields.nonMarketOrders]: false,
    [EqrcFields.premarketTrading]: false,
    [EqrcFields.postMarketTrading]: false,
    [EqrcFields.shortSaleExempt]: false,
  },
  [Forms.EQRC_ALERT_CONFIG.key]: {
    [EqrcFields.active]: false,
  },
  [Forms.EQRC_EDIT_STATUS_MENU.key]: {
    [EqrcFields.status]: EQRC_RULE_STATUS.Active,
  },
  [Forms.EQRC_ACTIONS.key]: {
    [EqrcFields.mpid]: undefined,
    [EqrcFields.port]: undefined,
    [EqrcFields.exchange]: undefined,
  },
  [Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key]: {
    [EqrcFields.status]: EQRC_RULE_STATUS.Active,
  },
  [Forms.ORDER_ENTRY.key]: {
    [RASH_ORDER.route]: "INET",
    [RASH_ORDER.capacity]: RASH_CAPACITY_OPTIONS[RASH_CAPACITY_VALUE.PRINCIPAL],
    [RASH_ORDER.side]: RASH_SIDE_OPTIONS[RASH_SIDE_VALUE.BUY],
    [RASH_ORDER.pegType]: RASH_PEG_TYPE_OPTIONS[RASH_PEG_TYPE_VALUE.NONE],
    [RASH_ORDER.discretionPegType]: RASH_PEG_TYPE_OPTIONS[RASH_PEG_TYPE_VALUE.NONE],
  },
  [Forms.PV_MONITOR_MEMBER_TOP.key]: {
    [PV_REJECT_TOP.rejectDate]: REJECT_DATE_OPTIONS[DATE_FILTER.ALL],
  },
  [Forms.SETTINGS_BANNER.key]: {
    [BANNER_FORM.IS_ENABLED]: false,
  },
};

let constructState = {
  selectAllMpidCount: null,
};

Object.entries(Forms).forEach(
  ([key, value]) =>
    (constructState[value.key] = {
      fields: {
        [originatingFormKey]: value,
        ...defaultValues[value.key],
      },
      errors: {},
      totalFormErrors: 0,
      grayedFields: [],
      globalErrorMessage: "",
      isLoading: false,
      confirmMessages: {},
    })
);
// we need defaults for stats settings
Object.assign(constructState[Forms.SETTINGS_STATS.key].fields, {
  incContraGUP: false,
  incContraMPID: true,
  incExecGUP: false,
  incExecMPID: true,
});

Object.assign(constructState[Forms.SETTINGS_LIMO.key].fields, {
  [FieldNames.useNetTrading]: "false",
  [FieldNames.perTradeBuyAction]: "W",
  [FieldNames.holdDefaultAction]: "A",
});

Object.assign(constructState[Forms.SETTINGS_TR.key].fields, {
  confirmTableHotButtons: true,
  confirmReportQuantity: true,
  confirmReportQuantityNumber: DEFAULT_CONFIRM_REPORT_QUANTITY_NUMBER,
});

//only some forms have allow show errors by default
[Forms.PTR_INTRADAY_LIMITS.key, Forms.SETTINGS_LIMO.key].forEach(
  f => (constructState[f].allowShowError = true)
);

export const defaultState = constructState;
// recieve a field
// - validate it
// - find its related fields and validate those
export const prepareFieldValidation = ({
  form,
  field,
  value,
  state,
  entitlements,
  fromUserEvent,
}) => {
  const execedfield = execOrReturn(field, { form, field, entitlements });
  const payload = gatherFieldData(
    { form, field, value, state, entitlements },
    field.props.name,
    value,
    fromUserEvent
  );

  runValidation(payload, state, form, field);

  const rawOtherFields = [];

  (field.props?.validateOther || []).forEach(f => {
    rawOtherFields.push(
      execOrReturn(final[f], {
        form,
        field,
        entitlements,
      })
    );
  });
  // find related fields
  Object.entries(execedfield?.props?.validation || {}).forEach(([key, rule]) => {
    Object.entries(rule.otherFields || {}).forEach(([key, otherField]) => {
      rawOtherFields.push(
        execOrReturn(final[otherField.field], {
          form,
          field,
          entitlements,
        })
      );
    });
  });
  const otherFields = _.uniq(rawOtherFields, _.isEqual);
  const origField = field;

  //validate them
  otherFields.forEach(field => {
    const fieldValue = state[form.key].fields[field.props.name];
    const payload = gatherFieldData(
      { form, field, value: fieldValue, state, entitlements },
      origField.props.name,
      value,
      fromUserEvent
    );

    runValidation(payload, state, form, field);
  });
};

// take a field and prepare a payload object for validation
export const gatherFieldData = (
  { form, field, value, state, entitlements },
  changedField,
  changedValue,
  fromUserEvent = true
) => {
  const execedfield = execOrReturn(field, { form, field, entitlements });
  const payload = {
    rules: {},
    flags: {},
    label: execedfield.props.label,
  };

  if (execedfield === null) {
    return null;
  }

  // TODO: Jeff - why do actions not get an id?
  payload.currentValue = fromUserEvent ? value : state[form.key].fields[field.props.name];
  payload.previousValue = state[form.key].fields[field.props.name];
  payload.state = state[form.key];
  payload.form = form;
  payload.fieldType = field.TypeName;

  // each validation rule definition
  Object.keys(execedfield?.props?.validation || {}).forEach(rule => {
    const ruleDef = execOrReturn(execedfield.props?.validation[rule], {
      form,
      field,
      entitlements,
    });

    // not applied to current form
    if (!ruleDef?.onForms?.[form.key]) {
      return;
    }

    const execedRule = execOrReturn(execedfield.props.validation[rule], {
      form,
      field,
      entitlements,
    });

    // grab other fields
    // TODO: this should be more general, and not send these specific keys every time
    payload.rules[rule] = { otherFields: {} };
    payload.rules[rule].fields = execedRule?.fields?.map(field => {
      return execOrReturn(final[field], { form, entitlements });
    });
    payload.rules[rule].orEqualToField = execedRule.orEqualToField;
    payload.rules[rule].orEqualToValue = execedRule.orEqualToValue;
    payload.rules[rule].max = execedRule.max;
    payload.rules[rule].valueList = execedRule.valueList;
    payload.rules[rule].instant = execedRule.instant;
    payload.rules[rule].requiredOptions = execedRule.requiredOptions;
    payload.rules[rule].mathPredicate = execedRule.mathPredicate;
    payload.rules[rule].messageFragment = execedRule.messageFragment;
    payload.rules[rule].valueToCheck = execedRule.valueToCheck;
    payload.rules[rule].valueCheck = execedRule.valueCheck;
    payload.rules[rule].zeroMeansDisabled = execedRule.zeroMeansDisabled;

    if (ruleDef.comparator) {
      payload.rules[rule].comparator = ruleDef.comparator;
    }

    Object.entries(ruleDef?.otherFields || {}).forEach(([i, otherField]) => {
      const otherFieldDef = execOrReturn(final[otherField.field], { form, entitlements });
      payload.rules[rule].otherFields[otherField.field] = {
        currentValue:
          changedField === otherField.field
            ? changedValue
            : state[form.key].fields[otherFieldDef.props.name],
        comparator: ruleDef.otherFields[i].value,
      };
    });

    // calculate flags
    Object.entries(ruleDef?.flags || {}).forEach(([flagName, fn]) => {
      payload.rules[rule][flagName] = ruleDef.flags[flagName](field.props.name, state[form.key]);
    });

    payload.rules[rule].ruleSpecific = execedfield?.ruleSpecific;
  });

  return payload;
};

// run validation and update state
export const runValidation = (payload, state, form, field, fromUserEvent) => {
  const [result, instant] = validationEngine().validate(payload, state, form, field);
  // figure out how many errors are on this form, based on validation results
  // and previous state value
  if (result && !state[form.key].errors[`${field.props.name}_errorStatus`]) {
    ++state[form.key].totalFormErrors;
  } else if (state[form.key].errors[`${field.props.name}_errorStatus`] && !result) {
    --state[form.key].totalFormErrors;
  }
  state[form.key].errors = {
    ...state[form.key].errors,
    [`${field.props.name}_errorStatus`]: !!result,
    [`${field.props.name}_errorText`]: result ? result : "",
    [`${field.props.name}_instant`]: instant,
  };
};

const setConfirmMessage = ({ field, form, name, value, state }) => {
  if (field && field.props && field.props.confirmWhenValues) {
    const { props } = field;
    const { confirmWhenValues } = props;
    let key = name;
    if (props.confirmKey) {
      key = props.confirmKey;
    }
    const formValues = { ...state[form.key].fields };
    formValues[name] = value;
    let shouldConfirm = true;
    const confirmArgs = { formState: state, currentForm: form };
    if (typeof confirmWhenValues === "function") {
      shouldConfirm = confirmWhenValues(confirmArgs);
    } else {
      Object.entries(confirmWhenValues).forEach(([fieldName, fieldValue]) => {
        if (formValues[fieldName] !== fieldValue) {
          shouldConfirm = false;
        }
      });
    }
    if (props.confirmMessage && shouldConfirm) {
      const message = execOrReturn(props.confirmMessage, confirmArgs);
      const confirmMessages = { ...state[form.key].confirmMessages };
      confirmMessages[key] = message;
      state[form.key] = {
        ...state[form.key],
        confirmMessages,
      };
    } else {
      const confirmMessages = { ...state[form.key].confirmMessages };
      delete confirmMessages[key];
      state[form.key] = {
        ...state[form.key],
        confirmMessages,
      };
    }
  }
};

// this is called to validate an entire form
export const initializeValidation = (state, form, entitlements, fromUserEvent) => {
  // This expects a state object and will mutate directly
  // Loop over all field sets, looking for fields that are required.
  // - for fields that are required, set state so those fields are 'errored'
  //   since they are blank
  const formFields = [FormFields[form.key].left, FormFields[form.key].right]
    .map(column =>
      column.map(fieldSet =>
        Object.entries(fieldSet.fields).map(([key, field]) =>
          execOrReturn(field, { form, field, entitlements })
        )
      )
    )
    .flat(3);

  formFields.forEach(field => {
    if (field === null) {
      return;
    }

    prepareFieldValidation({
      form,
      field,
      id: field?.props?.name,
      value: state[form.key].fields[field],
      state: state,
      entitlements,
      fromUserEvent: false,
    });

    setConfirmMessage({
      field,
      form,
      name: field.props.name,
      value: state[form.key].fields[field.props.name],
      state,
    });
  });

  const grayedFields = calculateGrayFields(state[form.key].fields, form, entitlements);
  state[form.key].grayedFields = grayedFields;

  return {
    ...state,
    [form.key]: {
      ...state[form.key],
      ...(["TRReport", "TRModify"].includes(form.key) && { globalErrorMessage: "" }),
      // TODO: miiiiight need this with an if
      // globalErrorMessage: "",
    },
  };
};
const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }

  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state }, { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_IS_LOADING": {
      const { form } = action.payload;
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          isLoading: true,
        },
      };
    }
    case "SET_NOT_IS_LOADING": {
      const { form } = action.payload;
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          isLoading: false,
        },
      };
    }
    case "ADD_CONFIRM_MESSAGE": {
      const { key, message, form } = action.payload;
      const confirmMessages = { ...state[form.key].confirmMessages };
      confirmMessages[key] = message;
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          confirmMessages,
        },
      };
    }
    case "REMOVE_CONFIRM_MESSAGE": {
      const { key, form } = action.payload;
      const confirmMessages = { ...state[form.key].confirmMessages };
      delete confirmMessages[key];
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          confirmMessages,
        },
      };
    }
    case "CLEAR_GLOBAL_ERROR": {
      const { form } = action.payload;
      return { ...state, [form.key]: { ...state[form.key], globalErrorMessage: "" } };
    }

    case "SET_PREMODIFIED_STATE": {
      const { form, data } = action.payload;
      return { ...state, [form]: { ...state[form], preModifiedState: data } };
    }
    case "DUMP_SETTINGS": {
      const { userData, stMpidOptions, activeRiskMpidOptions, user } = action.payload;

      const getAllActiveRiskMpidOptions = () => {
        return activeRiskMpidOptions.reduce((acc, curr) => {
          if (Array.isArray(curr.children)) {
            return acc.concat(curr.children);
          } else {
            return acc;
          }
        }, []);
      };

      const getValidRiskMpidOptions = savedOptions => {
        const allActiveOptions = getAllActiveRiskMpidOptions();

        const validOptions = savedOptions.reduce((acc, curr) => {
          if (curr?.label && curr.label.indexOf("Clearing") !== -1) {
            // - due to the way NEF-312 handles selected state, we do not want
            //   to include 'parent nodes'
            // acc.push(curr);
          } else {
            try {
              const relationship = JSON.parse(curr.value);
              if (relationship.correspondentMPID && relationship.clearingNum) {
                const option = allActiveOptions.find(opt => {
                  const value = JSON.parse(opt.value);
                  return (
                    value.correspondentMPID === relationship.correspondentMPID &&
                    value.clearingNum === relationship.clearingNum
                  );
                });
                if (option) {
                  acc.push(option);
                }
              }
            } catch {
              console.info(`${curr?.label} could not be parsed`);
            }
          }
          return acc;
        }, []);
        return validOptions;
      };

      const newState = { ...state };
      userData.forEach((d, i) => {
        let formRef;
        let savedFields = safeParseJSON(d.data);

        switch (d.type) {
          case "SettingsStats":
            formRef = Forms.SETTINGS_STATS;
            break;
          case "SettingsTradeReporting":
            formRef = Forms.SETTINGS_TR;
            break;
          case SettingNames.EQRC_MONITORING_FORM:
            formRef = Forms.EQRC_TOP;
            const mpid = savedFields[EqrcFields.mpid]?.value;
            const port =
              savedFields[EqrcFields.port] &&
              (savedFields[EqrcFields.port] || []).map(p => p.value);
            const mpidPorts = user.eqrcData?.mpids.find(m => m.mpid === mpid);

            // is selected MPID in the list of valid MPIDs?
            // are there any ports that are not within the currently selected MPID's ports
            if (
              !user.eqrcData?.mpids?.find(m => m?.mpid === mpid) ||
              port?.find(p => !mpidPorts.ports.includes(p))
            ) {
              // user no longer has access to some of their selected options,
              // do not load
              return;
            }
            break;
          case "rightLimitMPIDS":
            formRef = Forms.RIGHT_LIMIT;

            const loadedMPIDs = savedFields[FieldNames.mpid];
            if (
              !loadedMPIDs ||
              (Array.isArray(loadedMPIDs) && loadedMPIDs.length === 0) ||
              loadedMPIDs === SELECT_ALL
            ) {
              savedFields[FieldNames.mpid] = getAllActiveRiskMpidOptions();
            } else {
              savedFields[FieldNames.mpid] = getValidRiskMpidOptions(savedFields[FieldNames.mpid]);
            }
            break;
          case SettingNames.LIMO_MPID:
            if (!savedFields[FieldNames.mpid] || savedFields[FieldNames.mpid] === SELECT_ALL) {
              savedFields[FieldNames.mpid] = getAllActiveRiskMpidOptions();
            } else {
              savedFields[FieldNames.mpid] = getValidRiskMpidOptions(savedFields[FieldNames.mpid]);
            }
            formRef = Forms.CV_TOP;
            break;
          case "statsSelection":
            formRef = Forms.ST_MIDDLE;
            if (Array.isArray(savedFields[FieldNames.mpid])) {
              const stMpidValues = stMpidOptions.map(mpid => mpid.value);
              savedFields[FieldNames.mpid] = savedFields[FieldNames.mpid].reduce((acc, curr) => {
                if (stMpidValues.includes(curr?.value)) {
                  acc.push(curr);
                }
                return acc;
              }, []);
            }
            break;
          default:
            // we got a setting we don't know about
            return;
        }

        if (formRef && d.type !== "tableColumnSet") {
          newState[formRef.key].fields = {
            ...defaultValues[formRef.key],
            ...newState[formRef.key].fields,
            ...savedFields,
          };
        }
      });

      // if the user doesn't have the right limit mpid pref, we never get into the
      // switch above, so we check here
      if (!newState[Forms.RIGHT_LIMIT.key].fields[FieldNames.mpid]) {
        newState[Forms.RIGHT_LIMIT.key].fields = {
          ...newState[Forms.RIGHT_LIMIT.key].fields,
          [FieldNames.mpid]: getAllActiveRiskMpidOptions(),
        };
      }

      return newState;
    }
    case "DUMP_PRO_MONITOR_MPIDS": {
      const { mpids } = action.payload;
      return {
        ...state,
        [Forms.PV_MONITOR_MEMBER_TOP.key]: {
          ...state[Forms.PV_MONITOR_MEMBER_TOP.key],
          fields: {
            ...state[Forms.PV_MONITOR_MEMBER_TOP.key].fields,
            [PV_REJECT_TOP.mpid]: mpids,
          },
        },
      };
    }
    case "WRITE_FORM_VALUE": {
      const { form, fields } = action.payload;

      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          fields: {
            ...defaultValues[form.key],
            ...state[form.key].fields,
            ...fields,
          },
        },
      };
    }
    case "SET_FORM_VALUES": {
      let { form, fields } = action.payload;
      let formKey = form;
      if (typeof form === "object") {
        formKey = form.key;
      }

      const fieldsWithValues = { ...defaultValues[formKey] };
      Object.entries(fields).forEach(([key, value]) => {
        fieldsWithValues[key] = value;
      });

      const newState = {
        ...state,
        [formKey]: {
          ...state[formKey],
          globalErrorMessage: fieldsWithValues.rejectText,
          fields: fieldsWithValues,
          errors: {},
          valuesAsSet: fields,
          totalFormErrors: 0,
        },
      };
      return newState;
    }

    case "SET_FORM_VALUES_AND_PREMODIFIED": {
      let { form, fields } = action.payload;
      if (form.key) {
        const fieldsWithValues = { ...defaultValues[form.key] };
        Object.entries(fields).forEach(([key, value]) => {
          fieldsWithValues[key] = value;
        });
        window.a = _.cloneDeep(fieldsWithValues);
        const b = {
          ...state,
          [form.key]: {
            ...state[form.key],
            globalErrorMessage: fieldsWithValues.rejectText,
            fields: fieldsWithValues,
            errors: {},
            valuesAsSet: fields,
            // TODO: FFS immer already
            preModifiedState: window.a,
            totalFormErrors: 0,
          },
        };
        return b;
      }
      return state;
    }
    case "UPDATE_FORM_VALUE": {
      const { form, field, value, entitlements } = action.payload;

      let { id } = action.payload;
      if (!id) {
        id = field;
      }
      const newState = { ...state };
      newState[form.key] = {
        ...newState[form.key],
        fields: {
          ...state[form.key].fields,
          ...((value || value === false) && { [field]: value }),
          // make sure we are undefined if we had a value before
          ...(!value && value !== false && { [field]: undefined }),
        },
      };

      let fieldToUse = field;
      if (typeof field === "string") {
        fieldToUse = execOrReturn(final[id], {
          form,
          field,
          entitlements,
        });
      }

      prepareFieldValidation({
        form,
        field: fieldToUse,
        value,
        state: newState,
        entitlements,
        fromUserEvent: true,
      });

      if (GrayableFields.includes(field)) {
        const grayedFields = calculateGrayFields(newState[form.key].fields, form, entitlements);
        newState[form.key].grayedFields = grayedFields;
      }

      if (value === "" || value === null || (Array.isArray(value) && value.length === 0)) {
        delete newState[form.key].fields[field];
      }
      return newState;
    }
    case "SET_ERROR_ON_FIELD": {
      const { form, fieldName, message } = action.payload;
      const errors = { ...state[form.key].errors };
      const count = state[form.key].totalFormErrors + 1;
      errors[`${fieldName}_errorStatus`] = true;
      errors[`${fieldName}_errorText`] = message;
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          errors,
          totalFormErrors: count,
          allowShowError: true,
        },
      };
    }
    case "RESET_FORM": {
      let { form, entitlements } = action.payload;
      let newState = { ...state };
      newState[form.key] = defaultState[form.key];
      newState[form.key].isLoading = state[form.key].isLoading;
      const validationFields = [];
      const formFields = FormFields[form.key];
      formFields.left.forEach(fieldSet => {
        validationFields.concat(fieldSet.fields);
      });
      formFields.right.forEach(fieldSet => {
        validationFields.concat(fieldSet.fields);
      });
      newState = initializeValidation(newState, form, entitlements);
      return newState;
    }
    case "REVERT_FORM": {
      let { form, entitlements } = action.payload;
      let newState = { ...state };
      newState[form.key].fields = state[form.key].preModifiedState || {
        ...defaultState[form.key].fields,
      };
      newState = initializeValidation(newState, form, entitlements);
      return newState;
    }
    case "INIT_FORM_VALIDATION": {
      const { form, entitlements } = action.payload;
      const newState = initializeValidation({ ...state }, form, entitlements, false);

      return newState;
    }
    case "RESET_FORM_VALIDATION": {
      const { form } = action.payload;
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          errors: {},
          totalFormErrors: 0,
          globalErrorMessage: "",
          allowShowError: false,
        },
      };
    }
    case "ALLOW_SHOW_ERROR": {
      const { form, allowShowError } = action.payload;
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          allowShowError,
        },
      };
    }
    case "SET_GLOBAL_ERROR": {
      const { form, message } = action.payload;

      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          globalErrorMessage: message,
        },
      };
    }
    case "SET_CONFIRM_MESSAGE": {
      const newState = { ...state };
      setConfirmMessage({ state: newState, ...action.payload });
      return newState;
    }
    case "UPDATE_CLEARING_PRICE": {
      const { form } = action.payload;
      const price = state[form.key].fields[FieldNames.price];
      const contract = state[form.key].fields[FieldNames.contract];
      const fee = state[form.key].fields[FieldNames.fee];
      const priceTradeDigit = state[form.key].fields[FieldNames.priceTradeDigit];

      let clearingPrice;

      switch (priceTradeDigit) {
        case PriceTradeDigit.PER_SHARE:
          clearingPrice = new Decimal(parseFloat(price) || 0).add(parseFloat(fee) || 0).toString();
          break;
        case PriceTradeDigit.CONTRACT:
          clearingPrice = new Decimal(parseFloat(contract) || 0)
            .add(parseFloat(fee) || 0)
            .toString();
          break;
        default:
          break;
      }

      return DispatchFnSwitch(state, {
        type: "UPDATE_FORM_VALUE",
        payload: {
          id: FieldNames.clearingPrice,
          form,
          field: FieldNames.clearingPrice,
          value: clearingPrice,
        },
      });
    }
    case "EMPTY_GIVE_UPS": {
      const { form } = action.payload;

      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          fields: {
            ...state[form.key].fields,
            [FieldNames.giveUpMPID]: null,
            [FieldNames.counterGiveUpMPID]: null,
          },
        },
      };
    }
    case "EMPTY_CORRESPONDENT_MPID": {
      const { form } = action.payload;

      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          fields: {
            ...state[form.key].fields,
            [FieldNames.correspondentMPID]: undefined,
          },
        },
      };
    }
    case "SET_SELECT_ALL_MPID": {
      return {
        ...state,
        selectAllMpidCount: action.payload,
      };
    }
    case "SET_TRADE_REPORT_CLEARING_ONLY_FIELDS": {
      let newState = { ...state };
      [
        Forms.TR_REPORT,
        Forms.TR_REPAIR,
        Forms.TR_MODIFY,
        Forms.TR_COPY,
        Forms.ST_MODIFY,
        Forms.ST_REPAIR,
        Forms.ST_COPY,
      ].forEach(f => {
        defaultState[f.key].fields[FieldNames.clearReportRiskVals] =
          clearReportRiskOptions[OptionConstants[FieldNames.clearReportRiskVals].clear];
        newState = DispatchFnSwitch(newState, {
          type: "UPDATE_FORM_VALUE",
          payload: {
            id: FieldNames.clearReportRiskVals,
            form: f,
            field: FieldNames.clearReportRiskVals,
            value: clearReportRiskOptions[OptionConstants[FieldNames.clearReportRiskVals].clear],
          },
        });
      });
      return newState;
    }
    case "SET_USER_PREF": {
      const { theme, notificationPlacement } = action.payload;
      return {
        ...state,
        [Forms.SETTINGS_USER_PREFERENCE.key]: {
          ...state[Forms.SETTINGS_USER_PREFERENCE.key],
          fields: {
            [FieldNames.workXTheme]: theme
              ? workXThemeOptions[theme]
              : workXThemeOptions[WORKX_THEME_VALUE.LIGHT],
            [FieldNames.workXNotificationPlacement]: notificationPlacement
              ? workXNotificationPlacementOptions[notificationPlacement]
              : workXNotificationPlacementOptions[WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_RIGHT],
          },
        },
      };
    }
    default:
      return { ...state };
  }
};

const FormProvider = ({ children, defaultData, theme, notificationPlacement }) => {
  const [user] = useUserContext();
  const [mpids] = useMPIDOptionsContext();
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  useEffect(() => {
    dispatchF({ type: "SET_USER_PREF", payload: { theme, notificationPlacement } });
  }, [theme, notificationPlacement]);

  useEffect(() => {
    const actions = [];
    if (typeof user[INITIAL_DATA_MODEL.config]?.selectAllMpidCount === "number") {
      actions.push({
        type: "SET_SELECT_ALL_MPID",
        payload: user[INITIAL_DATA_MODEL.config].selectAllMpidCount,
      });
    }

    if (user?.entitlements?.[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
      actions.push({
        type: "SET_TRADE_REPORT_CLEARING_ONLY_FIELDS",
      });
    }
    dispatchF(actions);
  }, [user]);

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      Array.isArray(user[INITIAL_DATA_MODEL.userData]) &&
      mpids.userMPIDOptions.isReady &&
      mpids.clearCorrMPIDOptions.isReady
    ) {
      dispatchF({
        type: "DUMP_SETTINGS",
        payload: {
          userData: user[INITIAL_DATA_MODEL.userData],
          stMpidOptions: mpids.userMPIDOptions.stMpidOptions,
          activeRiskMpidOptions: mpids.clearCorrMPIDOptions.activeRiskMpidOptions,

          user,
        },
      });
    }
  }, [user, mpids]);

  useEffect(() => {
    if (!user.isInitialDataLoading) {
      if (mpids.userMPIDOptions.isReady) {
        dispatchF({
          type: "DUMP_PRO_MONITOR_MPIDS",
          payload: {
            mpids: mpids.userMPIDOptions.trMpidOptions,
          },
        });
      }
    }
  }, [user, mpids]);

  return (
    <formDispatch.Provider value={dispatchF}>
      <formContext.Provider value={[state, dispatchF]}>{children}</formContext.Provider>
    </formDispatch.Provider>
  );
};

export default FormProvider;

const addToGrayFields = (currentFields, newFields) => {
  if (!Array.isArray(newFields)) {
    newFields = [newFields];
  }
  newFields.forEach(field => {
    if (!currentFields.includes(field)) currentFields.push(field);
  });
  return currentFields;
};

// TODO: memo the shit out of this
export const calculateGrayFields = (formValues, form, entitlements) => {
  if (form === Forms.TR_SCAN) {
    return;
  }
  // figure out the value of the fields we care about for gray out logic
  // - pluck all fields but the one that change value from state
  // - use passed value for change field
  let grayedFields = [];
  const values = {};
  GrayableFields.forEach(field => {
    values[field] =
      formValues[field] && formValues[field].value ? formValues[field].value : formValues[field];
  });

  if (values[FieldNames.scanGiveupWithMyMpid] === true) {
    addToGrayFields(grayedFields, [FieldNames.giveUpMPID, FieldNames.counterGiveUpMPID]);
  }
  if (
    (typeof values[FieldNames.giveUpMPID] === "string" &&
      values[FieldNames.giveUpMPID].length > 0) ||
    (typeof values[FieldNames.counterGiveUpMPID] === "string" &&
      values[FieldNames.counterGiveUpMPID].length > 0)
  ) {
    addToGrayFields(grayedFields, FieldNames.scanGiveupWithMyMpid);
  }
  const SelectStrings = {
    positionTransfer: "positionTransfer",
    clearingCopy: "clearingCopy",
    clearingNonReg: "clearingNonReg",
    nasdaq: "nasdaq",
    trf: "trf",
    nasdaqSalesFeeTransfer: "nasdaqSalesFeeTransfer",
    stepIn: "stepIn",
    stepOutFee: "stepOutFee",
  };

  if (values[FieldNames.special]) {
    const specialGraysOut = [
      SelectStrings["nasdaq"],
      "nasdaqSalesFeeTransfer",
      "nasdaqStepOut",
      "positionTransfer",
    ];
    grayedFields = addToGrayFields(grayedFields, specialGraysOut);
  }

  switch (values[FieldNames.stepInOut]) {
    case SelectOptions[FieldNames.stepInOut].find(o => o.id === "stepIn").value:
      const stepInGraysOut = [
        FieldNames.specialInstructions,
        SelectStrings["nasdaq"],
        SelectStrings["clearingCopy"],
        SelectStrings["positionTransfer"],
        SelectStrings["clearingNonReg"],
        SelectStrings["nasdaqSalesFeeTransfer"],
      ];
      grayedFields = addToGrayFields(grayedFields, stepInGraysOut);
      break;
    case SelectOptions[FieldNames.stepInOut].find(o => o.id === "stepOut").value:
      const stepOutGraysOut = [FieldNames.specialInstructions];
      grayedFields = addToGrayFields(grayedFields, [
        ...stepOutGraysOut,
        SelectStrings["clearingCopy"],
        SelectStrings["positionTransfer"],
        SelectStrings["clearingNonReg"],
        SelectStrings["nasdaqSalesFeeTransfer"],
      ]);
      break;
    case SelectOptions[FieldNames.stepInOut].find(o => o.id === "stepOutFee").value:
      const stepOutFeeGraysOut = [FieldNames.specialInstructions];
      grayedFields = addToGrayFields(grayedFields, [
        ...stepOutFeeGraysOut,
        SelectStrings["nasdaq"],
        SelectStrings["clearingCopy"],
        SelectStrings["positionTransfer"],
        SelectStrings["clearingNonReg"],
        SelectStrings["nasdaqSalesFeeTransfer"],
      ]);
      break;
    default:
      break;
  }

  switch (values[FieldNames.specialInstructions]) {
    case SelectOptions[FieldNames.specialInstructions].find(
      o => o.id === SelectStrings["positionTransfer"]
    ).value: {
      const grayOut = [FieldNames.stepInOut, FieldNames.special, SelectStrings["nasdaq"]];
      grayedFields = addToGrayFields(grayedFields, grayOut);
      break;
    }
    case SelectOptions[FieldNames.specialInstructions].find(
      o => o.id === SelectStrings["clearingCopy"]
    ).value: {
      const grayOut = [SelectStrings["nasdaq"], FieldNames.stepInOut];
      grayedFields = addToGrayFields(grayedFields, grayOut);
      break;
    }
    case SelectOptions[FieldNames.specialInstructions].find(
      o => o.id === SelectStrings["clearingNonReg"]
    ).value: {
      const grayOut = [FieldNames.stepInOut, SelectStrings["nasdaq"]];
      grayedFields = addToGrayFields(grayedFields, grayOut);
      break;
    }
    case SelectOptions[FieldNames.specialInstructions].find(
      o => o.id === SelectStrings["nasdaqSalesFeeTransfer"]
    ).value:
      const grayOut = [FieldNames.stepInOut, FieldNames.special, SelectStrings["trf"]];
      grayedFields = addToGrayFields(grayedFields, grayOut);
      break;
    default:
      break;
  }

  switch (values[FieldNames.intendedMarketFlag]) {
    case SelectOptions[FieldNames.intendedMarketFlag].find(o => o.id === SelectStrings["trf"])
      .value: {
      const grayOut = [SelectStrings["nasdaqSalesFeeTransfer"]];
      grayedFields = addToGrayFields(grayedFields, grayOut);
      break;
    }
    case SelectOptions[FieldNames.intendedMarketFlag].find(o => o.id === SelectStrings["nasdaq"])
      .value: {
      const grayOut = [
        SelectStrings["stepIn"],
        SelectStrings["stepOutFee"],
        FieldNames.special,
        SelectStrings["clearingCopy"],
        SelectStrings["positionTransfer"],
        SelectStrings["clearingNonReg"],
      ];
      grayedFields = addToGrayFields(grayedFields, grayOut);
      break;
    }
    default:
      break;
  }

  return grayedFields;
};
