import { useEffect, useMemo, useReducer, useState } from "react";
import { DispatchFn, DispatchFnSwitch, ContextProviderProps } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { NotificationHub } from "@nef/core";
import { BulkJob } from "./constant";

type BulkJobState = {
  processing: { [id: string]: BulkJob };
  requesting: boolean;
  polling: boolean;
  makeRequest: boolean;
};

type BulkJobAction = { type: any; payload?: any };

const FACTORY = new ContextFactory<BulkJobState, BulkJobAction>();

const DEFAULT_STATE: BulkJobState = {
  processing: {},
  requesting: false,
  polling: false,
  makeRequest: false,
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<BulkJobState, BulkJobAction> = (
  prevState: BulkJobState,
  action: BulkJobAction
) => {
  switch (action.type) {
    case "ADD_JOB": {
      const newProcessing = { ...prevState.processing, [action.payload.id]: action.payload };
      return { ...prevState, processing: newProcessing };
    }
    case "UPDATE_JOBS": {
      const newProcessing = action.payload.reduce((acc: any, curr: any) => {
        if (
          curr.countAccepted + curr.countRejected + curr.countFailed + curr.countUnknown ===
          curr.numItems
        ) {
          const subtitle = [];
          if (curr.countAccepted > 0) {
            subtitle.push(<span>{curr.countAccepted} Accepted</span>);
            subtitle.push(<br></br>);
          }
          if (curr.countRejected > 0) {
            subtitle.push(<span>{curr.countRejected} Rejected</span>);
            subtitle.push(<br></br>);
          }
          if (curr.countFailed > 0) {
            subtitle.push(<span>{curr.countFailed} Failed</span>);
            subtitle.push(<br></br>);
          }
          if (curr.countUnknown > 0) {
            subtitle.push(<span>{curr.countUnknown} Unknown</span>);
            subtitle.push(<br></br>);
          }
          NotificationHub.send("success", "Bulk Job Complete", {
            subtitle: <>{subtitle}</>,
          });
        } else {
          acc[curr.id] = curr;
        }
        return acc;
      }, {} as any);
      return { ...prevState, processing: newProcessing };
    }
    case "SET_POLLING": {
      return { ...prevState, polling: action.payload };
    }
    case "SET_MAKE_REQUEST": {
      return { ...prevState, makeRequest: action.payload };
    }
    default: {
      return prevState;
    }
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<BulkJobState, BulkJobAction>(DISPATCH_FN_SWITCH);

interface BulkJobProviderProps extends ContextProviderProps {}

export const BulkJobProvider: React.FC<BulkJobProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<BulkJobState, BulkJobAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );
  const [timeout, setRequestTimeout] = useState<NodeJS.Timeout>();

  const bulkJobIds = useMemo(() => {
    return Object.keys(state.processing);
  }, [state.processing]);

  useEffect(() => {
    if (bulkJobIds.length > 0 && !state.polling) {
      clearTimeout(timeout);
      dispatch([
        { type: "SET_POLLING", payload: true },
        { type: "SET_MAKE_REQUEST", payload: true },
      ]);
    }
    if (bulkJobIds.length === 0 && state.polling) {
      clearTimeout(timeout);
      dispatch([
        { type: "SET_POLLING", payload: false },
        { type: "SET_MAKE_REQUEST", payload: false },
      ]);
    }
  }, [bulkJobIds, state.polling, timeout]);

  useEffect(() => {
    if (state.polling && state.makeRequest) {
      dispatch({ type: "SET_MAKE_REQUEST", payload: false });
      clearTimeout(timeout);
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_ACT_WS, "bulk/poll"),
        {
          method: "post",
          headers: getHeaders(),
          body: JSON.stringify(bulkJobIds),
        },
        (bulkJobs: any) => {
          dispatch({ type: "UPDATE_JOBS", payload: bulkJobs });
          setRequestTimeout(
            setTimeout(() => {
              dispatch({ type: "SET_MAKE_REQUEST", payload: true });
            }, 3000)
          );
        },
        () => {
          NotificationHub.send("danger", "Error polling for Bulk Job Status");
          setRequestTimeout(
            setTimeout(() => {
              dispatch({ type: "SET_MAKE_REQUEST", payload: true });
            }, 60000)
          );
        }
      );
    }
  }, [state.requesting, state.polling, bulkJobIds, state.makeRequest, timeout]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const useBulkJobDispatch =
  FACTORY.createContextHook<React.Dispatch<BulkJobAction | BulkJobAction[]>>(DISPATCH_CONTEXT);
export const useBulkJobState = FACTORY.createContextHook<BulkJobState>(STATE_CONTEXT);
