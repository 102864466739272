import { StandardTables } from "wksConstants";
import { EqrcFields } from "../constants";

type keys = keyof typeof EqrcFields;
type values = (typeof EqrcFields)[keys];

type tableKeys = keyof typeof StandardTables;
type tableVals = (typeof StandardTables)[tableKeys];

const ExchangeMpidPort = [
  EqrcFields.exchange,
  EqrcFields.port,
  EqrcFields.mpid,
  EqrcFields.status,
  EqrcFields.groupId,
];

const allowedKeys: Partial<{ [k in tableVals]: values[] }> = {
  [StandardTables.EQRC_FAT_FINGER]: [
    ...ExchangeMpidPort,
    EqrcFields.rangeOnePct,
    EqrcFields.rangeTwoPct,
    EqrcFields.rangeThreePct,
    EqrcFields.rangeFourPct,
    EqrcFields.rangeFivePct,
    EqrcFields.rangeSixPct,
    EqrcFields.rangeOneAmt,
    EqrcFields.rangeTwoAmt,
    EqrcFields.rangeThreeAmt,
    EqrcFields.rangeFourAmt,
    EqrcFields.rangeFiveAmt,
    EqrcFields.rangeSixAmt,
    EqrcFields.includeIsoOrders,
    EqrcFields.includeAuctionOrders,
  ],
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: [...ExchangeMpidPort, EqrcFields.symbols],
  [StandardTables.EQRC_GROSS_EXPOSURE]: [
    ...ExchangeMpidPort,
    EqrcFields.groupId,
    EqrcFields.openExposure,
    EqrcFields.executedExposure,
    EqrcFields.notionalExposure,
    EqrcFields.breachAction,
  ],
  [StandardTables.EQRC_MARKET_IMPACT_CHECK]: [...ExchangeMpidPort, EqrcFields.marketImpact],
  [StandardTables.EQRC_ORDER_TYPE]: [
    ...ExchangeMpidPort,
    EqrcFields.isoOrders,
    EqrcFields.shortSaleOrders,
    EqrcFields.nonMarketOrders,
    EqrcFields.premarketTrading,
    EqrcFields.postMarketTrading,
    EqrcFields.shortSaleExempt,
    EqrcFields.buyMarketIPO,
  ],
  [StandardTables.EQRC_ADV_CHECK]: [...ExchangeMpidPort, EqrcFields.pctAdv, EqrcFields.minVolume],
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: [
    ...ExchangeMpidPort,
    EqrcFields.duplicateMaxMessages,
    EqrcFields.duplicateTimeWindowSize,
    EqrcFields.portMaxMessages,
    EqrcFields.portTimeWindowSize,
    EqrcFields.symbolMaxMessages,
    EqrcFields.symbolTimeWindowSize,
  ],
  [StandardTables.EQRC_SHORT_SALE]: [...ExchangeMpidPort, EqrcFields.symbols],
  [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: [...ExchangeMpidPort, EqrcFields.perOrderSharesLimit],
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: [...ExchangeMpidPort, EqrcFields.perOrderNotionalLimit],
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: [
    ...ExchangeMpidPort,
    EqrcFields.sharesLocatedOption,
    EqrcFields.sharesLocateRule,
    EqrcFields.sharesLocatedSSE,
    EqrcFields.brokers,
    EqrcFields.brokersListRule,
    EqrcFields.enableBrokerList,
  ],
  [StandardTables.EQRC_SHARES_LOCATED_CHECK]: [
    ...ExchangeMpidPort,
    EqrcFields.sharesLocatedOption,
    EqrcFields.sharesLocateRule,
    EqrcFields.sharesLocatedSSE,
    EqrcFields.brokersListRule,
  ],
  [StandardTables.EQRC_BROKER_LIST]: [
    ...ExchangeMpidPort,
    EqrcFields.brokers,
    EqrcFields.brokersListRule,
    EqrcFields.enableBrokerList,
  ],
};

export const keyPruner = (form: tableVals, object: { [key: string]: any }) => {
  const allowed = allowedKeys[form] || [];
  if (allowed.length === 0) {
    console.warn(`No allowed keys found for form ${form}`);
  }

  const a = Object.keys(object).reduce((acc, key) => {
    if (allowed.includes(key)) {
      acc[key] = object[key];
    }

    return acc;
  }, {} as { [k in values]: any[] });

  return a;
};
