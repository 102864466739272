import React, { useCallback, useEffect, useMemo } from "react";
import { StandardHeader, StandardTable, useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { BannerTableButtons, useBannerContext } from ".";

export const BannerManagerTable: React.FC = () => {
  const [bannerData, bannerDispatch] = useBannerContext();
  const tableDispatch = useStandardTableDispatch();

  useEffect(() => {
    bannerDispatch({
      type: "START_REFRESH",
    });
  }, [bannerDispatch]);

  useEffect(() => {
    tableDispatch({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.SETTINGS_BANNER_MANAGER, isLoading: bannerData.isLoading },
    });
  }, [bannerData.isLoading, tableDispatch]);

  useEffect(() => {
    if (bannerData.banners) {
      tableDispatch({
        type: "SET_TABLE_DATA",
        payload: { table: StandardTables.SETTINGS_BANNER_MANAGER, data: bannerData.banners },
      });
    }
  }, [bannerData.banners, tableDispatch]);

  const tableButtons = useMemo(() => {
    return <BannerTableButtons />;
  }, []);

  const Header = useCallback(() => <StandardHeader title="Banner Manager" />, []);
  return (
    <StandardTable
      header={Header}
      table={StandardTables.SETTINGS_BANNER_MANAGER}
      enableLayoutExport={true}
      isSingleSelect={false}
      isColumnsVirtualized={false}
      isFilterable={true}
      headerMenu={tableButtons}
      subHeader={undefined}
      hideRowCount={undefined}
      hideQueryDate={undefined}
      hideSelectedCount={undefined}
      exportCallback={undefined}
      exportFileName={undefined}
      additionalRowClick={undefined}
      isEditableTableEnabled={undefined}
      style={undefined}
      isRowSelectEnabled={undefined}
      Header={undefined}
    />
  );
};
