import { Forms, PVMemberMonitorTop } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import { useStandardTableDispatch } from "components/standardTable";
import React, { useEffect } from "react";
import styled from "styled-components";
import { SelectOption } from "types";
import { StandardTables } from "wksConstants";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch } from "../cache/porCache";
import { useRejectCacheDispatch } from "../cache/rejectCache";
import { REJECT_COUNT_FILTER_BY, useRejectCountDispatch } from "../cache/rejectCountCache";
import { DATE_FILTER } from "../constant";

const FieldWrapper = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

type PorClientMonitorInputsProps = {
  selectedMpids: SelectOption[];
  selectedDateFilter: SelectOption;
};
export const PorClientMonitorInputs: React.FC<PorClientMonitorInputsProps> = ({
  selectedMpids,
  selectedDateFilter,
}) => {
  const tableDispatch = useStandardTableDispatch();
  const porCacheDispatch = usePorCacheDispatch();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const rejectCountDispatch = useRejectCountDispatch();

  useEffect(() => {
    if (Array.isArray(selectedMpids) && selectedMpids.length > 0) {
      porCacheDispatch({
        type: "START_POLLING",
        payload: {
          filterBy: POR_CACHE_FILTER_BY.MPID,
          keys: selectedMpids.map(mpid => mpid.label),
        },
      });
    } else {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
    }
    return () => {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
    };
  }, [selectedMpids, tableDispatch, porCacheDispatch]);

  useEffect(() => {
    if (Array.isArray(selectedMpids) && selectedMpids.length > 0 && selectedDateFilter?.value) {
      rejectCountDispatch({
        type: "START_POLLING",
        payload: {
          filter: {
            filterBy: REJECT_COUNT_FILTER_BY.CLIENT_COUNTS,
            criteria: selectedMpids.map(mpid => mpid.value) as string[],
            date: selectedDateFilter.value as DATE_FILTER,
          },
        },
      });
    } else {
      rejectCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
    }
    return () => {
      rejectCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_REJECTS },
      });
    };
  }, [
    selectedMpids,
    tableDispatch,
    porCacheDispatch,
    rejectCacheDispatch,
    rejectCountDispatch,
    selectedDateFilter,
  ]);

  return (
    <FieldWrapper>
      <FieldLoop
        isReactFragment={true}
        form={Forms.PV_MONITOR_MEMBER_TOP}
        fields={PVMemberMonitorTop}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
    </FieldWrapper>
  );
};
