export const PVR_EMAIL_FIELD_PREFIX = "PVR_EMAIL_";

export enum PVR_EMAIL_ENTITY {
  id = "id",
  creator = "creator",
  recipientName = "recipientName",
  recipientEmail = "recipientEmail",
  status = "status",
  userId = "userId",
  version = "version",
  modifiedBy = "modifiedBy",
  lastUpdate = "lastUpdate",
}

export const PVR_EMAIL_STATUS = {
  Enabled: "E",
  Disabled: "D",
};

export type PvrEmail = {
  [PVR_EMAIL_ENTITY.id]: string;
  [PVR_EMAIL_ENTITY.creator]: string;
  [PVR_EMAIL_ENTITY.recipientName]: string;
  [PVR_EMAIL_ENTITY.recipientEmail]: string;
  [PVR_EMAIL_ENTITY.status]: string;
  [PVR_EMAIL_ENTITY.userId]: string;
  [PVR_EMAIL_ENTITY.version]: number;
  [PVR_EMAIL_ENTITY.modifiedBy]: string;
  [PVR_EMAIL_ENTITY.lastUpdate]: string;
};
