import { StandardTable } from "components/standardTable/standardTable";
import React, { useCallback, useEffect } from "react";
import { StandardTables } from "wksConstants";
import styled from "styled-components";
import { StandardHeader, useStandardTableDispatch } from "components/standardTable";
import { PORModel, ServiceIndicatorWrapper } from "../constant";
import { LimoLVCIndicator } from "components/limitMonitor";
import { usePorCacheDispatch, usePorCacheState } from "../cache/porCache";

const RequestTableWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 2 / 4;
`;

export const PvClientPorTable: React.FC = () => {
  const porCacheState = usePorCacheState();
  const porCacheDispatch = usePorCacheDispatch();
  const tableDispatch = useStandardTableDispatch();

  useEffect(() => {
    tableDispatch([
      {
        type: "SET_TABLE_DATA",
        payload: {
          table: StandardTables.PV_REQUESTS,
          data: Object.values(porCacheState.data)
            .filter(por => !!por[PORModel.SUBSCRIBED])
            .sort(
              (a, b) =>
                new Date(b[PORModel.LAST_UDPATE]).getTime() -
                new Date(a[PORModel.LAST_UDPATE]).getTime()
            ),
        },
      },
      {
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.PV_REQUESTS, isLoading: porCacheState.isLoading },
      },
    ]);
  }, [porCacheState.data, porCacheState.isLoading, tableDispatch]);

  useEffect(
    () => () =>
      porCacheDispatch({
        type: "STOP_POLLING",
      }),
    [porCacheDispatch]
  );

  const PORHeader = useCallback(
    () => (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            Parameter Requests
            <LimoLVCIndicator
              prefix={undefined}
              id={"por-indicator"}
              status={porCacheState.status}
            />
          </ServiceIndicatorWrapper>
        }
      />
    ),
    [porCacheState.status]
  );

  return (
    <RequestTableWrapper>
      <StandardTable
        Header={undefined}
        table={StandardTables.PV_REQUESTS}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={undefined}
        headerMenu={undefined}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={false}
        header={PORHeader}
        isFilterable={false}
      />
    </RequestTableWrapper>
  );
};
