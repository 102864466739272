import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@nef/core";
import { BANNER_THEME_OPTION, Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import { formValidationCheck, useStandardTableDispatch } from "components/standardTable";
import { useUserContext } from "components/user";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { BannerManagerForm, handleSaveBanner, useBannerDispatch } from ".";
import { Banner, BANNER_FORM } from "./constant";
import dayjs from "dayjs";

const StyledModalBody = styled(ModalBody)`
  overflow: hidden;
`;

type BannerModalProps = {
  isVisible: boolean;
  onClose: () => void;
  selectedBanner: Banner | null;
};

export const BannerModal: React.FC<BannerModalProps> = ({ isVisible, onClose, selectedBanner }) => {
  const tableDispatch = useStandardTableDispatch();
  const [formData, formDispatch] = useFormContext();
  const [userData] = useUserContext();
  const bannerDispatch = useBannerDispatch();

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form: Forms.SETTINGS_BANNER },
    });
  }, [formDispatch]);

  useEffect(() => {
    if (selectedBanner !== null) {
      const form = Forms.SETTINGS_BANNER;
      formDispatch({
        type: "SET_FORM_VALUES",
        payload: {
          form,
          fields: {
            ...selectedBanner,
            [BANNER_FORM.DATE_RANGE]: [
              dayjs(selectedBanner[BANNER_FORM.START_DATE]),
              dayjs(selectedBanner[BANNER_FORM.END_DATE]),
            ],
            [BANNER_FORM.THEME]: BANNER_THEME_OPTION[selectedBanner[BANNER_FORM.THEME]],
          },
        },
      });
    }
  }, [formDispatch, selectedBanner, userData.entitlements]);

  const handleCloseModal = useCallback(() => {
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose]);

  const handleDone = useCallback(() => {
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.SETTINGS_BANNER_MANAGER },
    });
    handleCloseModal();
  }, [handleCloseModal, tableDispatch]);

  const handleSaveNew = useCallback(() => {
    const banner: Banner = {
      [BANNER_FORM.IS_ENABLED]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.IS_ENABLED],
      [BANNER_FORM.START_DATE]:
        formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.DATE_RANGE][0],
      [BANNER_FORM.END_DATE]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.DATE_RANGE][1],
      [BANNER_FORM.MESSAGE]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.MESSAGE],
      [BANNER_FORM.THEME]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.THEME]?.value,
      [BANNER_FORM.LINK_LABEL]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.LINK_LABEL],
      [BANNER_FORM.LINK_URL]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.LINK_URL],
    };
    handleSaveBanner({
      bannerDispatch,
      onDone: handleDone,
      banner,
    });
  }, [bannerDispatch, formData, handleDone]);

  const handleSaveEdit = useCallback(() => {
    const banner: Banner = {
      [BANNER_FORM.ID]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.ID],
      [BANNER_FORM.IS_ENABLED]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.IS_ENABLED],
      [BANNER_FORM.START_DATE]:
        formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.DATE_RANGE][0],
      [BANNER_FORM.END_DATE]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.DATE_RANGE][1],
      [BANNER_FORM.MESSAGE]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.MESSAGE],
      [BANNER_FORM.THEME]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.THEME]?.value,
      [BANNER_FORM.LINK_LABEL]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.LINK_LABEL],
      [BANNER_FORM.LINK_URL]: formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.LINK_URL],
    };
    handleSaveBanner({
      bannerDispatch,
      onDone: handleDone,
      banner,
    });
  }, [bannerDispatch, formData, handleDone]);

  const handleSave = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: Forms.SETTINGS_BANNER,
      userData,
    });
    if (!valid) {
      return;
    }
    if (selectedBanner !== null) {
      handleSaveEdit();
    } else {
      handleSaveNew();
    }
  }, [formData, formDispatch, userData, selectedBanner, handleSaveEdit, handleSaveNew]);

  const Header = useMemo(() => {
    if (typeof formData[Forms.SETTINGS_BANNER.key].fields[BANNER_FORM.ID] === "string") {
      return "Edit Banner";
    } else {
      return "Create Banner";
    }
  }, [formData]);

  return (
    <Modal isOpen={isVisible} closeOnOutsideClick={true} toggle={handleCloseModal} size={"md"}>
      <ModalHeader toggle={handleCloseModal} title={Header} />
      <StyledModalBody>
        <BannerManagerForm />
      </StyledModalBody>
      <ModalFooter>
        <Button outline={true} onClick={handleCloseModal}>
          Close
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};
