import React, { createContext, useContext, useReducer } from "react";
import { SelectOption } from "types";
import { FORM_KEY, Forms } from ".";

export type SelectOptionAction =
  | {
      type: "ADD_TO_DISABLED_MAP";
      payload: { fieldName: string; form: FORM_KEY };
    }
  | {
      type: "ADD_TO_LOADING_MAP";
      payload: { fieldName: string; form: FORM_KEY };
    }
  | {
      type: "REMOVE_FROM_DISABLED_MAP";
      payload: { fieldName: string; form: FORM_KEY };
    }
  | {
      type: "REMOVE_FROM_LOADING_MAP";
      payload: { fieldName: string; form: FORM_KEY };
    }
  | {
      type: "ADD_TO_OPTIONS_CACHE";
      payload: { form: FORM_KEY; key: string; options: SelectOption[] };
    }
  | {
      type: "RESET_OPTIONS_CACHE";
      payload: { form: FORM_KEY };
    }
  | {
      type: "SET_OPTIONS";
      payload: { options: SelectOption[]; form: FORM_KEY; fieldName: string };
    };

export interface SelectOptionState {
  loadingMap: { [fieldName: string]: Set<string> };
  disabledMap: { [fieldName: string]: Set<string> };
  optionsCache: { [form in FORM_KEY]?: { [key: string]: SelectOption[] } };
  options: { [form in FORM_KEY]: { [fieldName: string]: SelectOption[] } };
}

const defaultState: SelectOptionState = {
  loadingMap: {},
  disabledMap: {},
  optionsCache: {},
  options: Object.values(Forms).reduce((acc, { id }) => {
    acc[id as FORM_KEY] = {};
    return acc;
  }, {} as { [form in FORM_KEY]: { [fieldName: string]: SelectOption[] } }),
};
const SelectOptionDispatch = createContext<
  React.Dispatch<SelectOptionAction | SelectOptionAction[]>
>(() => null);
SelectOptionDispatch.displayName = "SelectOptionDispatch";
export const useSelectOptionDispatch = () => useContext(SelectOptionDispatch);

const SelectOptionContext = createContext<
  [SelectOptionState, React.Dispatch<SelectOptionAction | SelectOptionAction[]>]
>([defaultState, () => null]);
SelectOptionContext.displayName = "SelectOptionContext";
export const useSelectOptionContext = () => useContext(SelectOptionContext);

const DispatchFn = (
  state: SelectOptionState,
  actions: SelectOptionAction | SelectOptionAction[]
) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (
  state: SelectOptionState,
  action: SelectOptionAction
): SelectOptionState => {
  switch (action.type) {
    case "SET_OPTIONS": {
      const { options, form, fieldName } = action.payload;
      const newState = { ...state, options: { ...state.options } };

      if (!newState.options[form]) {
        newState.options[form] = {};
      }

      newState.options[form] = {
        ...state.options[form],
        [fieldName]: options,
      };

      return newState;
    }
    case "ADD_TO_DISABLED_MAP": {
      const { fieldName, form } = action.payload;
      let disabledSet = state.disabledMap[fieldName];
      if (!disabledSet) {
        disabledSet = new Set();
      }
      disabledSet.add(form);
      const newState = { ...state };
      newState.disabledMap = {
        ...state.disabledMap,
        [fieldName]: new Set(disabledSet),
      };
      return newState;
    }
    case "ADD_TO_LOADING_MAP": {
      const { fieldName, form } = action.payload;
      let loadSet = state.loadingMap[fieldName];
      if (!loadSet) {
        loadSet = new Set();
      }
      loadSet.add(form);
      const newState = { ...state };
      newState.loadingMap = {
        ...state.loadingMap,
        [fieldName]: new Set(loadSet),
      };
      return newState;
    }
    case "REMOVE_FROM_DISABLED_MAP": {
      const { fieldName, form } = action.payload;
      let disabledSet = state.disabledMap[fieldName];
      if (!disabledSet) {
        disabledSet = new Set();
      }
      disabledSet.delete(form);
      const newState = { ...state };
      newState.disabledMap = {
        ...state.disabledMap,
        [fieldName]: new Set(disabledSet),
      };
      return newState;
    }
    case "REMOVE_FROM_LOADING_MAP": {
      const { fieldName, form } = action.payload;
      let loadSet = state.loadingMap[fieldName];
      if (!loadSet) {
        loadSet = new Set();
      }
      loadSet.delete(form);
      const newState = { ...state };
      newState.loadingMap = {
        ...state.loadingMap,
        [fieldName]: new Set(loadSet),
      };
      return newState;
    }
    case "ADD_TO_OPTIONS_CACHE": {
      const { form, key, options } = action.payload;
      const newState = { ...state };
      const optionsCache = { ...state.optionsCache };
      if (!optionsCache[form]) {
        optionsCache[form] = {};
      }
      optionsCache[form] = {
        ...state.optionsCache[form],
        [key]: options,
      };
      newState.optionsCache = optionsCache;

      return newState;
    }
    case "RESET_OPTIONS_CACHE": {
      const { form } = action.payload;
      const optionsCache = { ...state.optionsCache };
      delete optionsCache[form];
      return { ...state, optionsCache };
    }
    default:
      return { ...state };
  }
};

interface SelectOptionContextProps {
  children: React.ReactNode;
  defaultData?: SelectOptionState;
}

export const SelectOptionProvider: React.FC<SelectOptionContextProps> = ({
  children,
  defaultData,
}) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return (
    <SelectOptionDispatch.Provider value={dispatchF}>
      <SelectOptionContext.Provider value={[state, dispatchF]}>
        {children}
      </SelectOptionContext.Provider>
    </SelectOptionDispatch.Provider>
  );
};
