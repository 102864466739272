import React from "react";
import { PVR_CONFIGURATION_ENTITY, PVR_VALIDATION_RESULT } from "./fields";
import { StatusIndicator } from "components/standardTable/statusIndicator";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import moment from "moment-timezone";
import styled from "styled-components";
import { colors } from "../../../colors";
import { PVR_EMAIL_ENTITY } from "./email/constant";

export const getPvrConfigIdentifier = config => {
  const id = `${config[PVR_CONFIGURATION_ENTITY.id]}_${config[PVR_CONFIGURATION_ENTITY.mpid]}${
    config[PVR_CONFIGURATION_ENTITY.email]?.[PVR_EMAIL_ENTITY.id]
  }`;
  return id;
};

const MessageCount = styled.span`
  ${({ color }) => `
    text-decoration: underline;
    color: ${color};
  `}
`;

const YES = "Yes";
const NO = "No";
export const getYesOrNo = value => {
  return value ? YES : NO;
};

const PvrValidationResultColumn = (result, attribute) => {
  let color;
  switch (attribute) {
    case PVR_VALIDATION_RESULT.warnings:
      color = colors.warning;
      break;
    case PVR_VALIDATION_RESULT.errors:
      color = colors.danger;
      break;
    default:
      color = colors.black;
      break;
  }
  const messages = result[attribute]?.global;
  const attrJsx = messages?.map((attr, index) => {
    if (index < messages.length - 1) {
      return (
        <React.Fragment key={attr}>
          {attr}
          <br />
        </React.Fragment>
      );
    } else {
      return <React.Fragment key={attr}>{attr}</React.Fragment>;
    }
  });
  return (
    <TooltipTableCell
      align="center"
      id={`${attribute}_${getPvrConfigIdentifier(result)}`}
      tooltipContent={attrJsx ? attrJsx : `No ${attribute}`}
    >
      {messages?.length ? <MessageCount color={color}>{messages.length}</MessageCount> : "None"}
    </TooltipTableCell>
  );
};

const PvrAlertSharedColumns = {
  [PVR_CONFIGURATION_ENTITY.enabled]: {
    Header: "Status",
    accessor: PVR_CONFIGURATION_ENTITY.enabled,
    width: 90,
    id: PVR_CONFIGURATION_ENTITY.enabled,
    Cell: ({ row }) => {
      return (
        <StatusIndicator
          color={row.original[PVR_CONFIGURATION_ENTITY.enabled] ? "success" : "danger"}
          id={`${row.original[PVR_CONFIGURATION_ENTITY.id]}_status`}
        >
          {row.original[PVR_CONFIGURATION_ENTITY.enabled] ? "Enabled" : "Disabled"}
        </StatusIndicator>
      );
    },
    sortType: (a, b) => {
      if (
        a.original[PVR_CONFIGURATION_ENTITY.enabled] > b.original[PVR_CONFIGURATION_ENTITY.enabled]
      ) {
        return -1;
      } else if (
        a.original[PVR_CONFIGURATION_ENTITY.enabled] < b.original[PVR_CONFIGURATION_ENTITY.enabled]
      ) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  [PVR_CONFIGURATION_ENTITY.mpid]: {
    Header: "MPID",
    accessor: row => {
      return row[PVR_CONFIGURATION_ENTITY.mpid] === "*"
        ? "<ALL>"
        : row[PVR_CONFIGURATION_ENTITY.mpid];
    },
    width: 90,
    id: PVR_CONFIGURATION_ENTITY.mpid,
  },
  [PVR_CONFIGURATION_ENTITY.emailId]: {
    Header: "Email",
    accessor: row => row[PVR_CONFIGURATION_ENTITY.email]?.recipientEmail,
    width: 150,
    id: PVR_CONFIGURATION_ENTITY.emailId,
  },
  [PVR_CONFIGURATION_ENTITY.emailActive]: {
    Header: "Email",
    accessor: row => getYesOrNo(row[PVR_CONFIGURATION_ENTITY.emailActive]),
    width: 100,
    id: PVR_CONFIGURATION_ENTITY.emailActive,
  },
  [PVR_CONFIGURATION_ENTITY.logActive]: {
    Header: "UI Log",
    accessor: row => getYesOrNo(row[PVR_CONFIGURATION_ENTITY.logActive]),
    width: 100,
    id: PVR_CONFIGURATION_ENTITY.logActive,
  },
  [PVR_CONFIGURATION_ENTITY.popupActive]: {
    Header: "Pop-ups",
    accessor: row => getYesOrNo(row[PVR_CONFIGURATION_ENTITY.popupActive]),
    width: 100,
    id: PVR_CONFIGURATION_ENTITY.popupActive,
  },
  [PVR_CONFIGURATION_ENTITY.porStatusAlertActive]: {
    Header: "POR Status",
    accessor: row => getYesOrNo(row[PVR_CONFIGURATION_ENTITY.porStatusAlertActive]),
    width: 100,
    id: PVR_CONFIGURATION_ENTITY.porStatusAlertActive,
  },
  [PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive]: {
    Header: "PV Resubmit",
    accessor: row => getYesOrNo(row[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive]),
    width: 100,
    id: PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive,
  },
  [PVR_CONFIGURATION_ENTITY.pvWindowAlertActive]: {
    Header: "POR Time Window",
    accessor: row => getYesOrNo(row[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive]),
    width: 100,
    id: PVR_CONFIGURATION_ENTITY.pvWindowAlertActive,
  },
  [PVR_CONFIGURATION_ENTITY.creator]: {
    Header: "Alert Owner",
    accessor: row => row[PVR_CONFIGURATION_ENTITY.creator]?.[PVR_EMAIL_ENTITY.recipientEmail],
    width: 110,
    id: PVR_CONFIGURATION_ENTITY.creator,
  },
  [PVR_CONFIGURATION_ENTITY.lastUpdate]: {
    Header: "Last Update",
    accessor: row =>
      row[PVR_CONFIGURATION_ENTITY.lastUpdate]
        ? moment(row[PVR_CONFIGURATION_ENTITY.lastUpdate]).format("MM/DD/YYYY HH:mm:SS A")
        : "N/A",
    width: 100,
    id: PVR_CONFIGURATION_ENTITY.lastUpdate,
  },
  [PVR_VALIDATION_RESULT.hasError]: {
    Header: "Valid",
    accessor: PVR_VALIDATION_RESULT.errors,
    width: 90,
    id: PVR_VALIDATION_RESULT.hasError,
    Cell: ({ row }) => {
      let color = "success";
      let text = "This is a valid configuration";
      if (row.original[PVR_VALIDATION_RESULT.errors]) {
        color = "danger";
        text = "Validation error(s) detected";
      } else if (row.original[PVR_VALIDATION_RESULT.warnings]) {
        color = "warning";
        text = "This is a valid configuration with at least one warning";
      }
      return (
        <StatusIndicator
          color={color}
          id={`${getPvrConfigIdentifier(row.original)}_${[PVR_VALIDATION_RESULT.hasError]}`}
        >
          {text}
        </StatusIndicator>
      );
    },
    sortType: (a, b) => {
      const getVal = result => {
        return result?.[PVR_VALIDATION_RESULT.errors]?.[PVR_VALIDATION_RESULT.global]?.length
          ? 2
          : result?.[PVR_VALIDATION_RESULT.warnings]?.[PVR_VALIDATION_RESULT.global]?.length
          ? 1
          : 0;
      };
      const aVal = getVal(a.original);
      const bVal = getVal(b.original);
      return aVal - bVal;
    },
  },
  [PVR_VALIDATION_RESULT.errors]: {
    Header: "Error(s)",
    accessor: PVR_VALIDATION_RESULT.errors,
    width: 90,
    id: PVR_VALIDATION_RESULT.errors,
    Cell: ({ row }) => PvrValidationResultColumn(row.original, PVR_VALIDATION_RESULT.errors),
    sortType: (a, b) => {
      return (
        (a.original?.[PVR_VALIDATION_RESULT.errors]?.[PVR_VALIDATION_RESULT.global]?.length || 0) -
        (b.original?.[PVR_VALIDATION_RESULT.errors]?.[PVR_VALIDATION_RESULT.global]?.length || 0)
      );
    },
  },
  [PVR_VALIDATION_RESULT.warnings]: {
    Header: "Warning(s)",
    accessor: PVR_VALIDATION_RESULT.warnings,
    width: 90,
    id: PVR_VALIDATION_RESULT.warnings,
    Cell: ({ row }) => PvrValidationResultColumn(row.original, PVR_VALIDATION_RESULT.warnings),
    sortType: (a, b) => {
      return (
        (a.original?.[PVR_VALIDATION_RESULT.warnings]?.[PVR_VALIDATION_RESULT.global]?.length ||
          0) -
        (b.original?.[PVR_VALIDATION_RESULT.warnings]?.[PVR_VALIDATION_RESULT.global]?.length || 0)
      );
    },
  },
};

export const PvrAlertColumns = [
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.enabled],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.mpid],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.emailId],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.emailActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.logActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.popupActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.porStatusAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.creator],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.lastUpdate],
];

export const PvrAlertCreateValidationColumns = [
  PvrAlertSharedColumns[PVR_VALIDATION_RESULT.hasError],
  PvrAlertSharedColumns[PVR_VALIDATION_RESULT.errors],
  PvrAlertSharedColumns[PVR_VALIDATION_RESULT.warnings],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.mpid],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.emailId],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.emailActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.logActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.popupActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.porStatusAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.creator],
];

export const PvrAlertEditValidationColumns = [
  PvrAlertSharedColumns[PVR_VALIDATION_RESULT.hasError],
  PvrAlertSharedColumns[PVR_VALIDATION_RESULT.errors],
  PvrAlertSharedColumns[PVR_VALIDATION_RESULT.warnings],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.mpid],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.emailId],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.emailActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.logActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.popupActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.porStatusAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive],
  PvrAlertSharedColumns[PVR_CONFIGURATION_ENTITY.creator],
];
