import { CircleButton, FontAwesomeIcon, FormField } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { FlipCardHeader } from "components/pvRejects/client/constant";
import { ArrayWrapper } from "components/pvRejects/constant";
import { MpidOrSymbol, Mpid_Or_Security } from "components/pvRejects/finra/context";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

const FlipCardOuter = styled.div`
  width: 100%;
  perspective: 1000px;
  height: 100%;
`;
const FlipCardInner = styled.div<{ isFlipped: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  ${({ isFlipped }) => (isFlipped ? "transform: rotateY(180deg);" : "")}
`;

const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const FlipCardBack = styled.div`
  transform: rotateY(180deg);
  backface-visibility: hidden;
  height: calc(100% - 44px);
`;

interface FlipCardProps {
  isFlipped: boolean;
  front: React.ReactNode | JSX.Element;
  back: React.ReactNode | JSX.Element;
}

interface FlipCardFactoryProps {
  frontHeader: string | React.ReactNode;
  frontBody: React.ReactNode;
  backHeader: React.ReactNode;
  backBody:
    | React.ReactNode
    | ((
        data: {
          [symbol: string]: {
            label: string;
            value: React.ReactNode;
            count: number;
            subRows: any[];
            rejects: { [rejectMsg: string]: number };
          };
        },
        filterText: string
      ) => React.ReactNode);
  data: any;
  isSymbolOrMpid: Mpid_Or_Security;
}

const FlipCardWrapper = styled.div`
  grid-column: span 1;
`;
export const FlipCardFactory: React.FC<FlipCardFactoryProps> = ({
  frontHeader,
  frontBody,
  backHeader,
  backBody,
  data,
  isSymbolOrMpid,
}) => {
  const [isFlipped, setFlipped] = useState(false);
  const [filterText, setFilterText] = useState<string | string[] | undefined>("");

  const wrappedGetFilterText = useCallback(() => {
    if (filterText === undefined) {
      return "";
    } else if (Array.isArray(filterText)) {
      return filterText[0];
    } else {
      return filterText;
    }
  }, [filterText]);

  const wrappedSetFilterText = useCallback(
    (event: CustomChangeEvent) => setFilterText(event?.value?.toString()),
    [setFilterText]
  );

  const toggleFlip = useCallback(() => {
    setFlipped(!isFlipped);
  }, [isFlipped]);

  return (
    <FlipCardWrapper className="one">
      <FlipCard
        isFlipped={isFlipped}
        front={
          <>
            <FlipCardHeader size={3}>
              {frontHeader}
              <CircleButton onClick={toggleFlip} ghost={true} size="xs">
                <FontAwesomeIcon iconClassName="fa-chart-bar" />
              </CircleButton>
            </FlipCardHeader>

            {frontBody}
          </>
        }
        back={
          <>
            <FlipCardHeader size={3}>
              {backHeader}
              <CircleButton onClick={toggleFlip} ghost={true} size="xs">
                <FontAwesomeIcon iconClassName="fa-table" />
              </CircleButton>
            </FlipCardHeader>
            <FormField
              value={filterText?.toString()}
              onChange={wrappedSetFilterText}
              size={"sm"}
              placeholder={isSymbolOrMpid === MpidOrSymbol.MPID ? "MPID" : "Symbol"}
            />
            <ArrayWrapper>
              {typeof backBody !== "function" ? backBody : backBody(data, wrappedGetFilterText())}
            </ArrayWrapper>
          </>
        }
      />
    </FlipCardWrapper>
  );
};

export const FlipCard: React.FC<FlipCardProps> = ({ isFlipped, front, back }) => {
  return (
    <FlipCardOuter>
      <FlipCardInner isFlipped={isFlipped}>
        <FlipCardFront>{front}</FlipCardFront>
        <FlipCardBack>{back}</FlipCardBack>
      </FlipCardInner>
    </FlipCardOuter>
  );
};
