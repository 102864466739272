import {
  EmptyState,
  FontAwesomeIcon,
  NavbarIconContainer,
  Popover,
  PopoverDivider,
} from "@nef/core";
import { getColor } from "@nef/style-utils";
import { NotificationNavbarIcon } from "components/styled";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import { useAlertContext, useAlertDispatch } from "./context";

const AlertContainer = styled.section`
  ${props => `
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(${props.numAlerts}, ${props.itemHeight}px);
    height: 100%;
    overflow: overlay;
  `}
`;

const GridItemWrapper = styled.div`
  ${props => `
    grid-row-start: ${props.rowStart};
  `}
`;

const AlertItem = styled.div`
  ${props => `
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: auto auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
    box-sizing: border-box;
    color: ${props.isRead ? getColor("gray", 300) : "inherit"};
    height: 100%;
  `}
`;

const AlertTitle = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
`;

const AlertBody = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
`;

const AlertCheckbox = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
`;

const footerHeight = 35;

const AlertFooter = styled.footer`
  ${props => `
    width: 100%;
    display: flex;
    height: ${footerHeight}px;
    position: absolute;
    bottom: ${props.bottom};
    padding: 0rem 1rem;
    box-sizing: border-box;
    background-color: inherit;
    transition: bottom .5s;
    border-top: 1px solid ${getColor("gray", 50)(props)};
  `}
`;

const ShowNewIcon = styled(FontAwesomeIcon)`
  ${props => `
    color: ${getColor("warning", 500)(props)};
    margin-right: .5rem;
  `}
`;

const AlertShowNew = styled.div`
  ${props => `
    width: 100%;
    display: flex;
    height: ${footerHeight}px;
    position: absolute;
    bottom: ${props.bottom};
    background-color: inherit;
    justify-content: center;
    align-items: center;
    transition: bottom .5s;
    color: ${getColor("warning", 700)(props)};
    border-top: 1px solid;
    cursor: pointer;
    box-sizing: border-box;
  `}
`;

const StyledPopover = styled(Popover)`
  ${props => `
    padding-top: 0px !important;
    padding-bottom: ${props.hasShowNew ? footerHeight * 2 : footerHeight}px !important;
    overflow: hidden !important;
    height: 30vh;
    box-sizing: border-box;
  `}
`;

const StyledDivider = styled(PopoverDivider)`
  margin: 0px !important;
`;

const StyledEmptyState = styled(EmptyState)`
  height: 100%;
`;

const defaultNumItemsDisplayed = 30;
const defaultState = {
  items: [],
  numItemsDisplayed: defaultNumItemsDisplayed,
  current: Math.floor(defaultNumItemsDisplayed / 2),
  refresh: false,
  alerts: [],
};

const createAlertItem = (alert, idx, rowStart) => {
  return (
    <GridItemWrapper key={idx} rowStart={rowStart}>
      <AlertItem isRead={alert.isRead}>
        <AlertTitle>{alert.title}</AlertTitle>
        <AlertBody>{alert.subtitle}</AlertBody>
        <AlertCheckbox></AlertCheckbox>
      </AlertItem>
      <StyledDivider />
    </GridItemWrapper>
  );
};

const dispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return dispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => dispatchFnSwitch(acc, curr), { ...state });
};

const dispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "HANDLE_RESET": {
      return { ...state, current: Math.floor(defaultNumItemsDisplayed / 2) };
    }
    case "SET_REFRESH": {
      return { ...state, refresh: action.payload };
    }
    case "UPDATE_ITEMS": {
      const alerts = action.payload;
      const items = [];
      alerts.forEach((alert, idx) => {
        items.unshift(createAlertItem(alert, idx, alerts.length - idx));
      });
      return { ...state, items, alerts, refresh: false };
    }
    case "HANDLE_SCROLL": {
      const { topIdx, halfNumItemsDispalyed, maxCurrent } = action.payload;
      let nextCurrent = topIdx;
      if (nextCurrent < halfNumItemsDispalyed) {
        nextCurrent = halfNumItemsDispalyed;
      }
      if (nextCurrent > maxCurrent) {
        nextCurrent = maxCurrent;
      }
      if (state.current === nextCurrent) {
        return state;
      }
      return { ...state, current: nextCurrent };
    }
    default: {
      return { ...state };
    }
  }
};

export const GenericAlerts = ({
  iconClassName,
  id,
  tooltip,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyFilterTitle,
  emptyFilterSubtitle,
  itemHeight,
  onChangeAlertSetShown,
  hasUnread,
}) => {
  const [alertData] = useAlertContext();
  const alertDispatch = useAlertDispatch();
  const [open, setOpen] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [state, dispatch] = useReducer(dispatchFn, defaultState);
  const iconRef = useRef();
  const popoverRef = useRef();
  const containerRef = useRef();

  const halfNumItemsDispalyed = useMemo(() => {
    return Math.floor(state.numItemsDisplayed / 2);
  }, [state.numItemsDisplayed]);

  const maxCurrent = useMemo(() => {
    return Math.max(state.items.length - Math.floor(state.numItemsDisplayed / 2), 0);
  }, [state.items, state.numItemsDisplayed]);

  useEffect(() => {
    if (alertData.forceRefresh) {
      dispatch([
        {
          type: "UPDATE_ITEMS",
          payload: alertData.alerts,
        },
        { type: "HANDLE_RESET" },
      ]);
      alertDispatch({
        type: "FINISH_FORCE_REFRESH",
      });
      containerRef?.current?.scrollTo(0, 0);
    }
  }, [alertData, alertData.alerts, alertData.forceRefresh, alertDispatch, containerRef]);

  useEffect(() => {
    if (
      state.alerts.length === 0 ||
      ((state.refresh || !open) && alertData.alerts !== state.alerts)
    ) {
      dispatch({
        type: "UPDATE_ITEMS",
        payload: alertData.alerts,
      });
    }
  }, [alertData.alerts, open, state.alerts, state.refresh]);

  useEffect(() => {
    if (typeof onChangeAlertSetShown === "function") {
      onChangeAlertSetShown(state.alerts);
    }
  }, [onChangeAlertSetShown, state.alerts]);

  const handleMouseOver = useCallback(
    e => {
      if (e.target.id === id) {
        setHovered(true);
      }
    },
    [id]
  );

  const handleMouseOut = useCallback(e => {
    setHovered(false);
  }, []);

  const toggleOpen = useCallback(() => {
    if (open) {
      dispatch({ type: "HANDLE_RESET" });
    }
    setOpen(!open);
  }, [open]);

  const handleRefresh = useCallback(() => {
    dispatch({ type: "SET_REFRESH", payload: true });
  }, []);

  useEffect(() => {
    const listener = event => {
      if (
        !iconRef.current ||
        !popoverRef.current ||
        iconRef.current.contains(event.target) ||
        popoverRef.current.contains(event.target)
      ) {
        return;
      }
      setOpen(false);
      dispatch([{ type: "HANDLE_RESET" }]);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [dispatch]);

  const handleScroll = useCallback(
    e => {
      const topIdx = Math.floor(e.target.scrollTop / itemHeight);
      if (Math.abs(topIdx - state.current) > state.numItemsDisplayed / 3) {
        dispatch({
          type: "HANDLE_SCROLL",
          payload: { topIdx, halfNumItemsDispalyed, maxCurrent },
        });
      }
    },
    [itemHeight, maxCurrent, halfNumItemsDispalyed, state]
  );

  const AlertItems = useMemo(() => {
    let start = Math.max(state.current - halfNumItemsDispalyed, 0);
    const end = start + state.numItemsDisplayed;
    return state.items.slice(start, end);
  }, [halfNumItemsDispalyed, state]);

  const hasShowNew = useMemo(() => {
    return alertData.alerts !== state.alerts;
  }, [alertData.alerts, state.alerts]);

  return (
    <>
      <NavbarIconContainer
        onClick={toggleOpen}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        title={tooltip}
      >
        <div ref={iconRef}>
          <NotificationNavbarIcon
            id={id}
            iconClassName={iconClassName}
            $hasNotification={hasUnread}
          />
        </div>
      </NavbarIconContainer>
      <StyledPopover target={id} isOpen={open} width={450} hasShowNew={hasShowNew} ref={popoverRef}>
        {alertData.totalNumAlerts > 0 ? (
          <>
            {state.items.length > 0 ? (
              <>
                <AlertContainer
                  numAlerts={state.items.length}
                  itemHeight={itemHeight}
                  onScroll={handleScroll}
                  ref={containerRef}
                >
                  {AlertItems}
                </AlertContainer>
                <StyledDivider />
                <StyledDivider />
                <AlertFooter bottom={hasShowNew ? `${footerHeight}px` : "0px"}>
                  {alertData.footer}
                </AlertFooter>
                <AlertShowNew
                  onClick={handleRefresh}
                  bottom={hasShowNew ? "0px" : `-${footerHeight}px`}
                >
                  <ShowNewIcon iconClassName={`fa-exclamation-triangle`} />
                  Show Latest
                </AlertShowNew>
              </>
            ) : (
              <>
                <StyledEmptyState icon="alert" emptyStateTitle={emptyFilterTitle}>
                  {emptyFilterSubtitle}
                </StyledEmptyState>
                <AlertFooter bottom={hasShowNew ? `${footerHeight}px` : "0px"}>
                  {alertData.footer}
                </AlertFooter>
              </>
            )}
          </>
        ) : (
          <StyledEmptyState icon="alert" emptyStateTitle={emptyStateTitle}>
            {emptyStateSubtitle}
          </StyledEmptyState>
        )}
      </StyledPopover>
      {/* <Tooltip target={id} isOpen={isHovered} offsetTop={4}>
        {tooltip}
      </Tooltip> */}
    </>
  );
};
