import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NotificationHub } from "@nef/core";
import { useFormContext, useFormDispatch } from "components/form";
import { useRefDataContext } from "components/refData";
import {
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { useUserContext } from "components/user";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "../../../keycloak";
import { doFetchWrapper } from "../../../network";
import { usePvrConfigDispatch } from "./configContext";
import { PVR_CONFIGURATION_ENTITY, PVR_VALIDATION_RESULT } from "./fields";
import { PVR_EMAIL_ENTITY } from "./email/constant";

const StyledModalBody = styled(ModalBody)`
  height: 90vh;
  overflow: overlay;
`;

const MISSING_FIELDS_MESSAGE = "Missing required fields to create alert configuration(s).";
export const PvrCreateModal = ({
  form,
  table,
  RiskAlertForm,
  ValidationTable,
  isVisible,
  onClose,
}) => {
  const tableDispatch = useStandardTableDispatch();
  const [tableData] = useStandardTableContext();
  const [formData] = useFormContext();
  const [stepNumber, setStepNumber] = useState(0);
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();
  const [refData] = useRefDataContext();
  const [hasError, setHasError] = useState(true);
  const configDispatch = usePvrConfigDispatch();

  const handleCloseNew = useCallback(() => {
    setStepNumber(0);
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose]);

  const createManyCallback = useCallback(
    data => {
      NotificationHub.send("success", `Created ${data?.length || 0} alert configuration(s)`);
      configDispatch({
        type: "SET_REQUESTING",
        payload: true,
      });
    },
    [configDispatch]
  );

  const createManyError = useCallback(() => {
    NotificationHub.send("danger", `Failed to create alert configuration(s)`);
  }, []);

  const createConfigs = useMemo(() => {
    const alertData = formData[form.key].fields;
    let mpids;
    if (alertData[PVR_CONFIGURATION_ENTITY.isAllMpid]) {
      mpids = [{ label: "<ALL>", value: "*" }];
    } else {
      mpids = alertData[PVR_CONFIGURATION_ENTITY.mpid];
    }
    if (Array.isArray(mpids)) {
      const recipients =
        alertData[PVR_CONFIGURATION_ENTITY.emailId]?.map(emailOpt => emailOpt?.value) || [];
      const addWorkXUser =
        alertData[PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient] ||
        alertData[PVR_CONFIGURATION_ENTITY.logActive] ||
        alertData[PVR_CONFIGURATION_ENTITY.popupActive];
      if (addWorkXUser) {
        recipients.unshift("00000000-0000-0000-0000-000000000000");
      }
      const alertConfigs = [];
      recipients.forEach((emailId, index) => {
        // create config for each mpid + email combination
        mpids.forEach(mpid => {
          alertConfigs.push({
            [PVR_CONFIGURATION_ENTITY.mpid]: mpid?.value,
            [PVR_CONFIGURATION_ENTITY.emailId]: emailId,
            [PVR_CONFIGURATION_ENTITY.logActive]:
              index === 0 && addWorkXUser && alertData[PVR_CONFIGURATION_ENTITY.logActive],
            [PVR_CONFIGURATION_ENTITY.popupActive]:
              index === 0 && addWorkXUser && alertData[PVR_CONFIGURATION_ENTITY.popupActive],
            [PVR_CONFIGURATION_ENTITY.emailActive]:
              (index === 0 &&
                addWorkXUser &&
                alertData[PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient]) ||
              !addWorkXUser ||
              index > 0,
            [PVR_CONFIGURATION_ENTITY.porStatusAlertActive]:
              alertData[PVR_CONFIGURATION_ENTITY.porStatusAlertActive],
            [PVR_CONFIGURATION_ENTITY.pvWindowAlertActive]:
              alertData[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive],
            [PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive]:
              alertData[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive],
          });
        });
      });
      return alertConfigs;
    } else {
      return [];
    }
  }, [form.key, formData]);

  const handleSaveNew = useCallback(() => {
    if (createConfigs.length > 0) {
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "alert/configuration/createMany"),
        {
          method: "post",
          headers: getHeaders(),
          body: JSON.stringify(createConfigs),
        },
        createManyCallback,
        createManyError
      );
      handleCloseNew();
    } else {
      NotificationHub.send("warning", MISSING_FIELDS_MESSAGE);
    }
  }, [createConfigs, createManyCallback, createManyError, handleCloseNew]);

  const handleBack = useCallback(() => {
    setHasError(true);
    setStepNumber(0);
  }, []);

  const handleValidateCreate = useCallback(() => {
    if (formValidationCheck({ formData, formDispatch, form, userData })) {
      const success = data => {
        const {
          [PVR_VALIDATION_RESULT.entities]: entities,
          [PVR_VALIDATION_RESULT.hasError]: hasError,
        } = data;
        setHasError(hasError);
        entities.forEach(config => {
          const identifier = `${config[PVR_CONFIGURATION_ENTITY.email][PVR_EMAIL_ENTITY.id]}|${
            config[PVR_CONFIGURATION_ENTITY.mpid]
          }`;
          // errors
          if (data[PVR_VALIDATION_RESULT.errors]?.[identifier]) {
            config[PVR_VALIDATION_RESULT.errors] = data[PVR_VALIDATION_RESULT.errors][identifier];
          } else {
            delete config[PVR_VALIDATION_RESULT.errors];
          }
          // warnings
          if (data[PVR_VALIDATION_RESULT.warnings]?.[identifier]) {
            config[PVR_VALIDATION_RESULT.warnings] =
              data[PVR_VALIDATION_RESULT.warnings][identifier];
          } else {
            delete config[PVR_VALIDATION_RESULT.warnings];
          }
        });
        tableDispatch([
          {
            type: "SET_TABLE_DATA",
            payload: {
              table,
              data: entities,
            },
          },
          { type: "SET_NOT_LOADING", payload: { table } },
        ]);
      };
      const error = errors => {
        NotificationHub.send("danger", "An error occurred while validating.");
      };

      if (createConfigs.length > 0) {
        doFetchWrapper(
          formatUrl(
            process.env.REACT_APP_URL_PVR_ALERT_SERVICE,
            "alert/configuration/validateCreate"
          ),
          {
            method: "post",
            headers: getHeaders(),
            body: JSON.stringify(createConfigs),
          },
          success,
          error
        );
        tableDispatch({
          type: "SET_LOADING",
          payload: { table },
        });
        setStepNumber(1);
      } else {
        NotificationHub.send("warning", MISSING_FIELDS_MESSAGE);
      }
    }
  }, [createConfigs, form, formData, formDispatch, table, tableDispatch, userData]);

  return (
    <Modal
      isOpen={isVisible}
      closeOnOutsideClick={true}
      toggle={handleCloseNew}
      size={stepNumber === 0 ? "md" : "flex"}
    >
      <ModalHeader toggle={handleCloseNew} title="Create Alert for Price Reject" />
      <StyledModalBody>
        {stepNumber === 0 ? <RiskAlertForm /> : <ValidationTable table={table} />}
      </StyledModalBody>
      <ModalFooter>
        {stepNumber === 0 ? (
          <>
            <Button outline={true} onClick={handleCloseNew}>
              Close
            </Button>
            <Button onClick={handleValidateCreate}>Next</Button>
          </>
        ) : (
          <>
            <Button outline={true} onClick={handleBack}>
              Back
            </Button>
            <Button onClick={handleSaveNew} disabled={hasError || tableData[table].isLoading}>
              Save
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};
