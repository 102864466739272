import { CircleButton, FontAwesomeIcon } from "@nef/core";
import { LimoLVCIndicator } from "components/limitMonitor";
import React from "react";
import { abbreviateNumber } from "utils/js.utils";
import { Status } from "wksConstantsTS";
import { ServiceIndicatorWrapper } from "../constant";
import { FlipCardHeader } from "components/pvRejects/client/constant";

type RejectsFlipCardSectionProps = {
  totalRejectCount: number;
  toggleFlipped: () => void;
  children: React.ReactNode | JSX.Element;
  cacheStatus: Status;
};

export const RejectsFlipCardSection: React.FC<RejectsFlipCardSectionProps> = ({
  totalRejectCount,
  toggleFlipped,
  children,
  cacheStatus,
}) => {
  return (
    <>
      <FlipCardHeader size={3}>
        <ServiceIndicatorWrapper>
          Price Rejects By Symbol - {abbreviateNumber(totalRejectCount)}
          <LimoLVCIndicator
            id={"rej-symbol-count-indicator"}
            status={cacheStatus}
            prefix={undefined}
          />
        </ServiceIndicatorWrapper>
        <CircleButton onClick={toggleFlipped} ghost={true} size="xs">
          <FontAwesomeIcon iconClassName="fa-chart-bar" />
        </CircleButton>
      </FlipCardHeader>
      {children}
    </>
  );
};
