import { BarChart } from "@nef/charts";
import React, { useMemo } from "react";
import { useRejectCountState } from "../cache/rejectCountCache";
import {
  PV_REJECT_LABELS,
  PV_REJECT_MESSAGE,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  REJ_PRICE_OO_RANGE,
  sortRejectArrayData,
} from "../constant";
import { ChartContainer, FlipChartContainer } from "./constant";

export const RejectsBySymbolBarChart: React.FC = () => {
  const rejectCountState = useRejectCountState();

  const barChartData = useMemo(() => {
    const data = Object.entries(rejectCountState.counts).reduce((acc, [symbol, count]) => {
      acc[symbol] = {
        [REJ_PRICE_OO_RANGE]: count.priceCount,
        [REJ_PRICE_OO_OVERRIDE_RANGE]: count.priceOverrideCount,
      };
      return acc;
    }, {} as { [symbol: string]: { [rejMsg: string]: number } });
    const arrData = Object.entries(data).map(([key, val]) => {
      return { symbol: key, rejects: val };
    });
    arrData.sort(sortRejectArrayData);
    const chartData = [];
    for (let i = 0; i < 5 && i < arrData.length; i++) {
      chartData.push({
        name: arrData[i].symbol,
        values: Object.entries(arrData[i].rejects).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    const countOfAllOthers = arrData.slice(5, arrData.length).reduce((acc, curr) => {
      Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
        if (!acc[rejMsg]) {
          acc[rejMsg] = 0;
        }
        acc[rejMsg] += count;
      });
      return acc;
    }, {} as { [rejMsg: string]: number });
    const vals = Object.values(countOfAllOthers);
    if (vals.length > 0 && vals.reduce((acc, curr) => acc + curr) > 0) {
      chartData.push({
        name: "Other",
        values: Object.entries(countOfAllOthers).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    return chartData;
  }, [rejectCountState.counts]);

  return (
    <FlipChartContainer>
      <ChartContainer>
        <BarChart data={barChartData} legend={true} />
      </ChartContainer>
    </FlipChartContainer>
  );
};
