import { ReactNode } from "react";

export enum GENERIC_ALERT_ITEM {
  ICON = "icon",
  TITLE = "title",
  SUBTITLE = "subtitle",
  ID = "id",
  TIME = "time",
}

export type GenericAlertItem = {
  [GENERIC_ALERT_ITEM.ICON]: JSX.Element | ReactNode;
  [GENERIC_ALERT_ITEM.TITLE]: string | JSX.Element | ReactNode;
  [GENERIC_ALERT_ITEM.SUBTITLE]: string | JSX.Element | ReactNode;
  [GENERIC_ALERT_ITEM.ID]: string;
  [GENERIC_ALERT_ITEM.TIME]: number;
};
