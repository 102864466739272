import { NotificationHub } from "@nef/core";
import { ContextProviderProps, DispatchFn, DispatchFnSwitch } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useReducer } from "react";
import { formatUrl } from "utils/js.utils";
import { Status } from "wksConstantsTS";

export enum POR_COUNT_FILTER_STATUS {
  ALL,
  PENDING,
}

export enum POR_COUNT_FILTER_BY {
  MPID,
  SYMBOL,
}

export enum POR_FINRA_COUNT_TYPE {
  MPID = "mpidCounts",
  SYMBOL = "symbolCounts",
}

type POR_COUNT_FILTER = null | {
  filterBy: POR_COUNT_FILTER_BY;
  filterStatus: POR_COUNT_FILTER_STATUS;
};

type StatusCountMap = {
  PENDING: number;
  APPROVED: number;
  DENIED: number;
  EXPIRED: number;
};

type PorCount = { key: string; statusCountMap: StatusCountMap; timestamp: number };

type FinraCountResponse = {
  mpidCountResponse: PorCount[];
  symbolCountResponse: PorCount[];
  porWindowId: string;
};

type PorCountData = {
  [countType in POR_FINRA_COUNT_TYPE]: { [countKey: string]: PorCount };
};

type PorCounts = {
  [POR_FINRA_COUNT_TYPE.MPID]: { [mpid: string]: number };
  [POR_FINRA_COUNT_TYPE.SYMBOL]: { [symbol: string]: number };
};

type OffsetMap = { [key: string]: number };

export type PorCountState = {
  data: PorCountData;
  counts: PorCounts;
  pending: PorCounts;
  offsets: OffsetMap;
  filter: POR_COUNT_FILTER;
  windowId: string | null;
  status: Status;
  makeInitialRequest: boolean;
  requestFailedCount: number;
  hasMadeInitialRequest: boolean;
  isPolling: boolean;
  isSummaryPolling: boolean;
  isRightPolling: boolean;
  isRequesting: boolean;
  timestamp: number;
  instId: number;
  isLoading: boolean;
  requestAbort: boolean;
  abort: AbortController;
  requestTimer: NodeJS.Timeout | undefined;
};

export type PorCountAction =
  | { type: "START_POLLING" }
  | { type: "RESET_CACHE" }
  | { type: "STOP_POLLING" }
  | { type: "HANDLE_INITIAL_REQUEST" }
  | { type: "HANDLE_REQUEST_FAILED"; payload: number }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_REQUESTING"; payload: boolean }
  | { type: "SET_REQUEST_STATUS"; payload: Status }
  | { type: "SET_ABORT"; payload: AbortController }
  | { type: "SET_REQUEST_TIMER"; payload: NodeJS.Timeout | undefined }
  | { type: "SET_DATA"; payload: PorCountData }
  | { type: "SET_COUNTS"; payload: PorCounts }
  | { type: "SET_PENDING"; payload: PorCounts }
  | { type: "SET_OFFSETS"; payload: OffsetMap }
  | { type: "SET_WINDOW_ID"; payload: string };

export type PorCountItem = {
  priceCount: number;
  priceOverrideCount: number;
  key: string;
  timestamp: number;
};

const FACTORY = new ContextFactory<PorCountState, PorCountAction>();

const DEFAULT_STATE = {
  data: { [POR_FINRA_COUNT_TYPE.MPID]: {}, [POR_FINRA_COUNT_TYPE.SYMBOL]: {} },
  counts: { [POR_FINRA_COUNT_TYPE.MPID]: {}, [POR_FINRA_COUNT_TYPE.SYMBOL]: {} },
  pending: { [POR_FINRA_COUNT_TYPE.MPID]: {}, [POR_FINRA_COUNT_TYPE.SYMBOL]: {} },
  offsets: {},
  windowId: null,
  filter: null,
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isSummaryPolling: false,
  isRightPolling: false,
  isRequesting: false,
  timestamp: 0,
  instId: 0,
  isLoading: false,
  requestAbort: false,
  abort: new AbortController(),
  requestTimer: undefined,
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<PorCountState, PorCountAction> = (
  prevState: PorCountState,
  action: PorCountAction
) => {
  switch (action.type) {
    case "START_POLLING": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        isPolling: true,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        windowId: null,
        isRequesting: false,
        isLoading: true,
        data: { [POR_FINRA_COUNT_TYPE.MPID]: {}, [POR_FINRA_COUNT_TYPE.SYMBOL]: {} },
        counts: { [POR_FINRA_COUNT_TYPE.MPID]: {}, [POR_FINRA_COUNT_TYPE.SYMBOL]: {} },
        pending: { [POR_FINRA_COUNT_TYPE.MPID]: {}, [POR_FINRA_COUNT_TYPE.SYMBOL]: {} },
        offsets: {},
        timestamp: 0,
      };
    }
    case "RESET_CACHE": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...DEFAULT_STATE,
      };
    }
    case "STOP_POLLING": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        isPolling: false,
      };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...prevState,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...prevState,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_LOADING": {
      return { ...prevState, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...prevState, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...prevState, status: action.payload };
    }
    case "SET_ABORT": {
      return { ...prevState, abort: action.payload };
    }
    case "SET_REQUEST_TIMER": {
      return { ...prevState, requestTimer: action.payload };
    }
    case "SET_DATA": {
      return { ...prevState, data: action.payload };
    }
    case "SET_COUNTS": {
      return { ...prevState, counts: action.payload, isLoading: false };
    }
    case "SET_PENDING": {
      return { ...prevState, pending: action.payload, isLoading: false };
    }
    case "SET_OFFSETS": {
      return { ...prevState, offsets: action.payload };
    }
    case "SET_WINDOW_ID": {
      return { ...prevState, windowId: action.payload };
    }
    default:
      return { ...prevState };
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<PorCountState, PorCountAction>(DISPATCH_FN_SWITCH);

interface PorCountProviderProps extends ContextProviderProps {}

const pollingIntervalMs = 4000;
export const PorCountProvider: React.FC<PorCountProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<PorCountState, PorCountAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );

  const getCountData = useCallback(
    (isInitialData: boolean) => {
      const getSuccessActions = ({
        isInitialData,
        hasUpdate,
        timer,
        windowId,
        newOffsets,
        newData,
        newCounts,
        newPending,
      }: {
        isInitialData: boolean;
        hasUpdate: boolean;
        timer: NodeJS.Timeout;
        windowId: string;
        newOffsets?: OffsetMap;
        newData?: PorCountData;
        newCounts?: PorCounts;
        newPending?: PorCounts;
      }) => {
        const actions: PorCountAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          {
            type: "SET_REQUEST_STATUS",
            payload: Status.SUCCESS,
          },
          {
            type: "SET_REQUEST_TIMER",
            payload: timer,
          },
        ];

        if (isInitialData) {
          actions.push({ type: "HANDLE_INITIAL_REQUEST" });
        }
        if (hasUpdate) {
          if (newOffsets !== undefined) {
            actions.push({
              type: "SET_OFFSETS",
              payload: newOffsets,
            });
          } else {
            console.warn("PorCountCache: Missing new offsets");
          }
          if (newData !== undefined) {
            actions.push({
              type: "SET_DATA",
              payload: newData,
            });
          } else {
            console.warn("PorCountCache: Missing new data");
          }
          if (newCounts !== undefined) {
            actions.push({
              type: "SET_COUNTS",
              payload: newCounts,
            });
          } else {
            console.warn("PorCountCache: Missing new counts");
          }
          if (newPending !== undefined) {
            actions.push({
              type: "SET_PENDING",
              payload: newPending,
            });
          } else {
            console.warn("PorCountCache: Missing new new pending");
          }
        }
        if (state.windowId !== windowId) {
          actions.push({
            type: "SET_WINDOW_ID",
            payload: windowId,
          });
        }
        return actions;
      };

      const getCountsError = () => {
        const actions: PorCountAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
        ];
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        if (state.status !== Status.ERROR) {
          NotificationHub.send("danger", "Error getting POR counts");
        }
        dispatch(actions);
      };

      const getPorAbortCb = () => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      };

      const getCountsSuccess = (data: FinraCountResponse) => {
        if (state.windowId !== null && state.windowId !== data.porWindowId) {
          dispatch({
            type: "START_POLLING",
          });
        } else {
          const timer = setTimeout(() => {
            dispatch({ type: "SET_REQUESTING", payload: true });
          }, pollingIntervalMs);

          const hasUpdate =
            data.mpidCountResponse.length > 0 || data.symbolCountResponse.length > 0;
          if (hasUpdate) {
            const newOffsets = { ...state.offsets };
            const newData: {
              [countType in POR_FINRA_COUNT_TYPE]: { [countKey: string]: PorCount };
            } = { ...state.data };
            const newCounts = { ...state.counts };
            const newPending = { ...state.pending };
            // mpid counts
            data.mpidCountResponse.forEach(count => {
              newOffsets[POR_FINRA_COUNT_TYPE.MPID] = Math.max(
                newOffsets[POR_FINRA_COUNT_TYPE.MPID] || 0,
                count.timestamp
              );
              if (newData[POR_FINRA_COUNT_TYPE.MPID] === undefined) {
                newData[POR_FINRA_COUNT_TYPE.MPID] = {};
              }
              newData[POR_FINRA_COUNT_TYPE.MPID][count.key] = count;
              const totalCount =
                count.statusCountMap.APPROVED +
                count.statusCountMap.PENDING +
                count.statusCountMap.DENIED +
                count.statusCountMap.EXPIRED;
              if (totalCount > 0) {
                newCounts[POR_FINRA_COUNT_TYPE.MPID][count.key] = totalCount;
                if (count.statusCountMap.PENDING > 0) {
                  newPending[POR_FINRA_COUNT_TYPE.MPID][count.key] = count.statusCountMap.PENDING;
                } else {
                  delete newPending[POR_FINRA_COUNT_TYPE.MPID][count.key];
                }
              } else {
                delete newCounts[POR_FINRA_COUNT_TYPE.MPID][count.key];
                delete newPending[POR_FINRA_COUNT_TYPE.MPID][count.key];
              }
            });

            // symbol counts
            data.symbolCountResponse.forEach(count => {
              newOffsets[POR_FINRA_COUNT_TYPE.SYMBOL] = Math.max(
                newOffsets[POR_FINRA_COUNT_TYPE.SYMBOL] || 0,
                count.timestamp
              );
              if (newData[POR_FINRA_COUNT_TYPE.SYMBOL] === undefined) {
                newData[POR_FINRA_COUNT_TYPE.SYMBOL] = {};
              }
              newData[POR_FINRA_COUNT_TYPE.SYMBOL][count.key] = count;
              const totalCount =
                count.statusCountMap.APPROVED +
                count.statusCountMap.PENDING +
                count.statusCountMap.DENIED +
                count.statusCountMap.EXPIRED;
              if (totalCount > 0) {
                newCounts[POR_FINRA_COUNT_TYPE.SYMBOL][count.key] = totalCount;
                if (count.statusCountMap.PENDING > 0) {
                  newPending[POR_FINRA_COUNT_TYPE.SYMBOL][count.key] = count.statusCountMap.PENDING;
                } else {
                  delete newPending[POR_FINRA_COUNT_TYPE.SYMBOL][count.key];
                }
              } else {
                delete newCounts[POR_FINRA_COUNT_TYPE.SYMBOL][count.key];
                delete newPending[POR_FINRA_COUNT_TYPE.SYMBOL][count.key];
              }
            });
            dispatch(
              getSuccessActions({
                isInitialData,
                hasUpdate,
                timer,
                windowId: data.porWindowId,
                newOffsets,
                newData,
                newCounts,
                newPending,
              })
            );
          } else {
            dispatch(
              getSuccessActions({ isInitialData, hasUpdate, timer, windowId: data.porWindowId })
            );
          }
        }
      };

      const abortController = new AbortController();
      dispatch({ type: "SET_ABORT", payload: abortController });
      let endpoint = "/por/cache/get-count-by-symbol-and-mpid";
      let criteria = {
        mpidCountRequest: [
          { key: "*", status: null, timestamp: state.offsets[POR_FINRA_COUNT_TYPE.MPID] || 0 },
        ],
        symbolCountRequest: [
          { key: "*", status: null, timestamp: state.offsets[POR_FINRA_COUNT_TYPE.SYMBOL] || 0 },
        ],
      };

      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, endpoint),
        {
          method: "post",
          mode: "cors",
          signal: abortController.signal,
          headers: getHeaders(),
          body: JSON.stringify(criteria),
        },
        getCountsSuccess,
        getCountsError,
        getPorAbortCb
      );
    },
    [
      state.offsets,
      state.data,
      state.counts,
      state.pending,
      state.requestFailedCount,
      state.status,
      state.windowId,
    ]
  );

  // -- initial request --
  // should only have to run once as long as timestamp
  // is persisted throughout the entire session
  useEffect(() => {
    if (state.makeInitialRequest && state.isPolling) {
      if (state.requestFailedCount > 0) {
        const timer = setTimeout(() => {
          getCountData(true);
        }, pollingIntervalMs);
        dispatch({ type: "SET_REQUEST_TIMER", payload: timer });
      } else {
        dispatch({ type: "SET_LOADING", payload: true });
        getCountData(true);
      }
    }
  }, [state.makeInitialRequest, getCountData, state.requestFailedCount, state.isPolling]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting && !state.requestAbort) {
      dispatch({ type: "SET_REQUESTING", payload: false });
      getCountData(false);
    } else if (!state.isPolling) {
      dispatch({ type: "SET_REQUEST_STATUS", payload: Status.NO_STATUS });
    }
  }, [state.isPolling, state.isRequesting, state.requestAbort, getCountData]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const usePorCountDispatch =
  FACTORY.createContextHook<React.Dispatch<PorCountAction | PorCountAction[]>>(DISPATCH_CONTEXT);
export const usePorCountState = FACTORY.createContextHook<PorCountState>(STATE_CONTEXT);
