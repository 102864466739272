import { covertTableRowToFormValues } from "../constants";

export const copyRowToForm = (
  row,
  form,
  status,
  includeExchangeMpidPort,
  oppositeRow,
  formDispatch
) => {
  const statusRow = row?.[status];
  if (statusRow) {
    const fields = covertTableRowToFormValues(
      statusRow,
      includeExchangeMpidPort,
      oppositeRow,
      status
    );

    formDispatch([
      {
        type: "SET_FORM_VALUES",
        payload: { form, fields },
      },
      { type: "INIT_FORM_VALIDATION", payload: { form } },
    ]);
  }
};
