import { Header } from "@nef/core";
import { BulkJob } from "components/topBar/bulkJob/constant";
import styled from "styled-components";

export enum PV_REJECT_TOP {
  mpid = "mpid",
  rejectDate = "rejectDate",
}

export const FlipCardHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoRejectsFiller = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoRejectsFillerJsx = <NoRejectsFiller>No Rejects Found</NoRejectsFiller>;

export const FlipChartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.5rem;
`;

export type PieChartData = { name: string; value: number }[];

export enum BulkResubmitStatus {
  Success = "Success", // Request was good
  Reject = "Reject", // i.e. ACT Reject
  Failed = "Failed", // i.e. Bad Request
  Error = "Error", // i.e. Runtime exception, 500s
  Ignore = "Ignore",
}

export type BulkResubmitResponseDTO = {
  status: BulkResubmitStatus;
  numProcessing: number;
  numWorkxRejects: number;
  data: BulkJob;
};

export const PVR_ALL_VAL = "*";
