export const PVR_FIELD_PREFIX = "PVR_";

export enum PVR_CONFIGURATION_ENTITY {
  id = "id",
  isAllMpid = "isAllMpid",
  mpid = "mpid",
  email = "email",
  emailId = "emailId",
  creator = "creator",
  version = "version",
  modifiedBy = "modifiedBy",
  lastUpdate = "lastUpdate",
  enabled = "enabled",
  logActive = "logActive",
  emailActive = "emailActive",
  porStatusAlertActive = "porStatusAlertActive",
  pvResubmitAlertActive = "pvResubmitAlertActive",
  pvWindowAlertActive = "pvWindowAlertActive",
  popupActive = "popupActive",
  new = "new",
  includeWorkXUserRecipient = "includeWorkXUserRecipient",
}

export type PvrConfigurationEntity = {
  [PVR_CONFIGURATION_ENTITY.id]: string;
  [PVR_CONFIGURATION_ENTITY.mpid]: string;
  [PVR_CONFIGURATION_ENTITY.email]: any;
  [PVR_CONFIGURATION_ENTITY.emailId]: string;
  [PVR_CONFIGURATION_ENTITY.creator]: any;
  [PVR_CONFIGURATION_ENTITY.version]: number;
  [PVR_CONFIGURATION_ENTITY.modifiedBy]: string;
  [PVR_CONFIGURATION_ENTITY.lastUpdate]: string;
  [PVR_CONFIGURATION_ENTITY.enabled]: boolean;
  [PVR_CONFIGURATION_ENTITY.logActive]: boolean;
  [PVR_CONFIGURATION_ENTITY.emailActive]: boolean;
  [PVR_CONFIGURATION_ENTITY.porStatusAlertActive]: boolean;
  [PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive]: boolean;
  [PVR_CONFIGURATION_ENTITY.pvWindowAlertActive]: boolean;
  [PVR_CONFIGURATION_ENTITY.popupActive]: boolean;
  [PVR_CONFIGURATION_ENTITY.new]: boolean;
  [PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient]: boolean;
};

export enum PVR_VALIDATION_RESULT {
  errors = "errors",
  hasError = "hasError",
  hasWarning = "hasWarning",
  warnings = "warnings",
  entities = "entities",
  global = "global",
}

export type PvrValidationMap = { [id: string]: { [field: string]: string[] } };
export type PvrValidationResult = {
  errors: PvrValidationMap;
  hasError: boolean;
  hasWarning: boolean;
  warnings: PvrValidationMap;
  entities: PvrConfigurationEntity[];
};

export enum PVR_ALERT_TYPE {
  POR_STATUS = 1,
  POR_WINDOW = 2,
  PV_RESUBMIT = 3,
}

export const PVR_ALERT_TYPE_LABEL = {
  [PVR_ALERT_TYPE.POR_STATUS]: "POR Status Alert",
  [PVR_ALERT_TYPE.POR_WINDOW]: "POR Time Window Alert",
  [PVR_ALERT_TYPE.PV_RESUBMIT]: "PV Resubmit Alert",
};
