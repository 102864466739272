import { produce } from "immer";
import React, { createContext, memo, useContext, useReducer } from "react";

export enum AllOrPending {
  PENDING = "Pending",
  ALL = "All",
}

export type All_Or_Pending = `${AllOrPending}`;

export enum MpidOrSymbol {
  MPID = "MPID",
  SECURITY = "Symbol",
}
export type Mpid_Or_Security = `${MpidOrSymbol}`;

export enum PorOrPvr {
  POR = "POR",
  PVR = "PVR",
}
export type Por_Or_Pvr = `${PorOrPvr}`;

type PvrOrPor = "POR" | "PVR";
type FlipCard = {
  allOrPending: All_Or_Pending;
  mpidOrSymbol: Mpid_Or_Security;
  total: number;
  pending?: number;
  tableData: any[];
};

export type PORCacheAction =
  | {
      type: "SET_OTHER_CHART_DATA";
      payload: { others: any[] };
    }
  | { type: "SET_SELECTED"; payload: { selected: string } }
  | {
      type: "SET_ALL_OR_PENDING";
      payload: { allOrPending: AllOrPending; view: PvrOrPor };
    }
  | { type: "SET_MPID_OR_SECURITY"; payload: { MpidOrSymbol: MpidOrSymbol; view: PvrOrPor } }
  | { type: "SET_REQUEST_TABLE"; payload: { count: number; id: string } };

type PORCacheState = {
  [PorOrPvr.POR]: FlipCard;
  [PorOrPvr.PVR]: FlipCard;
  porTable: {};
  rejectsTable: {};
  hasSelected: boolean;
  selected: string;
};

const defaultPORCacheState: PORCacheState = {
  [PorOrPvr.POR]: {
    allOrPending: AllOrPending.PENDING,
    mpidOrSymbol: MpidOrSymbol.MPID,
    total: 0,
    pending: 0,
    tableData: [],
  },
  [PorOrPvr.PVR]: {
    allOrPending: AllOrPending.PENDING,
    mpidOrSymbol: MpidOrSymbol.MPID,
    total: 0,
    tableData: [],
  },
  porTable: {},
  rejectsTable: {},
  hasSelected: false,
  selected: "",
};

const DispatchFn = (
  state: PORCacheState,
  actions: PORCacheAction | PORCacheAction[]
): PORCacheState => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }

  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), {
    ...state,
  });
};

const DispatchFnSwitch = (state: PORCacheState, action: PORCacheAction): PORCacheState => {
  switch (action.type) {
    case "SET_SELECTED": {
      const { selected } = action.payload;
      return produce(state, draft => {
        draft.hasSelected = true;
        draft.selected = selected === "All" ? "" : selected;
      });
    }
    case "SET_ALL_OR_PENDING": {
      const { view, allOrPending } = action.payload;

      return produce(state, draft => {
        draft[view].allOrPending = allOrPending;
      });
    }
    case "SET_MPID_OR_SECURITY": {
      const { view, MpidOrSymbol } = action.payload;

      return produce(state, draft => {
        draft[view].mpidOrSymbol = MpidOrSymbol;
      });
    }
    default:
      return produce(state, draft => {});
  }
};

const PORCacheDispatch = createContext<React.Dispatch<PORCacheAction | PORCacheAction[]>>(
  () => null
);
PORCacheDispatch.displayName = "PORCacheDispatch";
export const usePORCacheDispatch = () => useContext(PORCacheDispatch);

const PORCacheContext = createContext<{
  state: PORCacheState;
  dispatch: React.Dispatch<PORCacheAction | PORCacheAction[]>;
}>({ state: defaultPORCacheState, dispatch: () => null });

PORCacheContext.displayName = "PORCacheContext";
export const usePORCacheContext = () => useContext(PORCacheContext);

const PORCacheProvider = ({
  defaultData,
  children,
}: {
  defaultData?: PORCacheState;
  children: React.ReactNode;
}) => {
  const [state, dispatchF] = useReducer(
    DispatchFn,
    Object.assign({}, defaultPORCacheState, defaultData)
  );

  return (
    <>
      <PORCacheDispatch.Provider value={dispatchF}>
        <PORCacheContext.Provider value={{ state, dispatch: dispatchF }}>
          {children}
        </PORCacheContext.Provider>
      </PORCacheDispatch.Provider>
    </>
  );
};

export const PORProvider = memo(PORCacheProvider);
