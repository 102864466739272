import { AlertForm, Divider } from "../constants";
import { PvrWorkXDeliveryOptions } from "./workxDeliveryOptions";
import { Forms } from "components/fields";
import { PvrEmailDeliveryOptions } from "./emailDeliveryOptions";
import { PvrAlertTypes } from "./alertTypes";
import { PvrMPIDOptions } from "./mpids";
import { KeycloakRoles, useUserContext } from "components/user";

export const PvrAlertForm = () => {
  const [userData] = useUserContext();

  return (
    <AlertForm>
      <PvrMPIDOptions
        form={Forms.PVR_CONFIG}
        enableAllMpid={userData.entitlements[KeycloakRoles.PVR_ALERTS_FINRA]}
      />
      <Divider />
      <PvrAlertTypes form={Forms.PVR_CONFIG} />
      <Divider />
      <PvrEmailDeliveryOptions form={Forms.PVR_CONFIG} />
      <Divider />
      <PvrWorkXDeliveryOptions form={Forms.PVR_CONFIG} />
    </AlertForm>
  );
};
