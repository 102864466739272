import { CSSGrid } from "@nef/core";
import { Middle } from "components/middle";
import { EffectCallback, useEffect, useState } from "react";
import { useLayoutContext } from "components/context";
import { RejectsTable } from "./tables/rejects.table";
import { PorTable } from "./tables/por.table";
import { PORs } from "./tiles/por";
import { PVRs } from "./tiles/pvr";
import { PORProvider, PorOrPvr, usePORCacheContext } from "./context";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch, usePorCacheState } from "../cache/porCache";
import { useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { REJECT_COUNT_FILTER_BY, useRejectCountDispatch } from "../cache/rejectCountCache";
import { usePorCountDispatch } from "../cache/porCountCache";
import { TradeDetailRecap, useRecapContext } from "components/tradeDetailRecap";
import { useRejectCacheDispatch } from "../cache/rejectCache";

export const Monitor = () => {
  const [, layoutDispatch] = useLayoutContext();
  const { state } = usePORCacheContext();
  const porCacheState = usePorCacheState();
  const porCacheDispatch = usePorCacheDispatch();
  const rejectCountDispatch = useRejectCountDispatch();
  const porCountDispatch = usePorCountDispatch();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const tableDispatch = useStandardTableDispatch();
  const [recapData] = useRecapContext();

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const [title, setTitle] = useState("");

  useEffect(() => {
    const pendingOrAll = `${state[PorOrPvr.POR].allOrPending}`;
    if (porCacheState.keys.length === 1) {
      setTitle(`${pendingOrAll} POR for ${porCacheState.keys[0]}`);
    } else if (porCacheState.exclude?.keys && porCacheState.exclude?.keys?.length > 0) {
      setTitle(`${pendingOrAll} POR for Other`);
    } else {
      setTitle(`${pendingOrAll} POR`);
    }
  }, [state, porCacheState]);

  useEffect(() => {
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
    });
    porCacheDispatch({
      type: "START_POLLING",
      payload: {
        filterBy: POR_CACHE_FILTER_BY.ALL,
      },
    });
    porCountDispatch({
      type: "START_POLLING",
    });
    rejectCountDispatch({
      type: "START_POLLING",
      payload: {
        filter: {
          filterBy: REJECT_COUNT_FILTER_BY.FINRA_COUNTS,
        },
      },
    });
    return () => {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
      porCountDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
      rejectCacheDispatch({
        type: "RESET_CACHE",
      });
      tableDispatch([
        {
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.PV_SUPERVISOR_MONITOR,
            data: [],
          },
        },
        {
          type: "DESELECT_ALL_ROWS",
          payload: {
            table: StandardTables.PV_SUPERVISOR_MONITOR,
          },
        },
        {
          type: "DESELECT_ALL_ROWS",
          payload: {
            table: StandardTables.PV_SUPERVISOR_MONITOR2,
          },
        },
      ]);
    };
  }, [porCacheDispatch, porCountDispatch, rejectCountDispatch, tableDispatch, rejectCacheDispatch]);

  useEffect(
    () => () =>
      porCacheDispatch({
        type: "STOP_POLLING",
      }),
    [porCacheDispatch]
  );

  useEffect(() => {
    if (title.indexOf("Pending") !== -1) {
      tableDispatch({
        type: "SET_TABLE_DATA",
        payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2, data: porCacheState.pending },
      });
    } else {
      tableDispatch({
        type: "SET_TABLE_DATA",
        payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2, data: porCacheState.data },
      });
    }
  }, [porCacheState.data, porCacheState.pending, tableDispatch, title]);

  return (
    <Middle>
      <CSSGrid
        gap={"1rem"}
        rows={"40% calc(60% - 1rem)"}
        cols={"calc(50% - 1rem) calc(50% - 1rem)"}
      >
        <PORs setTitle={setTitle} />
        <PVRs setTitle={setTitle} />
        <PorTable title={title} />
        <RejectsTable isAllOrPending={state[PorOrPvr.POR].allOrPending} selected={state.selected} />
      </CSSGrid>
      <TradeDetailRecap widthFactor={recapData.trades.length} />
    </Middle>
  );
};

export const MonitorWrapper = () => {
  return (
    <PORProvider>
      <Monitor></Monitor>
    </PORProvider>
  );
};
