import { DataArray, FormField, Loader } from "@nef/core";
import { FlipCard } from "components/flipCard";
import React, { useCallback, useMemo, useState } from "react";
import { useRejectCountState } from "../cache/rejectCountCache";
import { ArraySection, ArrayWrapper, DataArrayCount, filterForDataArray } from "../constant";
import { LoaderWrapper, NoRejectsFillerJsx } from "./constant";
import { RejectsBySymbolBarChart } from "./rejectsBySymbolBarChart";
import { RejectsFlipCardSection } from "./rejectsFlipCardSection";

type RejectsBySymbolProps = {
  totalRejectCount: number;
  dataArrayCounts: DataArrayCount[];
};
export const RejectsBySymbol: React.FC<RejectsBySymbolProps> = ({
  totalRejectCount,
  dataArrayCounts,
}) => {
  const rejectCountState = useRejectCountState();
  const [isFlipped, setFlipped] = useState(false);
  const [filterSymbol, setFilterSymbol] = useState("");

  const toggleFlipped = useCallback(() => {
    setFlipped(!isFlipped);
  }, [isFlipped]);

  const setSymbolFilter = useCallback((e: any) => {
    setFilterSymbol(e.value);
  }, []);

  const RejectDataArray = useMemo(() => {
    if (totalRejectCount > 0) {
      let filteredCounts = filterForDataArray(dataArrayCounts, filterSymbol);
      return (
        <>
          <FormField
            value={filterSymbol}
            onChange={setSymbolFilter}
            size={"sm"}
            placeholder="Symbol"
          />
          <ArrayWrapper>
            <DataArray data={filteredCounts} expandable={true} boldValues={false} />
          </ArrayWrapper>
        </>
      );
    }
    return <React.Fragment></React.Fragment>;
  }, [totalRejectCount, dataArrayCounts, filterSymbol, setSymbolFilter]);

  return (
    <ArraySection>
      <LoaderWrapper>
        <Loader isLoading={rejectCountState.isLoading}>
          {totalRejectCount === 0 ? NoRejectsFillerJsx : <></>}
          <FlipCard
            isFlipped={isFlipped}
            front={
              <RejectsFlipCardSection
                totalRejectCount={totalRejectCount}
                toggleFlipped={toggleFlipped}
                cacheStatus={rejectCountState.status}
              >
                {RejectDataArray}
              </RejectsFlipCardSection>
            }
            back={
              <RejectsFlipCardSection
                totalRejectCount={totalRejectCount}
                toggleFlipped={toggleFlipped}
                cacheStatus={rejectCountState.status}
              >
                <RejectsBySymbolBarChart />
              </RejectsFlipCardSection>
            }
          />
        </Loader>
      </LoaderWrapper>
    </ArraySection>
  );
};
