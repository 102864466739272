import { doFetchWrapper } from "network";
import { getHeaders } from "keycloak";
import { formatUrl } from "utils/js.utils";
import { NotificationHub } from "@nef/core";
import { SubtitleList } from "components/notification";

export const handleSaveNewPvrEmail = ({ baseUrl, emailDispatch, onDone, newEmail }) => {
  const onSuccess = () => {
    NotificationHub.send("success", "Created email");
    emailDispatch({
      type: "START_REFRESH",
    });
    if (typeof onDone === "function") {
      onDone();
    }
  };
  const onError = json => {
    const messages = [];
    if (json?.hasError) {
      Object.values(json.errors).forEach(errorMap => {
        Object.values(errorMap).forEach(msgList =>
          msgList.forEach(msg => {
            messages.push(msg);
          })
        );
      });
    }
    NotificationHub.send("danger", "Error creating email", {
      subtitle: <SubtitleList messages={messages} />,
    });
    if (typeof onDone === "function") {
      onDone();
    }
  };
  doFetchWrapper(
    formatUrl(baseUrl, "alert/email/create"),
    {
      method: "post",
      headers: getHeaders(),
      body: JSON.stringify(newEmail),
    },
    onSuccess,
    onError
  );
};
