import React, { useContext, useEffect, createContext, useReducer, ReactNode } from "react";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { formatUrl, addPrefixToField } from "utils/js.utils";
import { useCallback } from "react";
import { NotificationHub } from "@nef/core";
import { SelectOptions } from "components/fields";
import { PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY } from "../fields";
import { useUserContext } from "../../../user";
import { Views } from "viewConstants";
import { SelectOption } from "types";
import { PvrEmail, PVR_EMAIL_STATUS } from "./constant";

const pvrEmailDispatch = createContext<React.Dispatch<PvrEmailAction> | undefined>(undefined);
pvrEmailDispatch.displayName = "PvrEmailDispatch";
export const usePvrEmailDispatch = () => {
  const context = useContext(pvrEmailDispatch);
  if (context === undefined) {
    throw new Error("usePvrEmailDispatch must be used within a PvrEmailProvider");
  }
  return context;
};

const pvrEmailContext = createContext<[PvrEmailState, React.Dispatch<PvrEmailAction>] | undefined>(
  undefined
);
pvrEmailContext.displayName = "EmailContext";
export const usePvrEmailContext = () => {
  const context = useContext(pvrEmailContext);
  if (context === undefined) {
    throw new Error("usePvrEmailContext must be used within a PvrEmailProvider");
  }
  return context;
};

type PvrEmailState = {
  emails: PvrEmail[];
  isLoading: boolean;
  userId: string | null;
  refreshCount: number;
};

type PvrEmailAction =
  | { type: "START_REFRESH" }
  | { type: "FINISH_REFRESH"; payload: PvrEmail[] }
  | { type: "REFRESH_ERROR" }
  | { type: "SET_USER_ID"; payload: string };

const defaultState: PvrEmailState = {
  emails: [],
  isLoading: true,
  userId: null,
  refreshCount: 0,
};

const DispatchFn = (
  state: PvrEmailState,
  actions: PvrEmailAction | PvrEmailAction[]
): PvrEmailState => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state: PvrEmailState, action: PvrEmailAction): PvrEmailState => {
  switch (action.type) {
    case "START_REFRESH": {
      return { ...state, isLoading: true };
    }
    case "FINISH_REFRESH": {
      const emails = action.payload;
      const emailOptions = emails.reduce((acc, curr: PvrEmail) => {
        if (curr.status === PVR_EMAIL_STATUS.Enabled) {
          acc.push({
            value: curr.id,
            label: curr.recipientEmail,
          });
        }
        return acc;
      }, [] as SelectOption[]);
      SelectOptions[addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.emailId)] = () =>
        emailOptions;
      return { ...state, emails, isLoading: false, refreshCount: state.refreshCount + 1 };
    }
    case "REFRESH_ERROR": {
      NotificationHub.send(
        "danger",
        "An error occurred getting emails for Price Reject Override (PRO) Alerts"
      );
      return { ...state, isLoading: false };
    }
    case "SET_USER_ID": {
      return { ...state, userId: action.payload };
    }
    default:
      return { ...state };
  }
};

interface PvrEmailContextProps {
  children: ReactNode;
  defaultData?: Partial<PvrEmailState>;
  userId: string;
}

const PvrEmailContext: React.FC<PvrEmailContextProps> = ({ children, defaultData, userId }) => {
  const [state, dispatchF] = useReducer(DispatchFn, { ...defaultState, ...defaultData });
  const [user] = useUserContext();

  useEffect(() => {
    dispatchF({
      type: "SET_USER_ID",
      payload: userId,
    });
  }, [userId]);

  const getEmailsCallback = useCallback((data: PvrEmail[]) => {
    dispatchF({
      type: "FINISH_REFRESH",
      payload: data,
    });
  }, []);

  const getEmailsError = useCallback(() => {
    dispatchF({
      type: "REFRESH_ERROR",
    });
  }, []);

  useEffect(() => {
    if (state.isLoading && user?.allowed.views[Views.PVR_EMAILS]) {
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "/alert/email/userEmails"),
        {
          method: "GET",
          mode: "cors",
          headers: getHeaders(),
        },
        getEmailsCallback,
        getEmailsError
      );
    }
  }, [getEmailsCallback, getEmailsError, state.isLoading, user]);

  return (
    <pvrEmailDispatch.Provider value={dispatchF}>
      <pvrEmailContext.Provider value={[state, dispatchF]}>{children}</pvrEmailContext.Provider>
    </pvrEmailDispatch.Provider>
  );
};

export default PvrEmailContext;
