import { Button } from "@nef/core";
import { eqrcMpidPortExchangeFields, eqrcMpidPortFields, FieldLoop } from "components/fields";
import { useFormContext, useFormDispatch } from "components/form";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { addPrefixToField } from "utils/js.utils";
import { EqrcInputContextState, EXCHANGES, useEqrcInputContext } from "./eqrcInputContext";
import { EqrcFields, EQRC_FIELD_PREFIX } from "../constants";
import styled from "styled-components";
import { Form, SelectOption } from "types";
import { SelectOptionAction, useSelectOptionDispatch } from "components/fields/optionContext";

const StyledButton = styled(Button)`
  grid-column: span 2;
  margin-bottom: -7px !important;
`;

interface EqrcExchangeMpidPortProps {
  form: Form;
  btnProps?: ButtonComponentProps;
  ButtonComponent?: typeof Button;
  children?: React.ReactNode;
  hideExchange?: boolean;
  hideClearButton?: boolean;
  onClear?: () => void;
  isDisabled?: boolean;
}

const addPortsFromExchangeAndMpid = (
  portsToUpdate: { [key: string]: SelectOption },
  inputData: EqrcInputContextState,
  exchangeValue: string | null,
  mpidValue: string
) => {
  let exchanges: { [key: string]: string } = EXCHANGES;
  if (exchangeValue !== null) {
    exchanges = { exchange: exchangeValue };
  }
  Object.values(exchanges).forEach(exchange => {
    Object.entries(inputData.mpids[mpidValue].ports).forEach(([key, option]) => {
      if (inputData.exchanges[exchange].ports.hasOwnProperty(key)) {
        portsToUpdate[key] = option;
      }
    });
  });
  return portsToUpdate;
};

interface ButtonComponentProps {
  color: string;
  size: string;
  outline: boolean;
}

const defaultBtnProps = {
  color: "secondary",
  size: "sm",
  outline: false,
};

export const EqrcExchangeMpidPort: React.FC<EqrcExchangeMpidPortProps> = ({
  children,
  form,
  btnProps = defaultBtnProps,
  ButtonComponent = StyledButton,
  hideExchange = false,
  hideClearButton = false,
  isDisabled = false,
  onClear,
}) => {
  const [inputData] = useEqrcInputContext();
  const [formData]: [{ [key: string]: any }] = useFormContext();
  const formDispatch = useFormDispatch();
  const fieldOptionsDispatch = useSelectOptionDispatch();
  const prevOptions = useRef<{
    exchange: undefined | string;
    mpid: undefined | string;
    port: undefined | string;
  }>({ exchange: undefined, mpid: undefined, port: undefined });

  useEffect(() => {
    const exchangeValue = formData[form.key].fields[EqrcFields.exchange]?.value;
    const optionsDispatches: SelectOptionAction[] = [];

    let mpidValue = formData[form.key].fields[EqrcFields.mpid]?.value;
    if (Array.isArray(formData[form.key].fields[EqrcFields.mpid])) {
      if (formData[form.key].fields[EqrcFields.mpid].length === 1) {
        mpidValue = formData[form.key].fields[EqrcFields.mpid][0].value;
      } else {
        mpidValue = undefined;
      }
    }

    let portValue: string | SelectOption[];
    if (Array.isArray(formData[form.key].fields[EqrcFields.port])) {
      portValue = formData[form.key].fields[EqrcFields.port];
    } else {
      portValue = formData[form.key].fields[EqrcFields.port]?.value;
    }

    let exchange: any[] = [];
    let mpid: any[] = [];
    let port: any[] = [];
    if (Array.isArray(portValue) && portValue.length > 0) {
      // if ports is multi-select then all available ports will
      // be related to the selected exchange+mpid
      portValue = portValue[0].value as string;
    }

    if (portValue) {
      exchange = Object.values(inputData?.ports?.[portValue as string]?.exchanges || {});
      mpid = Object.values(inputData?.ports?.[portValue as string]?.mpids || {});

      if (mpidValue) {
        port = Object.values(
          addPortsFromExchangeAndMpid(
            {},
            inputData,
            exchangeValue ? exchangeValue : null,
            mpidValue as string
          )
        );
      } else {
        const innerPorts = Object.values(
          inputData.ports?.[portValue as string]?.mpids || {}
        ).reduce((acc, mpidOption) => {
          addPortsFromExchangeAndMpid(
            acc,
            inputData,
            exchangeValue ? exchangeValue : null,
            mpidOption.value as string
          );
          return acc;
        }, {} as { [key: string]: SelectOption });
        port = Object.values(innerPorts);
      }
    } else {
      if (mpidValue) {
        exchange = Object.values(inputData.mpids?.[mpidValue]?.exchanges || {});
      } else {
        exchange = Object.values(inputData.allExchangeOptions);
      }

      if (exchangeValue) {
        mpid = Object.values(inputData.exchanges?.[exchangeValue]?.mpids || {});
      } else {
        mpid = Object.values(inputData.allMpidOptions);
      }

      if (mpidValue && exchangeValue) {
        port = Object.values(addPortsFromExchangeAndMpid({}, inputData, exchangeValue, mpidValue));
      } else if (mpidValue) {
        port = Object.values(inputData.mpids?.[mpidValue]?.ports || {});
      } else if (exchangeValue) {
        port = Object.values(inputData.exchanges?.[exchangeValue]?.ports || {});
      } else {
        port = Object.values(inputData.allPortOptions);
      }
    }

    if (prevOptions.current.exchange !== JSON.stringify(exchange)) {
      prevOptions.current.exchange = JSON.stringify(exchange);
      optionsDispatches.push({
        type: "SET_OPTIONS",
        payload: {
          form: form.id,
          fieldName: `${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange)}`,
          options: exchange,
        },
      });
    }

    if (prevOptions.current.mpid !== JSON.stringify(mpid)) {
      prevOptions.current.mpid = JSON.stringify(mpid);
      optionsDispatches.push({
        type: "SET_OPTIONS",
        payload: {
          form: form.id,
          fieldName: `${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid)}`,
          options: mpid,
        },
      });
    }

    if (prevOptions.current.port !== JSON.stringify(port)) {
      prevOptions.current.port = JSON.stringify(port);
      optionsDispatches.push({
        type: "SET_OPTIONS",
        payload: {
          form: form.id,
          fieldName: `${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port)}`,
          options: port,
        },
      });
    }

    fieldOptionsDispatch(optionsDispatches);
  }, [inputData, formData, form, fieldOptionsDispatch]);

  const clearToValue = useMemo(() => {
    return undefined;
  }, []);

  const resetFields = useCallback(() => {
    if (onClear) {
      onClear();
    } else {
      formDispatch([
        {
          type: "SET_FORM_VALUES",
          payload: {
            form,
            fields: {
              [EqrcFields.exchange]: undefined,
              [EqrcFields.mpid]: clearToValue,
              [EqrcFields.port]: clearToValue,
            },
          },
        },
        {
          type: "INIT_FORM_VALIDATION",
          payload: { form },
        },
      ]);
    }
  }, [clearToValue, form, formDispatch, onClear]);

  const fields = useMemo(() => {
    if (hideExchange) {
      return eqrcMpidPortFields;
    } else {
      return eqrcMpidPortExchangeFields;
    }
  }, [hideExchange]);

  return (
    <>
      <FieldLoop
        form={form}
        fields={fields}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isReactFragment={true}
        isDisabled={isDisabled}
        containerRef={undefined}
        showLabel={undefined}
      />
      {children}
      {hideClearButton ? (
        <></>
      ) : (
        <ButtonComponent
          type="button"
          {...btnProps}
          onClick={resetFields}
          block
          disabled={isDisabled}
        >
          Clear
        </ButtonComponent>
      )}
    </>
  );
};
