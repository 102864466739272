import {
  FieldLoop,
  riskAlertFieldsAguMPID,
  riskAlertFieldsAguCorrespondentMPIDs,
  FORM_KEY,
} from "components/fields";
import { PtraFieldSection, SectionHeader } from "../constants";
import React, { useEffect, useMemo, useState } from "react";
import {
  SelectOptionAction,
  useSelectOptionContext,
  useSelectOptionDispatch,
} from "components/fields/optionContext";
import { useFormContext, useFormDispatch } from "components/form";
import { PTRA_AGU_ENTITY, PTRA_FIELD_PREFIX } from "../fields";
import { SelectOption } from "types";
import { addPrefixToField, formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { ClearingRelationship } from "components/user/mpidContext";
import { RiskStatus } from "wksConstants";
import { NotificationHub } from "@nef/core";

const emptyOptions: SelectOption[] = [];
export const PtrAguMPIDOptions: React.FC<PtraFieldSection> = ({ form }) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [{ optionsCache }] = useSelectOptionContext();
  const optionDispatch = useSelectOptionDispatch();
  const [lastAguMpid, setLastAguMpid] = useState(null);

  const executingMPID = useMemo(() => {
    return formData[form.key].fields[PTRA_AGU_ENTITY.executingMPID]?.value;
  }, [form.key, formData]);

  const aguMPID = useMemo(() => {
    return formData[form.key].fields[PTRA_AGU_ENTITY.participantMPID]?.value;
  }, [form.key, formData]);

  useEffect(() => {
    formDispatch({
      type: "UPDATE_FORM_VALUE",
      payload: {
        id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
        form,
        field: PTRA_AGU_ENTITY.participantMPID,
        value: emptyOptions,
      },
    });
  }, [executingMPID, form, formDispatch]);

  useEffect(() => {
    if (executingMPID !== lastAguMpid || (executingMPID === undefined && aguMPID === undefined)) {
      const getOptions = async () => {
        // fetch options
        let options: SelectOption[] = [];
        const optionCacheKey = `${PTRA_AGU_ENTITY.participantMPID}|${executingMPID}`;
        if (optionsCache[form.id as FORM_KEY]?.[optionCacheKey] || executingMPID === undefined) {
          optionDispatch([
            {
              type: "SET_OPTIONS",
              payload: {
                form: form.id,
                fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
                options: optionsCache[form.id as FORM_KEY]?.[optionCacheKey] || [],
              },
            },
            {
              type: "REMOVE_FROM_DISABLED_MAP",
              payload: {
                fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
                form: form.id,
              },
            },
          ]);

          return;
        }

        optionDispatch({
          type: "ADD_TO_LOADING_MAP",
          payload: {
            fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
            form: form.id,
          },
        });

        const responseOptionActions: SelectOptionAction[] = [
          {
            type: "REMOVE_FROM_LOADING_MAP",
            payload: {
              fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
              form: form.id,
            },
          },
        ];

        const response = await fetch(
          formatUrl(process.env.REACT_APP_URL_QUERY_WS, "getAguClrCorrMPIDs"),
          {
            method: "POST",
            headers: getHeaders(),
            body: JSON.stringify([executingMPID]),
          }
        );

        if (response.ok) {
          const json: ClearingRelationship[] = await response.json();
          const clearerToOptions: { [clearer: string]: SelectOption } = {};
          json.forEach(relationship => {
            if ([RiskStatus.ACTIVE, RiskStatus.TRANSITIONAL].includes(relationship.riskStatus)) {
              if (clearerToOptions[relationship.clearingMPID] === undefined) {
                clearerToOptions[relationship.clearingMPID] = {
                  id: relationship.clearingMPID,
                  label: `${relationship.clearingMPID} (Clearing)`,
                  value: relationship.clearingMPID,
                  children: [],
                };
              }
              (clearerToOptions[relationship.clearingMPID].children as SelectOption[]).push({
                id: relationship.correspondentMPID,
                label: relationship.correspondentMPID,
                value: JSON.stringify(relationship),
              });
            }
          });
          options = Object.values(clearerToOptions);

          responseOptionActions.push({
            type: "ADD_TO_OPTIONS_CACHE",
            payload: { form: form.id, key: optionCacheKey, options: options },
          });

          responseOptionActions.push({
            type: "SET_OPTIONS",
            payload: {
              form: form.id,
              fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
              options: options,
            },
          });

          responseOptionActions.push({
            type: "REMOVE_FROM_DISABLED_MAP",
            payload: {
              fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
              form: form.id,
            },
          });
        } else {
          NotificationHub.send("danger", "Unable to retrieve MPID relationships");
          responseOptionActions.push({
            type: "ADD_TO_DISABLED_MAP",
            payload: {
              fieldName: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
              form: form.id,
            },
          });
        }
        optionDispatch(responseOptionActions);
        return options;
      };

      getOptions();
      setLastAguMpid(executingMPID);
    }
  }, [executingMPID, form, lastAguMpid, optionsCache, optionDispatch, formDispatch, aguMPID]);

  return (
    <section>
      <SectionHeader>MPID Options</SectionHeader>
      <FieldLoop
        isReactFragment={true}
        form={form}
        fields={riskAlertFieldsAguMPID}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
      <FieldLoop
        isReactFragment={true}
        form={form}
        fields={riskAlertFieldsAguCorrespondentMPIDs}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
    </section>
  );
};
