import { ApiResponseNames, Forms, REJECT_DATE_OPTIONS } from "components/fields";
import { useFormContext } from "components/form";
import { Middle } from "components/middle";
import {
  DataArrayCount,
  DATE_FILTER,
  PV_REJECT_LABELS,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  REJ_PRICE_OO_RANGE,
} from "components/pvRejects/constant";
import { TradeDetailRecap, useRecapContext } from "components/tradeDetailRecap";
import React, { EffectCallback, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { SelectOption } from "types";
import { useLayoutContext } from "../../context";
import { REJECT_CACHE_FILTER_BY, useRejectCacheDispatch } from "../cache/rejectCache";
import { useRejectCountState } from "../cache/rejectCountCache";
import { ArrayButton } from "../constant";
import { PorWindowTimer } from "../porWindowTimer";
import { PieChartData, PV_REJECT_TOP, PVR_ALL_VAL } from "./constant";
import { PvClientPorTable } from "./porTable";
import { ProMonitorRejectPieChart } from "./rejectPieChart";
import { RejectsBySymbol } from "./rejectsBySymbol";
import { PvClientRejectTable } from "./rejectTable";
import { PorClientMonitorInputs } from "./topInputs";

const CHART_HEIGHT_PX = 300;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  height: 100%;
  grid-template-rows: 7px calc(23px - 1rem) ${CHART_HEIGHT_PX}px calc(
      100% - ${CHART_HEIGHT_PX}px - 30px - 2rem
    );
`;

const TimerWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 1 / 2;
`;

const emptyMpids: SelectOption[] = [];

export const PvRejectMonitor: React.FC = () => {
  const [, layoutDispatch] = useLayoutContext();
  const [formData] = useFormContext();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const rejectCountState = useRejectCountState();
  const [recapData] = useRecapContext();

  const selectedMpids: SelectOption[] = useMemo(() => {
    if (Array.isArray(formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[ApiResponseNames.mpid])) {
      return formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[ApiResponseNames.mpid];
    }
    return emptyMpids;
  }, [formData]);

  const selectedDateFilter: SelectOption = useMemo(() => {
    if (formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate]) {
      return formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate];
    }
    return REJECT_DATE_OPTIONS[DATE_FILTER.ALL];
  }, [formData]);

  const handleSetFilter = useCallback(
    ({ symbol, reject }: { symbol?: string; reject: string }) =>
      () => {
        if (Array.isArray(selectedMpids) && selectedDateFilter?.value) {
          rejectCacheDispatch({
            type: "START_POLLING",
            payload: {
              filter: {
                filterBy: REJECT_CACHE_FILTER_BY.MPID_SYMBOL,
                criteria: {
                  mpidToSymbols: selectedMpids.reduce((acc, mpid) => {
                    acc[mpid.label] = [symbol];
                    return acc;
                  }, {} as any),
                  rejectText: reject,
                  date: selectedDateFilter.value as DATE_FILTER,
                },
              },
            },
          });
        }
      },
    [rejectCacheDispatch, selectedDateFilter.value, selectedMpids]
  );

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const { dataArrayCounts, pieChartData, totalRejectCount } = useMemo(() => {
    const { dataArrayCounts, pieChartData, totalRejectCount, totalPriceCount, totalOverrideCount } =
      Object.entries(rejectCountState.counts).reduce(
        (acc, [symbol, count]) => {
          const subRows = [];
          if (count.priceCount > 0) {
            subRows.push({
              label: PV_REJECT_LABELS[REJ_PRICE_OO_RANGE],
              value: (
                <ArrayButton onClick={handleSetFilter({ symbol, reject: REJ_PRICE_OO_RANGE })}>
                  {count.priceCount}
                </ArrayButton>
              ),
              count: count.priceCount,
            });
          }
          if (count.priceOverrideCount > 0) {
            subRows.push({
              label: PV_REJECT_LABELS[REJ_PRICE_OO_OVERRIDE_RANGE],
              value: (
                <ArrayButton
                  onClick={handleSetFilter({ symbol, reject: REJ_PRICE_OO_OVERRIDE_RANGE })}
                >
                  {count.priceOverrideCount}
                </ArrayButton>
              ),
              count: count.priceOverrideCount,
            });
          }
          acc.dataArrayCounts.push({
            label: symbol,
            value: count.priceCount + count.priceOverrideCount,
            subRows,
          });
          acc.pieChartData[0].value += count.priceCount;
          acc.pieChartData[1].value += count.priceOverrideCount;
          acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
          acc.totalPriceCount += count.priceCount;
          acc.totalOverrideCount += count.priceOverrideCount;
          return acc;
        },
        {
          dataArrayCounts: [],
          pieChartData: [
            { name: REJ_PRICE_OO_RANGE, value: 0 },
            { name: REJ_PRICE_OO_OVERRIDE_RANGE, value: 0 },
          ],
          totalRejectCount: 0,
          totalPriceCount: 0,
          totalOverrideCount: 0,
        } as {
          dataArrayCounts: DataArrayCount[];
          pieChartData: PieChartData;
          totalRejectCount: number;
          totalPriceCount: number;
          totalOverrideCount: number;
        }
      );
    dataArrayCounts.push({
      label: "<ALL>",
      value: totalRejectCount,
      subRows: [
        {
          label: PV_REJECT_LABELS[REJ_PRICE_OO_RANGE],
          value: (
            <ArrayButton
              onClick={handleSetFilter({ symbol: PVR_ALL_VAL, reject: REJ_PRICE_OO_RANGE })}
            >
              {totalPriceCount}
            </ArrayButton>
          ),
          count: totalPriceCount,
        },
        {
          label: PV_REJECT_LABELS[REJ_PRICE_OO_OVERRIDE_RANGE],
          value: (
            <ArrayButton
              onClick={handleSetFilter({
                symbol: PVR_ALL_VAL,
                reject: REJ_PRICE_OO_OVERRIDE_RANGE,
              })}
            >
              {totalOverrideCount}
            </ArrayButton>
          ),
          count: totalOverrideCount,
        },
      ],
    });
    dataArrayCounts.sort((a: DataArrayCount, b: DataArrayCount) => {
      if (a.label === "<ALL>") {
        return -1;
      } else if (b.label === "<ALL>") {
        return 1;
      }
      return b.value - a.value;
    });
    return {
      dataArrayCounts,
      pieChartData,
      totalRejectCount,
      totalPriceCount,
      totalOverrideCount,
    };
  }, [handleSetFilter, rejectCountState.counts]);

  return (
    <Middle>
      <Grid>
        <PorClientMonitorInputs
          selectedMpids={selectedMpids}
          selectedDateFilter={selectedDateFilter}
        />
        <ProMonitorRejectPieChart
          pieChartData={pieChartData}
          totalRejectCount={totalRejectCount}
          selectedMpids={selectedMpids}
          selectedDateFilter={selectedDateFilter}
        />
        <RejectsBySymbol dataArrayCounts={dataArrayCounts} totalRejectCount={totalRejectCount} />

        <TimerWrapper>
          <PorWindowTimer marginTopPx={1} />
        </TimerWrapper>
        <PvClientPorTable />
        <PvClientRejectTable />
      </Grid>
      <TradeDetailRecap widthFactor={recapData.trades.length} />
    </Middle>
  );
};
