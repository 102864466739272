import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext } from "components/form";
import { getHeaders } from "keycloak";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { EmailManagerForm, usePvrEmailDispatch, handleSaveNewPvrEmail } from ".";
import { doFetchWrapper } from "../../../../network";
import { useUserContext } from "../../../user";
import { usePvrConfigDispatch } from "../configContext";
import { PVR_EMAIL_ENTITY } from "./constant";

const StyledModalBody = styled(ModalBody)`
  overflow: hidden;
`;

export const PvrEmailModal = ({ isVisible, onClose, selectedEmail }) => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData, formDispatch] = useFormContext();
  const [userData] = useUserContext();
  const emailDispatch = usePvrEmailDispatch();
  const configDispatch = usePvrConfigDispatch();

  useEffect(() => {
    if (selectedEmail) {
      const form = Forms.PVR_EMAIL_MANAGER;
      const fields = { [PVR_EMAIL_ENTITY.recipientEmail]: selectedEmail };
      formDispatch({
        type: "SET_FORM_VALUES",
        payload: { form, fields },
      });
    }
  }, [formDispatch, selectedEmail, userData.entitlements]);

  const handleCloseModal = useCallback(() => {
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose]);

  const handleDone = useCallback(() => {
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PVR_EMAIL_MANAGER },
    });
    handleCloseModal();
  }, [handleCloseModal, tableDispatch]);

  const handleSaveNew = useCallback(() => {
    const newEmail = {
      [PVR_EMAIL_ENTITY.recipientEmail]:
        formData[Forms.PVR_EMAIL_MANAGER.key].fields[PVR_EMAIL_ENTITY.recipientEmail],
    };
    handleSaveNewPvrEmail({
      baseUrl: process.env.REACT_APP_URL_PVR_ALERT_SERVICE,
      emailDispatch,
      onDone: handleDone,
      newEmail,
    });
  }, [emailDispatch, formData, handleDone]);

  const handleSaveEdit = useCallback(() => {
    const success = row => {
      NotificationHub.send("success", "Edited email");
      tableDispatch([
        {
          type: "UPDATE_TABLE_ROW_WITH_ID",
          payload: { table: StandardTables.PVR_EMAIL_MANAGER, row, idField: PVR_EMAIL_ENTITY.id },
        },
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PVR_EMAIL_MANAGER },
        },
      ]);
      emailDispatch({
        type: "START_REFRESH",
      });
      configDispatch({ type: "SET_REQUESTING", payload: true });
      handleCloseModal();
    };
    const error = () => {
      NotificationHub.send("danger", "Error editing email");
      handleCloseModal();
    };
    const selected = getSelectedRows(tableData[StandardTables.PVR_EMAIL_MANAGER]);
    if (Array.isArray(selected) && selected.length === 1) {
      const emailUpdate = {
        [PVR_EMAIL_ENTITY.id]: selected[0][PVR_EMAIL_ENTITY.id],
        [PVR_EMAIL_ENTITY.recipientEmail]:
          formData[Forms.PVR_EMAIL_MANAGER.key].fields[PVR_EMAIL_ENTITY.recipientEmail],
      };
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "alert/email/updateEmail"),
        {
          method: "put",
          headers: getHeaders(),
          body: JSON.stringify(emailUpdate),
        },
        success,
        error
      );
    } else {
      NotificationHub.send("danger", "Could not find selected record.");
    }
  }, [configDispatch, emailDispatch, formData, handleCloseModal, tableData, tableDispatch]);

  const handleSave = useCallback(() => {
    if (selectedEmail) {
      handleSaveEdit();
    } else {
      handleSaveNew();
    }
  }, [selectedEmail, handleSaveEdit, handleSaveNew]);

  const Header = useMemo(() => {
    if (typeof selectedEmail === "string") {
      return "Edit Price Reject Override (PRO) Alert Recipient";
    } else {
      return "Add New Recipient for Price Reject Override (PRO) Alerts";
    }
  }, [selectedEmail]);

  return (
    <Modal isOpen={isVisible} closeOnOutsideClick={true} toggle={handleCloseModal} size={"md"}>
      <ModalHeader toggle={handleCloseModal} title={Header} />
      <StyledModalBody>
        <EmailManagerForm />
      </StyledModalBody>
      <ModalFooter>
        <Button outline={true} onClick={handleCloseModal}>
          Close
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};
